/* eslint-disable camelcase */

import { toast } from '@postscript/components';
import produce from 'immer';
import { isEmpty, isNil, omitBy } from 'lodash';
import PropTypes from 'prop-types';
import qs, { stringify } from 'qs';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { api } from '../network/apiClient';

const LOADING = 'LOADING';
const SET_ITEMS = 'SET_ITEMS';
const ADD_ITEM = 'ADD_ITEM';
const UPDATE_ITEM = 'UPDATE_ITEM';
const REMOVE_ITEM = 'REMOVE_ITEM';
const SET_SHOP_OPTIONS = 'SET_SHOP_OPTIONS';
const DATA_TYPE = 'partners';

const defaultInitialState = {
  loading: false,
  shopOptions: null,
  [DATA_TYPE]: null,
};

export const PartnersContext = createContext(defaultInitialState);
export const usePartners = () => useContext(PartnersContext);

const reducerFn = (draft, action) => {
  switch (action.type) {
    case LOADING:
      draft.loading = action.loading;
      break;
    case SET_ITEMS:
      draft[DATA_TYPE] = action.data;
      break;
    case ADD_ITEM:
      if (isNil(draft[DATA_TYPE])) draft[DATA_TYPE] = [];

      draft[DATA_TYPE].push(action.data);
      break;
    case UPDATE_ITEM: {
      if (isEmpty(draft[DATA_TYPE])) return draft;

      const index = draft[DATA_TYPE].findIndex(({ id }) => id === action.id);

      const item = draft[DATA_TYPE][index];

      draft[DATA_TYPE][index] = {
        ...item,
        ...action.data,
      };
      break;
    }
    case REMOVE_ITEM:
      if (isEmpty(draft[DATA_TYPE])) return draft;

      draft[DATA_TYPE] = draft[DATA_TYPE].filter(({ id }) => id !== action.id);
      break;
    case SET_SHOP_OPTIONS:
      draft.shopOptions = action.shopOptions;
      break;
    default:
  }
};

const reducer = produce(reducerFn);

export const PartnersProvider = ({ initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { search: searchParamsString } = useLocation();

  const setLoading = (loading) => {
    dispatch({
      type: LOADING,
      loading,
    });
  };

  const setItems = (data) => {
    dispatch({
      type: SET_ITEMS,
      data,
    });
  };

  const addItem = (data) => {
    dispatch({
      type: ADD_ITEM,
      data,
    });
  };

  const updateItem = (id, data) => {
    dispatch({
      type: UPDATE_ITEM,
      id,
      data,
    });
  };

  const removeItem = (id, data) => {
    dispatch({
      type: REMOVE_ITEM,
      id,
      data,
    });
  };

  const setShopOptions = (shopOptions) => {
    dispatch({
      type: SET_SHOP_OPTIONS,
      shopOptions,
    });
  };

  const search = async ({ username = '', shopId = '', page = 1 } = {}) => {
    setLoading(true);

    const paramArguments = omitBy(
      {
        username__contains: username,
        shop_id__eq: shopId,
        page,
      },
      (val) => isEmpty(val) && !Number.isInteger(val),
    );

    const params = stringify(paramArguments);
    try {
      const { partners } = await api.get(`/v2/partners/?${params}`);

      setItems(partners);
      setLoading(false);

      return partners;
    } catch (e) {
      setLoading(false);
      toast.error('Unable to find a partner.');
    }
  };

  const getShopOptions = async ({ search = '', limit = 35 } = {}) => {
    const paramArguments = omitBy(
      {
        search,
        limit,
      },
      (val) => isEmpty(val) && !Number.isInteger(val),
    );

    const params = stringify(paramArguments);

    const { shops } = await api.get(`/admin/get_all_shops?${params}`);

    setShopOptions(shops);
  };

  const create = async (partner) => {
    try {
      const newPartner = await api.post('/v2/partners/', partner);
      addItem(newPartner);
      return newPartner;
    } catch (e) {
      toast.error('Unable to create a partner.');
    }
  };

  const update = async (partner) => {
    try {
      const responsePartner = await api.put(
        `/v2/partners/${partner.id}/`,
        partner,
      );

      updateItem(responsePartner.id, responsePartner);
      return responsePartner;
    } catch (e) {
      toast.error('Unable to update partner.');
    }
  };

  const resetPassword = (partner) => {
    try {
      return api.post(`/v2/partners/${partner.id}/reset_password/`);
    } catch (e) {
      toast.error('Unable to reset password.');
    }
  };

  const resetTwoFactorAuth = async (partner) => {
    try {
      return await api.put(`/v2/partners/${partner.id}/reset_partner_2fa/`);
    } catch (e) {
      toast.error('Unable to reset 2fa.');
    }
  };

  const deletePartner = async (partner) => {
    try {
      const deleteResp = await api.delete(`/v2/partners/${partner.id}/`);
      removeItem(partner.id, partner);
      return deleteResp;
    } catch (e) {
      toast.error('Unable to delete this partner.');
    }
  };

  const enablePartnerQA = async (partnerId) => {
    try {
      const to_toggle = true;
      return await api.put(`/v2/partners/qa_approvals/user/${partnerId}/`, {
        to_toggle,
      });
    } catch (e) {
      toast.error("Unable to enable partner's QA approver status.");
    }
  };

  const disablePartnerQA = async (partnerId) => {
    try {
      const to_toggle = false;
      return await api.put(`/v2/partners/qa_approvals/user/${partnerId}/`, {
        to_toggle,
      });
    } catch (e) {
      toast.error("Unable to disable partner's QA approver status.");
    }
  };

  const getPartnerQA = async (partnerId) => {
    try {
      const getResp = await api.get(
        `/v2/partners/qa_approvals/user/${partnerId}/`,
      );
      return getResp.approver;
    } catch (e) {
      toast.error("Couldn't get partner's QA approver status.");
    }
  };

  const getPartnerPhone = async (partnerId) => {
    try {
      const getResp = await api.get(
        `/v2/partners/qa_approvals/user/${partnerId}/phone/`,
      );
      return getResp.number;
    } catch (e) {
      toast.error("Couldn't retrieve partner's phone number.");
    }
  };

  const addPartnerPhone = async (partnerId, number) => {
    try {
      return await api.put(
        `/v2/partners/qa_approvals/user/${partnerId}/phone/`,
        {
          number,
        },
      );
    } catch (e) {
      if (e.toString().endsWith('404')) {
        toast.error('Phone number not found or improperly formatted.');
      } else {
        toast.error(`Couldn't add phone number.`);
      }
    }
  };

  const getQueryParameters = () => {
    const parsedParams = qs.parse(searchParamsString, {
      ignoreQueryPrefix: true,
    });

    const formattedParams = {
      username: parsedParams.username,
      shop_ids: !isEmpty(parsedParams.shopIds)
        ? parsedParams.shopIds.split(',')
        : [],
      limit: parsedParams.limit,
      page: parsedParams.page,
    };

    return formattedParams;
  };

  useEffect(() => {
    getShopOptions();
  }, []);

  return (
    <PartnersContext.Provider
      value={{
        ...state,
        search,
        create,
        update,
        resetPassword,
        resetTwoFactorAuth,
        enablePartnerQA,
        disablePartnerQA,
        getPartnerQA,
        getPartnerPhone,
        addPartnerPhone,
        deletePartner,
        getQueryParameters,
        getShopOptions,
      }}
    >
      {children}
    </PartnersContext.Provider>
  );
};

PartnersProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  initialState: PropTypes.object,
};

PartnersProvider.defaultProps = {
  initialState: defaultInitialState,
};

import { TagDelimiters } from 'components/flowBuilder/constants/tags';
import type { Tag } from 'components/flowBuilder/types/tags';
import { DraftFlow } from '../types';
import { isSendMessageAction, isTextToBuyAction } from '../types/typeGuards';

export const getMergeTagsByCategory = (
  mergeTags: Tag[],
): Record<string, Tag[]> => {
  return mergeTags.reduce<Record<string, Tag[]>>(
    (accMergeTagsByCategory, mergeTag) => {
      const { category } = mergeTag;
      const categoryMergeTags = accMergeTagsByCategory[category] ?? [];
      const newCategoryMergeTags = [...categoryMergeTags, mergeTag];

      return {
        ...accMergeTagsByCategory,
        [category]: newCategoryMergeTags,
      };
    },
    {},
  );
};

export const getSubscriberPropertyMergeTagKey = (property: string): string => {
  return `${TagDelimiters.START}subscriber.custom_properties.get('${property}')${TagDelimiters.END}`;
};

export const getTriggerEventPropertyMergeTagKey = (
  property: string,
): string => {
  // Note: It's important to refer to the event as `initial_event` here, as that's what the BE
  // calls the trigger event.
  return `${TagDelimiters.START}initial_event.get('${property}')${TagDelimiters.END}`;
};

export const removeInvalidTriggerEventPropertyMergeTags = (
  content: string,
  validTriggerEventProperties: string[],
): string => {
  const keyPattern = `${TagDelimiters.START}initial_event\\.get\\((?:"|')((?:\\w|\\s|-)*)(?:"|')\\)${TagDelimiters.END}`;
  const regex = new RegExp(keyPattern, 'g');

  return content.replaceAll(regex, (match, propertyName) => {
    const isPropertyValid = validTriggerEventProperties.includes(propertyName);

    return isPropertyValid ? match : '';
  });
};

export const convertLegacyMergeTagsInString = (text: string): string => {
  const LEGACY_SUBSCRIBER_MERGE_TAG_REGEX = new RegExp(
    `${TagDelimiters.START}subscriber\\.custom_properties\\.((?:\\w|-)*)${TagDelimiters.END}`,
    'g',
  );

  return text.replaceAll(
    LEGACY_SUBSCRIBER_MERGE_TAG_REGEX,
    (_, propertyName) => {
      return getSubscriberPropertyMergeTagKey(propertyName);
    },
  );
};

export const convertLegacyMergeTags = (flow: DraftFlow): void => {
  flow.actions.forEach((action) => {
    if (isSendMessageAction(action) || isTextToBuyAction(action)) {
      // eslint-disable-next-line no-param-reassign
      action.params.content = convertLegacyMergeTagsInString(
        action.params.content,
      );
    }
  });
};

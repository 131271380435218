import React from 'react';
import styled from 'styled-components';

const StyledLinkButton = styled.button`
  background: none;
  font: var(--body-text-small);
  color: var(--link-color);
  font-weight: 600;
  border: none;
  padding: 0;
  text-decoration: underline;
  text-align: left;
  cursor: pointer;

  &:hover {
    color: var(--link-color-hover);
  }

  &:active {
    color: var(--link-color-active);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export default function LinkButton({ children, ...props }: Props): JSX.Element {
  return <StyledLinkButton {...props}>{children}</StyledLinkButton>;
}

import {
  BodyText,
  EyebrowText,
  Heading,
  Icon,
  IconSet,
  TabGroup,
  Tooltip,
} from '@postscript/components';
import EditableHeaderText, {
  EditableHeaderTextProps,
} from 'components/layout/EditableHeaderText';
import { useFullScreenEditor } from 'controllers/contexts/fullScreenEditor';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { COMMON_EVENT_NAMES, logEvent, ProductAreas } from 'utils/events';
import MediaQueries from 'utils/mediaQueries';

export const EVENT_LISTENERS = {
  ON_CLICK: 'onClick',
  ON_MOUSE_ENTER: 'onMouseEnter',
} as const;

export type EventListeners =
  typeof EVENT_LISTENERS[keyof typeof EVENT_LISTENERS];

export const logPageHeader = (
  eventListener: EventListeners,
  productArea: ProductAreas,
): void => {
  const data = {
    location: 'page header',
    product_area: productArea,
  };

  if (eventListener === EVENT_LISTENERS.ON_CLICK) {
    logEvent('more info clicked', data);
  }

  if (eventListener === EVENT_LISTENERS.ON_MOUSE_ENTER) {
    logEvent(COMMON_EVENT_NAMES.TOOLTIP_SHOWN, data);
  }
};

/* MAIN */

interface PageHeaderBaseProps extends React.HTMLAttributes<HTMLElement> {
  actions?: React.ReactNode;
  breadCrumb?: {
    onClick?: () => void;
    text: string;
    to?: React.ReactNode;
  };
  sticky?: boolean;
  tabs?: React.ReactNode;
}

interface EditablePageHeader extends PageHeaderBaseProps {
  editablePageTitle?: EditableHeaderTextProps;
  editableDescription?: EditableHeaderTextProps;
  pageTitle?: never;
  description?: never;
  descriptionLink?: never;
  descriptionTooltipOnClick?: never;
  descriptionTooltipOnMouseEnter?: never;
}

interface NonEditablePageHeader extends PageHeaderBaseProps {
  editablePageTitle?: never;
  editableDescription?: never;
  pageTitle: React.ReactNode;
  description?: React.ReactNode;
  descriptionLink?: string;
  descriptionTooltipOnClick?: () => void;
  descriptionTooltipOnMouseEnter?: () => void;
}

export type PageHeaderProps = EditablePageHeader | NonEditablePageHeader;

interface PageHeaderContainerProps {
  breadCrumb?: boolean;
  isFullScreenEditorMode?: boolean;
  isStuck?: boolean;
  sticky?: boolean;
}

// prettier-ignore
const PageHeaderContainer = styled.header<PageHeaderContainerProps>`
  position: relative;
  padding-bottom: ${({ isFullScreenEditorMode }) =>
    isFullScreenEditorMode ? 'var(--spacing-2)' : 'var(--spacing-4)'};
  margin-bottom: var(--spacing-2);
  transition: margin var(--nav-menu-transition-speed);

  .full-screen-editor & {
    margin-bottom: 0;
  }

  ${({ breadCrumb }) =>
    breadCrumb &&
    css`
      .full-screen-editor & {
        ${MediaQueries.desktopOnly} {
          margin-bottom: 0;
        }
      }
    `}

  // is sticky
  ${({ isStuck, sticky }) => sticky && css`
    ${MediaQueries.desktopOnly} {
      background: var(--main-bkg-color);
      position: sticky;
      top: 54px;
      z-index: 100;

      &:before {
        background: inherit;
        content: '';
        display: block;
        pointer-events: none;
        position: absolute;
        height: calc(100% + 54px);
        width: calc(100% + 72px);
        left: -18px;
        top: -54px;
        z-index: -1;
      }

      &:after {
        display: block;
        content: '';
        height: 1px;
        width: calc(100% + var(--spacing-6));
        background: var(--border-color-dim);
        position: absolute;
        opacity: 0;
        left: calc(-1 * var(--spacing-3));
        bottom: 0;
        transition: opacity var(--hover-transition);
      }

      .full-screen-editor &:after {
        width: calc(100% + 108px);
        left: -54px;
      }

      ${isStuck && css`
        box-shadow: var(--box-shadow-medium);

        &:before {
          opacity: 1;
        }
        &:after {
          opacity: 1;
        }
      `}
    }
  `}
`;

const StyledScrollMarker = styled.span<{ isFullScreenEditorMode?: boolean }>`
  height: 0;
  width: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  z-index: 99;
`;

/* BREADCRUMB */

const BreadCrumbIcon = styled(Icon)`
  margin-right: 3px;
  transition-property: color, transform;
  transition-duration: var(--hover-transition);
`;

const BreadCrumbLink = styled(EyebrowText)`
  color: inherit;
  span {
    color: var(--highlight-color);
  }
`;

const BreadCrumb = styled(Link)`
  color: var(--text-color-dim);
  cursor: pointer;
  display: inline-flex;
  vertical-align: top;

  &:hover,
  &:focus {
    outline: none;
    color: var(--link-color);
    text-decoration: underline;

    ${BreadCrumbIcon} {
      transform: translateX(-3px);
    }
  }

  &:active {
    color: var(--link-color-hover);
  }

  ${MediaQueries.desktopOnly} {
    position: absolute;
    top: -18px;
    left: 0;
    z-index: 102;
  }

  ${MediaQueries.tabletAndPhone} {
    padding-bottom: var(--spacing-1);
  }
`;

/* TITLE & DESCRIPTION AND ACTION CONTAINER */

const PageTitleDescriptionAndActionContainer = styled.div`
  display: flex;

  ${MediaQueries.tabletAndPhone} {
    padding-top: var(--spacing-1);
  }

  ${MediaQueries.phoneOnly} {
    flex-direction: column;
  }
`;

/* TITLE AND DESCRIPTION */
const PageTitleAndDescriptionContainer = styled.div<
  Pick<EditablePageHeader, 'editablePageTitle'>
>`
  display: flex;
  flex-direction: column;
  min-width: 0;

  ${({ editablePageTitle }) =>
    editablePageTitle
      ? 'flex: 0 1 auto; align-items: flex-start;'
      : 'flex: 0 1 100%;'}
`;

const PageTitle = styled(Heading)`
  overflow: hidden;
  text-overflow: ellipsis;

  ${MediaQueries.desktopOnly} {
    .full-screen-editor & {
      font: var(--heading-large-responsive);
    }
  }
  ${MediaQueries.tabletAndPhone} {
    font: var(--heading-large-responsive);
  }
`;

// prettier-ignore
const StyledEditablePageTitle = styled(EditableHeaderText)<EditableHeaderTextProps>`
  font: var(--heading-medium);

  // tweak icon position
  > span[role="img"] {
    margin-top: -4px;
  }

  // weird hack to fix a Safari Roc rendering issue
  ${MediaQueries.desktopOnly} {
    @supports selector(:nth-child(1 of x)) {
      input {
        top: -3px !important;
      }
      .full-screen-editor & input {
        top: -4px !important;
      }
    }
  }

  ${MediaQueries.tabletAndPhone} {
    @supports selector(:nth-child(1 of x)) {
      input {
        top: -4px !important;
      }
    }
  }

  ${MediaQueries.desktopOnly} {
    .full-screen-editor & {
      font: var(--heading-large-responsive);

      // tweak icon position
      > span[role="img"] {
        --icon-size: 30px;
      }
    }
  }
  ${MediaQueries.tabletAndPhone} {
    & {
      font: var(--heading-large-responsive);

      // tweak icon position
      > span[role="img"] {
        --icon-size: 30px;
      }
    }
  }
`;

const StyledDescriptionLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font: var(--body-text-x-small);
  color: var(--text-color-dim);
  text-decoration: underline;
  position: absolute;
  height: var(--spacing-4);
  width: var(--spacing-3);

  &:hover {
    color: var(--mint-core);
  }
  &:active {
    opacity: 0.75;
  }
  &:focus {
    outline: none;
    color: var(--mint-core);
  }
`;

// prettier-ignore
const Description = styled(BodyText).attrs({size: 'medium'})<{ hasLink?: string }>`
  position: relative;
  margin: 0;
  max-width: 648px; // 80 chars

  ${MediaQueries.tabletAndPhone} {
    padding-top: var(--spacing-2);
  }
`;

// prettier-ignore
const StyledEditableDescription = styled(EditableHeaderText)<EditableHeaderTextProps>`
  color: var(--text-color-dim);
  font: var(--body-text-small);
  align-items: flex-start;

  // tweak icon position
  > span[role="img"] {
    margin-top: 4px;
  }
`;

/* PAGE TABS */
const StyledTabGroup = styled(TabGroup)`
  align-self: flex-start;
`;

/* PAGE ACTION */

const PageActions = styled.div<Pick<EditablePageHeader, 'editablePageTitle'>>`
  flex: 1 0 auto;
  ${({ editablePageTitle }) => (editablePageTitle ? ' margin-left: auto;' : '')}

  ${MediaQueries.desktopAndTablet} {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: var(--spacing-1) 0 0 var(--spacing-4);
  }

  ${MediaQueries.tabletOnly} {
    padding-top: 0;
  }

  ${MediaQueries.phoneOnly} {
    padding: var(--spacing-4) 0 0;
    flex-grow: 100%;
  }
`;

const PageHeader = ({
  actions,
  breadCrumb,
  description,
  descriptionLink,
  descriptionTooltipOnClick,
  descriptionTooltipOnMouseEnter,
  editableDescription,
  editablePageTitle,
  pageTitle = 'Postscript',
  sticky = false,
  tabs,
  ...props
}: PageHeaderProps): JSX.Element => {
  const [isStuck, setIsStuck] = useState(false);
  const [isFullScreenEditorMode] = useFullScreenEditor();
  const stickyRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsStuck(e.intersectionRatio < 1);
      },
      { threshold: [0, 1] },
    );
    if (stickyRef.current) {
      observer.observe(stickyRef.current);
    }
    return () => observer.disconnect();
  }, [stickyRef]);

  const renderPageTitle = () => {
    if (editablePageTitle) {
      const { ...eptProps } = editablePageTitle;
      return (
        <StyledEditablePageTitle
          errorSize="large"
          iconSize={36}
          {...eptProps}
        />
      );
    }
    if (pageTitle) {
      return (
        <PageTitle forwardedAs="h1" id="pageHeader__pageTitle" size="medium">
          {pageTitle}
        </PageTitle>
      );
    }
  };

  const renderDescriptionLink = () => {
    return (
      <>
        {' '}
        <StyledDescriptionLink
          aria-describedby="pageHeader__DescriptionLink"
          data-for="pageHeader__DescriptionLink"
          data-tip
          href={descriptionLink}
          onMouseEnter={descriptionTooltipOnMouseEnter}
          rel="noreferrer"
          tabIndex={0}
          target="_blank"
        >
          <Icon
            component={IconSet.Information}
            onClick={descriptionTooltipOnClick}
            size={14}
          />
        </StyledDescriptionLink>
        <Tooltip id="pageHeader__DescriptionLink" size="small">
          More info about {pageTitle}
        </Tooltip>
      </>
    );
  };

  const renderDescription = () => {
    if (tabs) return;
    let renderedDescription = null;
    if (editableDescription) {
      const { ...edProps } = editableDescription;
      renderedDescription = (
        <StyledEditableDescription
          errorSize="small"
          iconSize={18}
          {...edProps}
        />
      );
    }
    if (description) {
      renderedDescription = (
        <Description hasLink={descriptionLink} forwardedAs="span">
          {description}
          {descriptionLink && renderDescriptionLink()}
        </Description>
      );
    }
    return renderedDescription;
  };

  const renderPageTabs = () => {
    if (!tabs) return;
    return (
      <StyledTabGroup
        description="Some assorted settings"
        id="page-level-tabs"
        placement="page-level"
      >
        {React.Children.map(tabs, (tab: React.ReactNode) => {
          if (!React.isValidElement(tab)) return;
          return React.cloneElement(tab, {
            key: `tab-${tab.props.children}`,
          });
        })}
      </StyledTabGroup>
    );
  };

  return (
    <PageHeaderContainer
      breadCrumb={!!breadCrumb}
      isFullScreenEditorMode={isFullScreenEditorMode}
      isStuck={isStuck}
      sticky={sticky}
      role="banner"
      data-cy="page-header"
      {...props}
    >
      {breadCrumb && (
        <BreadCrumb
          data-cy="page-header-breadcrumb"
          onClick={breadCrumb.onClick}
          to={breadCrumb.to || '#'}
          tabIndex={0}
        >
          <BreadCrumbIcon size={16} component={IconSet.ArrowLeft} />
          <BreadCrumbLink>
            Back to <span>{breadCrumb.text}</span>
          </BreadCrumbLink>
        </BreadCrumb>
      )}
      <PageTitleDescriptionAndActionContainer>
        <PageTitleAndDescriptionContainer editablePageTitle={editablePageTitle}>
          {renderPageTitle()}
          {renderDescription()}
          {renderPageTabs()}
        </PageTitleAndDescriptionContainer>
        {actions && (
          <PageActions
            data-pendo="page-header-actions"
            editablePageTitle={editablePageTitle}
          >
            {actions}
          </PageActions>
        )}
      </PageTitleDescriptionAndActionContainer>
      <StyledScrollMarker
        className="page-header-scroll-marker"
        isFullScreenEditorMode={isFullScreenEditorMode}
        ref={sticky ? stickyRef : null}
      />
    </PageHeaderContainer>
  );
};

export default PageHeader;

import { Banner } from '@postscript/components';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  COMMON_EVENT_NAMES,
  logButtonClickEvent,
  logEvent,
} from 'utils/events';
import { GlobalBanner, useBanners } from './globalBanners';

const GlobalBannerListItem = ({
  id,
  eventTrackingOptions,
  isTransient,
  isDismissable,
  shouldPersistDismiss,
  data,
}: GlobalBanner): JSX.Element => {
  const { removeBanner } = useBanners();
  const { listen } = useHistory();

  const dismissHandler = () => {
    if (shouldPersistDismiss) {
      localStorage.setItem(`${id}-banner-dismissed`, 'user-dismissed');
    }

    if (isDismissable) {
      removeBanner(id);
    }

    if (data.onDismiss) {
      data.onDismiss();
    }

    if (eventTrackingOptions?.bannerType) {
      const { bannerType } = eventTrackingOptions;

      logEvent(COMMON_EVENT_NAMES.BANNER_DISMISSED, {
        banner_type: bannerType,
      });
    }
  };

  useEffect(() => {
    if (!isTransient) return;

    // Remove transient banners on route change
    listen(() => removeBanner(id));
  }, []);

  useEffect(() => {
    if (!eventTrackingOptions?.bannerType) return;

    const { bannerType } = eventTrackingOptions;

    const trackButtonClick = (event: MouseEvent) => {
      logButtonClickEvent(event, {
        banner_type: bannerType,
      });
    };

    logEvent(COMMON_EVENT_NAMES.BANNER_DISPLAYED, { banner_type: bannerType });

    const actions = document.querySelectorAll(`#${id} button`);

    actions.forEach((action) =>
      (action as HTMLElement).addEventListener('click', trackButtonClick),
    );

    return () => {
      actions.forEach((action) =>
        (action as HTMLElement).removeEventListener('click', trackButtonClick),
      );
    };
  }, []);

  return (
    <Banner
      id={id}
      data-cy={`banner-id-${id}`}
      {...data}
      onDismiss={isDismissable || data.onDismiss ? dismissHandler : undefined}
    />
  );
};

export default GlobalBannerListItem;

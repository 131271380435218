/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { uniqBy } from 'lodash';
import { MULTIPLIER_FRACTION_DIGITS } from './constants';
import {
  Invoice,
  LedgerRecord,
  LedgerRecordTypes,
  MessageRate,
  MessageRateInput,
  MessageTypes,
} from './types';

export const formatDollars = (
  amount: number,
  minimumFractionDigits = 2,
  maximumFractionDigits?: number,
): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits ?? minimumFractionDigits,
    currency: 'USD',
  }).format(parseFloat(amount.toString()));
};

export const formatInputDollars = (
  input: string,
  minimumFractionDigits = 2,
  maximumFractionDigits?: number,
  allowNegativeValues = true,
): string => {
  let a = input;
  a = (a || '0').toString().replace(/,/g, '');
  if (!allowNegativeValues) a = a.replace(/-/g, '');
  if (a.charAt(0) === '-' && a.charAt(1) === '$') a = `-${a.substr(2)}`;
  if (a.charAt(0) === '$') a = a.substr(1);
  if (Number.isNaN(parseFloat(a))) a = '0';
  a = parseFloat(a).toFixed(maximumFractionDigits ?? minimumFractionDigits);

  return formatDollars(
    parseFloat(a),
    minimumFractionDigits,
    maximumFractionDigits,
  );
};

export function formatInputMessageRate(rate: string): string {
  return formatInputDollars(rate, 0, MULTIPLIER_FRACTION_DIGITS, false);
}

export const getCents = (amount: string, fractional = false): number => {
  if (fractional)
    return parseFloat(`.${(amount || '0').replace(/[^0-9-]/g, '')}`) * 1000;

  return parseFloat((amount || '0').replace(/[^0-9-]/g, ''));
};

interface LedgerRecordFilterInput {
  ledgerRecordTypes?: LedgerRecordTypes[];
  descriptions?: string[];
  messageTypes?: MessageTypes[];
}

export function filterLedgerRecords(
  ledgerRecords: LedgerRecord[],
  filters: LedgerRecordFilterInput = {},
): LedgerRecord[] {
  const { ledgerRecordTypes, descriptions, messageTypes } = filters;

  return ledgerRecords
    .filter(({ type }) =>
      ledgerRecordTypes?.length ? ledgerRecordTypes.includes(type) : true,
    )
    .filter(({ description }) =>
      descriptions?.length ? descriptions.includes(description) : true,
    )
    .filter(({ ledgerMetadata }) =>
      messageTypes?.length && ledgerMetadata?.messageType
        ? messageTypes.includes(ledgerMetadata?.messageType)
        : true,
    );
}

export function sumLedgerRecords(
  ledgerRecords: LedgerRecord[],
  filters: LedgerRecordFilterInput = {},
): number {
  const { ledgerRecordTypes, descriptions, messageTypes } = filters;

  return filterLedgerRecords(ledgerRecords, {
    ledgerRecordTypes,
    descriptions,
    messageTypes,
  })
    .map(({ amount }) => amount)
    .reduce((a, b) => a + b, 0);
}

export function countLedgerRecords(
  ledgerRecords: LedgerRecord[],
  filters: LedgerRecordFilterInput = {},
): number {
  const { ledgerRecordTypes, descriptions, messageTypes } = filters;

  return filterLedgerRecords(ledgerRecords, {
    ledgerRecordTypes,
    descriptions,
    messageTypes,
  }).length;
}

export function toggleButtonLoadingState({
  button,
  isLoading,
  originalText,
}: {
  button: HTMLButtonElement | null;
  isLoading: boolean;
  originalText: string;
}): void {
  if (!button) return;

  const mutableButton = button;

  if (isLoading) {
    mutableButton.disabled = true;
    mutableButton.innerText = 'Loading...';
    mutableButton.style.fontWeight = '600';

    return;
  }

  mutableButton.innerText = originalText;
  mutableButton.disabled = false;
}

export function findMessageRateByCodeAndType(
  messageRates: MessageRate[] | undefined,
  countryCode: string,
  messageType: MessageTypes,
): number | undefined {
  const messageRate = messageRates?.find(
    (rate) =>
      rate.countryCode === countryCode && rate.messageType === messageType,
  );

  return messageRate?.messageRate;
}

export function formatMessageRate(amount: number): string {
  return formatDollars(amount / 100, 2, MULTIPLIER_FRACTION_DIGITS);
}

export function convertMessageRatesToInputRates(
  messageRates: MessageRate[],
): MessageRateInput[] {
  const inputRates: MessageRateInput[] = [];

  const uniqueRatesByCountry = uniqBy(messageRates, 'countryCode');

  for (const rate of uniqueRatesByCountry) {
    const { countryCode } = rate;

    const getRateByType = (type: MessageTypes): number =>
      messageRates.find(
        (messageRate) =>
          messageRate.countryCode === countryCode &&
          messageRate.messageType === type,
      )?.messageRate || 0;

    inputRates.push({
      countryCode,
      formattedSmsRate: formatMessageRate(getRateByType('SMS')),
      formattedMmsRate: formatMessageRate(getRateByType('MMS')),
    });
  }

  return inputRates;
}

export function convertInputRatesToMessageRates(
  inputRates: MessageRateInput[],
): MessageRate[] {
  const messageRates = inputRates
    .map(({ countryCode, formattedSmsRate, formattedMmsRate }) => [
      {
        countryCode,
        messageRate: getCents(formattedSmsRate, true),
        messageType: 'SMS' as MessageTypes,
      },
      {
        countryCode,
        messageRate: getCents(formattedMmsRate, true),
        messageType: 'MMS' as MessageTypes,
      },
    ])
    .flat()
    .filter(({ messageRate }) => messageRate > 0);

  return messageRates;
}

export function getLedgerRecordsFromInvoices(
  invoices: Invoice[],
): LedgerRecord[] {
  return invoices.map(({ ledgerRecords }) => ledgerRecords).flat() || [];
}

import { Switch } from '@postscript/components';
import { useState } from 'react';
import { LEGACY_ROLES } from '../constants';
import { userHasLegacyRole } from '../helpers';

export default function UserSettingsForm(): JSX.Element {
  const isAiAlphaTester = userHasLegacyRole(`AI_ALPHA_TESTER`);
  const isSalesAgentWorkspaceTester = userHasLegacyRole(
    `SHOP_SALES_AGENT_WORKSPACE_TESTER`,
  );

  const [ccsAIAssistant, setCcsAIAssistant] = useState(
    localStorage.getItem(LEGACY_ROLES.AI_ALPHA_TESTER) === 'true',
  );
  const [shopSalesAgentWorkspaceTester, setShopSalesAgentWorkspaceTester] =
    useState(
      localStorage.getItem(LEGACY_ROLES.SHOP_SALES_AGENT_WORKSPACE_TESTER) ===
        'true',
    );

  return (
    <>
      {isAiAlphaTester && (
        <Switch
          id="CCSAIAssistant"
          label="CCS AI Assistant"
          labelPosition="left"
          checked={ccsAIAssistant}
          onChange={() => {
            localStorage.setItem(
              LEGACY_ROLES.AI_ALPHA_TESTER,
              (!ccsAIAssistant).toString(),
            );
            setCcsAIAssistant(!ccsAIAssistant);
          }}
        />
      )}
      {isSalesAgentWorkspaceTester && (
        <Switch
          id="agent-workspace-tester"
          label="Shop Sales Agent Workspace Tester"
          labelPosition="left"
          checked={shopSalesAgentWorkspaceTester}
          onChange={() => {
            localStorage.setItem(
              LEGACY_ROLES.SHOP_SALES_AGENT_WORKSPACE_TESTER,
              (!shopSalesAgentWorkspaceTester).toString(),
            );
            setShopSalesAgentWorkspaceTester(!shopSalesAgentWorkspaceTester);
          }}
        />
      )}
    </>
  );
}

import { toast } from '@postscript/components';
import { formatErrorMessage } from 'components/flowBuilder/utils/errors';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import {
  AvailablePaymentMethods,
  Cycle,
  Cycles,
  CycleWithTotals,
  Invoice,
  LedgerRecord,
  LedgerRecordStat,
  Package,
  Plan,
  PlanChangeInput,
  ProductPlan,
  ProductPlanTypes,
  RecurringFee,
  RecurringFeeInput,
  ScheduledPlanChange,
  SearchParams,
  UsageCredit,
  UsageCreditInput,
} from '../common/types';
import {
  assignProductPlan,
  cancelScheduledPlanChange,
  createRecurringFee,
  disableRecurringFee,
  forceSetCurrentPlan,
  getAvailablePaymentMethods,
  getCurrentAndNextCycles,
  getCurrentPlan,
  getCurrentProductPlans,
  getCustomMarketingPlans,
  getCyclesWithTotals,
  getInvoicesByCycle,
  getMarketingPlans,
  getNextPlan,
  getPackages,
  getPastCycles,
  getProductPlans,
  getRecurringFees,
  getScheduledPlanChange,
  getUninvoicedLedgerRecords,
  getUninvoicedUsageAmount,
  getUninvoicedUsageStats,
  getUsageCredit,
  removeSalesProductPlan,
  schedulePlanChange,
  setAvailablePaymentMethods,
  setCurrentPlan,
  setNextPlan,
  updateRecurringFee,
  updateUsageCredit,
} from './billingClient';

export const INVOICES_QUERY_KEY = 'billing_invoices';
export const CYCLES_WITH_TOTAL_QUERY_KEY = 'billing_cycles_with_total';
export const UNINVOICED_USAGE = 'billing_uninvoiced_usage';
export const UNINVOICED_LEDGER_RECORDS = 'billing_uninvoiced_ledger_records';
export const USAGE_CREDIT = 'billing_usage_credit';
export const MARKETING_PLANS = 'billing_marketing_plans';
export const CUSTOM_MARKETING_PLANS = 'billing_custom_marketing_plans';
export const PRODUCT_PLANS = 'billing_product_plans';
export const CURRENT_PRODUCT_PLANS = 'billing_current_product_plans';
export const PACKAGES = 'billing_packages';
export const AVAILABLE_PAYMENT_METHODS = 'billing_available_payment_methods';
export const UNINVOICED_USAGE_STATS = 'billing_uninvoiced_usage_stats';
export const RECURRING_FEES = 'billing_recurring_fees';
export const SCHEDULED_PLAN_CHANGE = 'billing_scheduled_plan_change';
export const CURRENT_PLAN = 'billing_current_plan';
export const NEXT_PLAN = 'billing_next_plan';
export const CURRENT_AND_NEXT_CYCLES = 'billing_current_and_next_cycles';
export const PAST_CYCLES = 'billing_past_cycles';

const staleTime = 1000 * 60;

const toastError = (error: unknown) => toast.error(formatErrorMessage(error));

export function useGetInvoicesByCycle(
  cycleId?: number,
  options?: UseQueryOptions<
    Invoice[],
    any,
    Invoice[],
    [typeof INVOICES_QUERY_KEY, number]
  >,
): UseQueryResult<Invoice[]> {
  if (!cycleId) {
    return useQuery([INVOICES_QUERY_KEY, 0], () => [], {
      ...options,
      staleTime,
    });
  }

  return useQuery(
    [INVOICES_QUERY_KEY, cycleId],
    () => getInvoicesByCycle(cycleId),
    {
      ...options,
      staleTime,
    },
  );
}

export function useGetCyclesWithTotals(
  options?: UseQueryOptions<
    CycleWithTotals[],
    any,
    CycleWithTotals[],
    typeof CYCLES_WITH_TOTAL_QUERY_KEY
  >,
): UseQueryResult<CycleWithTotals[]> {
  return useQuery(CYCLES_WITH_TOTAL_QUERY_KEY, getCyclesWithTotals, {
    ...options,
    staleTime,
  });
}

export function useGetUninvoicedUsageAmount(
  options?: UseQueryOptions<number, any, number, typeof UNINVOICED_USAGE>,
): UseQueryResult<number> {
  return useQuery(UNINVOICED_USAGE, getUninvoicedUsageAmount, {
    ...options,
    staleTime,
  });
}

export function useGetUninvoicedLedgerRecords(
  options?: UseQueryOptions<
    LedgerRecord[],
    any,
    LedgerRecord[],
    typeof UNINVOICED_LEDGER_RECORDS
  >,
): UseQueryResult<LedgerRecord[]> {
  return useQuery(UNINVOICED_LEDGER_RECORDS, getUninvoicedLedgerRecords, {
    ...options,
    staleTime,
  });
}

export function useGetUsageCredit(
  options?: UseQueryOptions<UsageCredit, any, UsageCredit, typeof USAGE_CREDIT>,
): UseQueryResult<UsageCredit> {
  return useQuery(USAGE_CREDIT, getUsageCredit, {
    ...options,
    staleTime,
  });
}

export function useUpdateUsageCredit(): UseMutationResult<
  void,
  any,
  UsageCreditInput
> {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (args: UsageCreditInput) => updateUsageCredit(args),
    {
      onSuccess: () => queryClient.refetchQueries(USAGE_CREDIT),
      onError: toastError,
    },
  );

  return mutation;
}

export function useGetMarketingPlans(
  options?: UseQueryOptions<Plan[], any, Plan[], typeof MARKETING_PLANS>,
): UseQueryResult<Plan[]> {
  return useQuery(MARKETING_PLANS, getMarketingPlans, {
    ...options,
    staleTime,
  });
}

export function useGetProductPlans(
  type: ProductPlanTypes,
  options?: UseQueryOptions<
    ProductPlan[],
    any,
    ProductPlan[],
    typeof PRODUCT_PLANS
  >,
): UseQueryResult<ProductPlan[]> {
  return useQuery(PRODUCT_PLANS, () => getProductPlans(type), {
    ...options,
    staleTime,
  });
}

export function useGetCurrentProductPlans(
  type: ProductPlanTypes,
  options?: UseQueryOptions<
    ProductPlan[],
    any,
    ProductPlan[],
    typeof CURRENT_PRODUCT_PLANS
  >,
): UseQueryResult<ProductPlan[]> {
  return useQuery(CURRENT_PRODUCT_PLANS, () => getCurrentProductPlans(type), {
    ...options,
    staleTime,
  });
}

export function useAssignProductPlan(): UseMutationResult<void, any, number> {
  const mutation = useMutation((id: number) => assignProductPlan(id), {
    onError: toastError,
  });

  return mutation;
}

export function useRemoveSalesProductPlan(): UseMutationResult<
  void,
  any,
  number
> {
  const mutation = useMutation((id: number) => removeSalesProductPlan(id), {
    onError: toastError,
  });

  return mutation;
}

export function useGetCustomMarketingPlans(
  options?: UseQueryOptions<Plan[], any, Plan[], typeof CUSTOM_MARKETING_PLANS>,
): UseQueryResult<Plan[]> {
  return useQuery(CUSTOM_MARKETING_PLANS, getCustomMarketingPlans, {
    ...options,
    staleTime,
  });
}

export function useGetPackages(
  options?: UseQueryOptions<Package[], any, Package[], typeof PACKAGES>,
): UseQueryResult<Package[]> {
  return useQuery(PACKAGES, getPackages, {
    ...options,
    staleTime,
  });
}

export function useGetAvailablePaymentMethods(
  options?: UseQueryOptions<
    AvailablePaymentMethods,
    any,
    AvailablePaymentMethods,
    typeof AVAILABLE_PAYMENT_METHODS
  >,
): UseQueryResult<AvailablePaymentMethods> {
  return useQuery(AVAILABLE_PAYMENT_METHODS, getAvailablePaymentMethods, {
    ...options,
    staleTime,
  });
}

export function useSetAvailablePaymentMethods(): UseMutationResult<
  void,
  any,
  AvailablePaymentMethods
> {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (values: AvailablePaymentMethods) => setAvailablePaymentMethods(values),
    {
      onSuccess: () => queryClient.refetchQueries(AVAILABLE_PAYMENT_METHODS),
      onError: toastError,
    },
  );

  return mutation;
}

export function useGetUninvoicedUsageStats(
  options?: UseQueryOptions<
    LedgerRecordStat[],
    any,
    LedgerRecordStat[],
    typeof UNINVOICED_USAGE_STATS
  >,
): UseQueryResult<LedgerRecordStat[]> {
  return useQuery(UNINVOICED_USAGE_STATS, getUninvoicedUsageStats, {
    ...options,
    staleTime,
  });
}

export function useGetRecurringFees(
  search?: SearchParams,
  options?: UseQueryOptions<
    RecurringFee[],
    any,
    RecurringFee[],
    [typeof RECURRING_FEES, SearchParams | undefined]
  >,
): UseQueryResult<RecurringFee[]> {
  return useQuery([RECURRING_FEES, search], () => getRecurringFees(search), {
    ...options,
    staleTime,
  });
}

export function useCreateRecurringFee(): UseMutationResult<
  void,
  any,
  RecurringFeeInput
> {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (values: RecurringFeeInput) => createRecurringFee(values),
    {
      onSuccess: () => queryClient.refetchQueries(RECURRING_FEES),
      onError: toastError,
    },
  );

  return mutation;
}

interface UpdateRecurringFeeInput {
  id: number;
  recurringFee: RecurringFeeInput;
}

export function useUpdateRecurringFee(): UseMutationResult<
  void,
  any,
  UpdateRecurringFeeInput
> {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (values: UpdateRecurringFeeInput) => updateRecurringFee(values),
    {
      onSuccess: () => queryClient.refetchQueries(RECURRING_FEES),
      onError: toastError,
    },
  );

  return mutation;
}

export function useDisableRecurringFee(): UseMutationResult<void, any, number> {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (values: number) => disableRecurringFee(values),
    {
      onSuccess: () => queryClient.refetchQueries(RECURRING_FEES),
      onError: toastError,
    },
  );

  return mutation;
}

export function useGetScheduledPlanChange(
  options?: UseQueryOptions<
    ScheduledPlanChange | null,
    any,
    ScheduledPlanChange | null,
    typeof SCHEDULED_PLAN_CHANGE
  >,
): UseQueryResult<ScheduledPlanChange | null> {
  return useQuery(SCHEDULED_PLAN_CHANGE, getScheduledPlanChange, {
    ...options,
    staleTime,
  });
}

export function useSchedulePlanChange(): UseMutationResult<
  void,
  any,
  ScheduledPlanChange
> {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (values: ScheduledPlanChange) => schedulePlanChange(values),
    {
      onSuccess: () => {
        queryClient.refetchQueries(SCHEDULED_PLAN_CHANGE);
        queryClient.refetchQueries(CURRENT_AND_NEXT_CYCLES);
        queryClient.refetchQueries(CURRENT_PLAN);
        queryClient.refetchQueries(NEXT_PLAN);
      },
      onError: toastError,
    },
  );

  return mutation;
}

export function useCancelScheduledPlanChange(): UseMutationResult<
  void,
  any,
  void
> {
  const queryClient = useQueryClient();

  const mutation = useMutation(cancelScheduledPlanChange, {
    onSuccess: () => {
      queryClient.refetchQueries(SCHEDULED_PLAN_CHANGE);
      queryClient.refetchQueries(CURRENT_AND_NEXT_CYCLES);
    },
    onError: toastError,
  });

  return mutation;
}

export function useGetCurrentPlan(
  options?: UseQueryOptions<Plan, any, Plan, typeof CURRENT_PLAN>,
): UseQueryResult<Plan> {
  return useQuery(CURRENT_PLAN, getCurrentPlan, {
    ...options,
    staleTime,
  });
}

export function useSetCurrentPlan(): UseMutationResult<
  void,
  any,
  PlanChangeInput
> {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (values: PlanChangeInput) => setCurrentPlan(values),
    {
      onSuccess: () => {
        queryClient.refetchQueries(CURRENT_PLAN);
        queryClient.refetchQueries(NEXT_PLAN);
        queryClient.refetchQueries(CURRENT_AND_NEXT_CYCLES);
      },
      onError: toastError,
    },
  );

  return mutation;
}

export function useForceSetCurrentPlan(): UseMutationResult<void, any, number> {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (planId: number) => forceSetCurrentPlan(planId),
    {
      onSuccess: () => {
        queryClient.refetchQueries(CURRENT_PLAN);
        queryClient.refetchQueries(CURRENT_AND_NEXT_CYCLES);
      },
      onError: toastError,
    },
  );

  return mutation;
}

export function useGetNextPlan(
  options?: UseQueryOptions<Plan, any, Plan, typeof NEXT_PLAN>,
): UseQueryResult<Plan> {
  return useQuery(NEXT_PLAN, getNextPlan, {
    ...options,
    staleTime,
  });
}

export function useSetNextPlan(): UseMutationResult<
  void,
  any,
  PlanChangeInput
> {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (values: PlanChangeInput) => setNextPlan(values),
    {
      onSuccess: () => {
        queryClient.refetchQueries(NEXT_PLAN);
        queryClient.refetchQueries(CURRENT_AND_NEXT_CYCLES);
      },
      onError: toastError,
    },
  );

  return mutation;
}

export function useGetCurrentAndNextCycles(
  options?: UseQueryOptions<
    Cycles,
    any,
    Cycles,
    typeof CURRENT_AND_NEXT_CYCLES
  >,
): UseQueryResult<Cycles> {
  return useQuery(CURRENT_AND_NEXT_CYCLES, getCurrentAndNextCycles, {
    ...options,
    staleTime,
  });
}

export function useGetPastCycles(
  options?: UseQueryOptions<Cycle[], any, Cycle[], typeof PAST_CYCLES>,
): UseQueryResult<Cycle[]> {
  return useQuery(PAST_CYCLES, getPastCycles, {
    ...options,
    staleTime,
  });
}

import { Button, Input, Layout, toast } from '@postscript/components';
import { Field, Form, Formik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';
import { useUpdateUserProfile } from '../hooks/useUsers';
import { User } from '../types';
import Avatar from './Avatar';

const StyledLayout = styled(Layout)`
  margin-bottom: var(--spacing-3);
`;

interface Props {
  user: User;
}

export default function ProfileForm({ user }: Props): JSX.Element {
  const { mutateAsync } = useUpdateUserProfile();
  const { username, profile } = user;

  return (
    <Formik
      validationSchema={yup.object().shape({
        username: yup.string(),
        profile: yup.object().shape({
          avatarUrl: yup.string().nullable(),
          firstName: yup.string().required('First name is required').nullable(),
          lastName: yup.string().nullable(),
        }),
      })}
      initialValues={{
        username,
        profile: {
          avatarUrl: profile?.avatarUrl || '',
          firstName: profile?.firstName || '',
          lastName: profile?.lastName || '',
        },
      }}
      onSubmit={async (values) => {
        try {
          await mutateAsync(values);
          toast.success('Profile updated.');
        } catch (error) {
          toast.error(error as string);
        }
      }}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <StyledLayout valign="top" gap="var(--spacing-5)">
            <Avatar />
            <div>
              <StyledLayout valign="top">
                <Field
                  id="profile.firstName"
                  name="profile.firstName"
                  label="First name"
                  as={Input}
                  error={
                    touched.profile?.firstName && errors.profile?.firstName
                  }
                  errorMessage={errors.profile?.firstName}
                />
                <Field
                  id="profile.lastName"
                  name="profile.lastName"
                  label="Last name"
                  as={Input}
                  error={touched.profile?.lastName && errors.profile?.lastName}
                  errorMessage={errors.profile?.lastName}
                />
              </StyledLayout>
              <Field
                id="username"
                name="username"
                label="Email address"
                as={Input}
                error={touched.username && errors.username}
                errorMessage={errors.username}
                message="Email address cannot be changed"
                disabled
              />
            </div>
          </StyledLayout>
          <Layout align="right">
            <Button type="submit" variant="secondary" disabled={isSubmitting}>
              Save Changes
            </Button>
          </Layout>
        </Form>
      )}
    </Formik>
  );
}

/* eslint-disable camelcase, no-param-reassign, no-case-declarations */

import { toast } from '@postscript/components';
import { api } from 'controllers/network/apiClient';
import produce from 'immer';
import React, { createContext, useContext, useEffect, useReducer } from 'react';

export const RESPONSES_PS_LAB = 'responses_2';

export const LABS_FEATURE_STATUS = {
  development: 'development',
  beta: 'beta',
  released: 'released',
} as const;

export type LabsFeatureStatus = keyof typeof LABS_FEATURE_STATUS;

export type LabsFeaturePayload = {
  name: string;
  key: string;
  status: LabsFeatureStatus;
  description: string;
  image_url?: string;
  feedback_url?: string;
  documentation_url?: string;
  is_self_serve: boolean;
  redirect_url?: string;
  enabled: boolean;
  percentage?: number;
};

export type LabsFeature = {
  name: string;
  key: string;
  status: LabsFeatureStatus;
  description: string;
  imageUrl?: string;
  feedbackUrl?: string;
  documentationUrl?: string;
  isSelfServe: boolean;
  redirectUrl?: string;
  enabled: boolean;
  percentage?: number;
};

const payloadToFeature = (payload: LabsFeaturePayload[]): LabsFeature[] => {
  const status: LabsFeature[] = [];

  payload.forEach((feature) => {
    status.push({
      name: feature.name,
      key: feature.key,
      status: feature.status,
      description: feature.description,
      imageUrl: feature.image_url,
      feedbackUrl: feature.feedback_url,
      documentationUrl: feature.documentation_url,
      isSelfServe: feature.is_self_serve,
      redirectUrl: feature.redirect_url,
      enabled: feature.enabled,
      percentage: feature.percentage,
    });
  });

  return status;
};

const featureToPayload = (feature: LabsFeature): LabsFeaturePayload => {
  const payload: LabsFeaturePayload = {
    name: feature.name,
    key: feature.key,
    status: feature.status,
    description: feature.description,
    image_url: feature.imageUrl,
    feedback_url: feature.feedbackUrl,
    documentation_url: feature.documentationUrl,
    is_self_serve: feature.isSelfServe,
    redirect_url: feature.redirectUrl,
    enabled: feature.enabled,
  };

  return payload;
};

const SET_FEATURES = 'set_features';
const SET_ALL_FEATURES = 'set_all_features';
const SET_SELF_SERVE_FEATURES = 'set_self_serve_features';
const INIT = 'init';

interface Init {
  type: typeof INIT;
}

interface SetFeatures {
  type: typeof SET_FEATURES;
  data: LabsFeature[];
}

interface SetAllFeatures {
  type: typeof SET_ALL_FEATURES;
  data: LabsFeature[];
}

interface SetSelfServeFeatures {
  type: typeof SET_SELF_SERVE_FEATURES;
  data: LabsFeature[];
}

type ReducerAction = Init | SetFeatures | SetSelfServeFeatures | SetAllFeatures;

const sortFeaturesAlphabetically = (features: LabsFeature[]) => {
  features.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
};

const sortFeaturesByEnabled = (features: LabsFeature[]) => {
  features.sort((a, b) => {
    if (a.enabled && !b.enabled) return -1;
    if (!a.enabled && b.enabled) return 1;
    return 0;
  });
};

interface State {
  hasInitialized: boolean;
  features: LabsFeature[];
  allFeatures: LabsFeature[];
  selfServeFeatures: LabsFeature[];
  addFeature: (key: string, feature: LabsFeature) => void;
  removeFeature: (feature: LabsFeature) => void;
  updateShopFeature: (feature: LabsFeature) => void;
  hasLabsFlag: (key: string) => boolean;
  toggleFeaturePercentage: (
    key: string,
    isEnabled: boolean,
    percentage: number,
  ) => Promise<void>;
}

const initialState: State = {
  hasInitialized: false,
  features: [],
  allFeatures: [],
  selfServeFeatures: [],
  addFeature: () => undefined,
  removeFeature: () => undefined,
  updateShopFeature: () => undefined,
  hasLabsFlag: () => false,
  toggleFeaturePercentage: () => Promise.resolve(),
};

const reducerFn = (draft: State, action: ReducerAction) => {
  switch (action.type) {
    case INIT:
      draft.hasInitialized = true;
      break;
    case SET_FEATURES:
      const setFeatures: LabsFeature[] = [];
      action.data.forEach((feature) => setFeatures.push(feature));
      draft.features = setFeatures;
      break;
    case SET_ALL_FEATURES:
      const setAllFeatures: LabsFeature[] = [];
      action.data.forEach((feature) => setAllFeatures.push(feature));
      draft.allFeatures = setAllFeatures;
      draft.features = draft.features.filter((f) =>
        setAllFeatures.some((s) => s.key === f.key),
      );
      break;
    case SET_SELF_SERVE_FEATURES:
      const setSelfServeFeatures: LabsFeature[] = [];
      action.data.forEach((feature) => setSelfServeFeatures.push(feature));
      draft.selfServeFeatures = setSelfServeFeatures;
      break;
    default:
      throw new Error('Unsupported action type dispatched.');
  }
};

export const LabsFeatureContext = createContext(initialState);
export const usePSLabs = (): State => useContext(LabsFeatureContext);

interface Props {
  children: JSX.Element;
}

export const LabsFeatureProvider = ({ children }: Props): JSX.Element => {
  const reducer = produce(reducerFn);
  const [state, dispatch] = useReducer(reducer, initialState);

  const setFeatures = (features: LabsFeature[]) => {
    dispatch({
      type: SET_FEATURES,
      data: features,
    });
  };

  const setAllFeatures = (features: LabsFeature[]) => {
    dispatch({
      type: SET_ALL_FEATURES,
      data: features,
    });
  };

  const setSelfServeFeatures = (features: LabsFeature[]) => {
    const selfServeFeatures: LabsFeature[] = [];

    features.forEach((value) => {
      if (value.isSelfServe) selfServeFeatures.push(value);
    });
    sortFeaturesByEnabled(selfServeFeatures);
    dispatch({
      type: SET_SELF_SERVE_FEATURES,
      data: selfServeFeatures,
    });
  };

  const getFeatures = async () => {
    try {
      const payload = await api.get('/v2/labs/features');
      const features = payloadToFeature(payload.features);

      sortFeaturesAlphabetically(features);
      setAllFeatures(features);
      setSelfServeFeatures(features);
    } catch (err) {
      console.error(err);
    }
  };

  const getShopFeatures = async () => {
    try {
      const payload = await api.get('/v2/shops/labs/features');
      const features = payloadToFeature(payload.features);

      setFeatures(features);
    } catch (err) {
      console.error(err);
    }
  };

  const removeFeature = async (feature: LabsFeature) => {
    try {
      await api.delete(`/v2/labs/features/${feature.key}`);

      await getFeatures();
    } catch (err) {
      console.error(err);
      getFeatures();
    }
  };

  const createFeature = async (feature: LabsFeature) => {
    try {
      const payload = featureToPayload(feature);
      await api.post('/v2/labs/features', payload);
    } catch (err: any) {
      toast.error(err);
    }
  };

  const updateFeature = async (key: string, feature: LabsFeature) => {
    try {
      const payload = featureToPayload(feature);
      await api.put(`/v2/labs/features/${key}`, payload);
    } catch (err: any) {
      toast.error(err);
    }
  };

  const updateShopFeature = async (feature: LabsFeature) => {
    try {
      const payload = featureToPayload(feature);
      await api.put(`/v2/shops/labs/features/${feature.key}`, payload);
      getShopFeatures();
    } catch (err: any) {
      toast.error(err);
    }
  };

  const addFeature = async (key: string, feature: LabsFeature) => {
    if (state.allFeatures.some((featureValue) => key === featureValue.key)) {
      await updateFeature(key, feature);
    } else {
      await createFeature(feature);
    }

    getFeatures();
    getShopFeatures();
  };

  const toggleFeaturePercentage = async (
    key: string,
    isEnabled: boolean,
    percentage: number,
  ) => {
    try {
      await api.post('/v2/labs/features/toggle', {
        enabled: isEnabled,
        feature_key: key,
        percent: percentage,
      });

      getFeatures();
      getShopFeatures();
    } catch (err: any) {
      toast.error(err);
    }
  };

  const hasLabsFlag = (key: string) =>
    state.features.some((feature) => feature.key === key && feature.enabled);

  useEffect(() => {
    (async () => {
      if (!state.hasInitialized) {
        await getFeatures();
        await getShopFeatures();

        dispatch({
          type: INIT,
        });
      }
    })();
  }, []);

  return (
    <LabsFeatureContext.Provider
      value={{
        ...state,
        addFeature,
        updateShopFeature,
        removeFeature,
        hasLabsFlag,
        toggleFeaturePercentage,
      }}
    >
      {children}
    </LabsFeatureContext.Provider>
  );
};

export const withPSLabs = (Component: React.FC<any>): React.FC<any> => {
  return (props: any) => {
    const psLabs = usePSLabs();
    return <Component psLabs={psLabs} {...props} />;
  };
};

/* eslint-disable no-use-before-define */
/* eslint-disable radix */
import { useContext } from 'react';
import { HistoryContext, LocationContext } from '../controllers/contexts';

/* Build query parameters from the url. */
const useQueryParams = () => {
  const history = useContext(HistoryContext);
  const location = useContext(LocationContext);

  const loadedParams = new URLSearchParams(location.search);
  const { pathname } = location;

  const filters = {};
  loadedParams.forEach((v, k) => {
    if (k.includes('__')) {
      // TODO: Make this robust.
      filters[k] = v;
    }
  });

  let params = {
    sort: loadedParams.get('sort'),
    page: parseInt(loadedParams.get('page')) || 1,
    filters,
    from: loadedParams.get('from'),
    to: loadedParams.get('to'),
  };

  const navigate = () => {
    const newParams = new URLSearchParams(getParams());
    history.push(`${pathname}?${newParams.toString()}`);
  };

  const changeSorting = (field, order, remove) => {
    params = remove
      ? { ...params, sort: null }
      : { ...params, sort: `${field}__${order}` };
    navigate();
  };

  const changePage = (num) => {
    params = { ...params, page: num };
    navigate();
  };

  const changeDateRange = (from, to) => {
    params = { ...params, from, to };
    navigate();
  };

  const changeFiltering = (field, value) => {
    const filter = `${field}__contains`;
    if (!value || value === '') {
      params = { ...params, filters: { ...params.filters, [filter]: null } };
    } else {
      params = { ...params, filters: { ...params.filters, [filter]: value } };
    }
    navigate();
  };

  const getParams = () => {
    const queryParams = {};
    if (params.sort) {
      queryParams.sort = params.sort;
    }
    if (params.page) {
      queryParams.page = params.page;
    }
    if (params.from) {
      queryParams.from = params.from;
    }
    if (params.to) {
      queryParams.to = params.to;
    }
    Object.keys(params.filters).forEach((key) => {
      if (params.filters[key]) {
        queryParams[key] = params.filters[key];
      }
    });

    return queryParams;
  };

  return {
    changeFiltering,
    changePage,
    changeSorting,
    changeDateRange,
    getParams,
  };
};

export default useQueryParams;

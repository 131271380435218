import { useEffect, useState } from 'react';
import { sumLedgerRecords } from '../../../common/utils';
import {
  useGetUninvoicedLedgerRecords,
  useGetUninvoicedUsageAmount,
  useGetUsageCredit,
} from '../../../context/useBilling';

export default function useGetUsageCreditUndeductedUsage(): number {
  const [amount, setAmount] = useState(0);
  const { data: uninvoicedUsage, isLoading: loadingUninvoicedUsage } =
    useGetUninvoicedUsageAmount();
  const {
    data: uninvoicedLedgerRecords,
    isLoading: loadingUninvoicedLedgerRecords,
  } = useGetUninvoicedLedgerRecords();
  const { data: usageCredit, isLoading: loadingUsageCredit } =
    useGetUsageCredit();

  useEffect(() => {
    if (
      !usageCredit ||
      loadingUninvoicedUsage ||
      loadingUninvoicedLedgerRecords ||
      loadingUsageCredit
    ) {
      return;
    }

    const uninvoicedUsageLedgerRecordsAmount = sumLedgerRecords(
      uninvoicedLedgerRecords || [],
      {
        ledgerRecordTypes: ['USAGE_CHARGE', 'CARRIER_FEES'],
      },
    );
    const messagingFeesToday =
      (uninvoicedUsage || 0) - uninvoicedUsageLedgerRecordsAmount;
    const ledgerRecordsSinceDeduction =
      uninvoicedLedgerRecords?.filter(
        ({ createdAt }) =>
          new Date(createdAt) > new Date(usageCredit.lastDeductedAt),
      ) || [];
    const carrierFeesAmountSinceDeduction = sumLedgerRecords(
      ledgerRecordsSinceDeduction,
      { ledgerRecordTypes: ['CARRIER_FEES'] },
    );
    const undeductedUsage =
      messagingFeesToday + carrierFeesAmountSinceDeduction;

    setAmount(undeductedUsage);
  }, [
    usageCredit,
    uninvoicedUsage,
    uninvoicedLedgerRecords,
    loadingUninvoicedUsage,
    loadingUninvoicedLedgerRecords,
    loadingUsageCredit,
  ]);

  return amount;
}

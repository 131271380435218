/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable chai-friendly/no-unused-expressions */
/* eslint-disable no-continue */
/* eslint-disable no-throw-literal */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable default-case */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-absolute-path */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-empty */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable func-names */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-alert */
// Button to let admins turn on extra keywords for stores.
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/extensions */

import { toast } from '@postscript/components';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import Events from '../../controllers/events';
import Requests from '../../controllers/network/network_requests';
import AuthPage, { AuthButton } from '../authentication/AuthPage';

const initialState = {
  email: '',
  request_sent: false,
};

class ResetPasswordRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.emailChange = this.emailChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }

  componentDidMount() {}

  emailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  formSubmit = async () => {
    if (!this.validateEmail(this.state.email)) {
      toast.error('Please enter a valid email');
      return;
    }
    Events.track('Request Password Reset');

    const path = '/v2/auth/reset_password/send_email/';
    const payload = {
      email: this.state.email,
    };

    try {
      await Requests.put(path, payload);
      this.setState({
        request_sent: true,
      });
    } catch (e) {
      toast.error(e);
    }
  };

  render() {
    return (
      <AuthPage headingText="Password Reset">
        {this.state.request_sent ? (
          <div className="text">
            If that email is registered, we'll send a password reset link to
            your inbox right away!
            <br />
            <br />
            <Link to="/login" className="text">
              Go to Login
            </Link>
          </div>
        ) : (
          <Form>
            <div>
              <FormGroup>
                <Label for="exampleEmail" hidden />
                <Input
                  onChange={this.emailChange}
                  name="email"
                  placeholder="Email Address"
                  value={this.state.email}
                  bsSize="lg"
                  data-cy="reset-pw-email-input"
                />
              </FormGroup>
            </div>
            {/* @Colin Align this center please */}
            <div id="login-register-button-container">
              <AuthButton
                size="large"
                variant="primary"
                onClick={this.formSubmit}
                data-cy="reset-pw-email-confirm"
              >
                Reset Password
              </AuthButton>
              <div id="login-register-switch-button">
                <Link to="/login">Go to Login</Link>
              </div>
            </div>
          </Form>
        )}
      </AuthPage>
    );
  }
}

ResetPasswordRequest.propTypes = {
  changeState: PropTypes.func.isRequired,
};

export default ResetPasswordRequest;

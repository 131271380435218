import { IconSet } from '@postscript/components';
import { PERMISSIONS } from 'components/account/AccountView/users/constants';
import {
  FLOW_BUILDER_INTEGRATION_INTO_LEGACY,
  PS_LABS,
  PS_PAY,
  USAGE_BILLING_ENABLED,
} from 'components/admin/utils/feature-flags';
import { FEATURE_KEYS } from 'components/billing/common/constants';
import { NavMenuItem } from 'components/navigation/NavMenu';
import UnresolvedNotificationsBadge from 'components/responses/UnresolvedNotificationsBadge';

const getAnalyticsLink = (url: string) =>
  window.location.pathname.includes('/analytics')
    ? `${url}${window.location.search}`
    : url;

export const defaultMenuConfig: NavMenuItem[] = [
  {
    ariaLabelledBy: 'nav-dashboard-title',
    icon: IconSet.Dashboard,
    label: 'Dashboard',
    id: 'dashboard',
    link: '/',
  },
  {
    ariaLabelledBy: 'nav-messaging-title',
    icon: IconSet.Message,
    label: 'Messaging',
    id: 'messaging',
    notification: UnresolvedNotificationsBadge(true),
    subMenu: [
      { link: '/campaigns', label: 'Campaigns', id: 'messaging-campaigns' },
      {
        link: '/flows',
        label: 'Flow Builder',
        tag: 'New',
        id: 'messaging-flow-builder',
        labsFeatureFlag: FLOW_BUILDER_INTEGRATION_INTO_LEGACY,
        featureFlagOptOut: true,
      },
      {
        link: '/automations',
        label: 'Automations',
        id: 'messaging-automations',
      },
      { link: '/segments', label: 'Segments', id: 'messaging-segments' },
      {
        link: '/responses',
        label: 'Responses',
        id: 'messaging-responses',
        notification: UnresolvedNotificationsBadge(false),
      },
    ],
  },
  {
    ariaLabelledBy: 'nav-list-growth-title',
    icon: IconSet.UsersPlus,
    label: 'Acquisition',
    id: 'acquisition',
    restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
    subMenu: [
      { link: '/popups', label: 'Popups', id: 'acquisition-popups' },
      {
        link: '/keywords',
        label: 'Keywords',
        id: 'acquisition-keywords',
        gatedFeatureKey: FEATURE_KEYS.keywords,
      },
      {
        link: '/grow',
        label: 'List Growth Tools',
        id: 'acquisition-list-growth',
      },
      {
        link: '/checkout',
        label: 'Checkout Collection',
        id: 'acquisition-checkout',
      },
    ],
  },
  {
    ariaLabelledBy: 'nav-analytics-title',
    icon: IconSet.Leaderboard,
    label: 'Analytics',
    id: 'analytics',
    restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
    subMenu: [
      {
        link: (): string => getAnalyticsLink(`/analytics/messages`),
        label: 'Messages',
        id: 'analytics-messages',
      },
      {
        link: (): string => getAnalyticsLink(`/analytics/subscribers`),
        label: 'Subscribers',
        id: 'analytics-subscribers',
      },
      { link: '/account/reports', label: 'Reports', id: 'analytics-reports' },
    ],
  },
  {
    ariaLabelledBy: 'nav-pspay-title',
    icon: IconSet.PSTextToBuy,
    label: 'Text-to-Buy',
    id: 'pspay',
    labsFeatureFlag: PS_PAY,
    restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
    subMenu: [
      {
        link: '/pspay/manage',
        label: 'Branding',
        id: 'pspay-manage',
      },
    ],
  },
  {
    ariaLabelledBy: 'nav-apps-title',
    icon: IconSet.Apps,
    label: 'Apps',
    id: 'apps',
    restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
    subMenu: [
      { link: '/integrations/all', label: 'All', id: 'apps-all' },
      {
        link: '/integrations/affiliates',
        label: 'Affiliate',
        id: 'apps-affiliates',
      },
      {
        link: '/integrations/automations',
        label: 'Automation',
        id: 'apps-automations',
      },
      {
        link: '/integrations/conversion',
        label: 'Conversion',
        id: 'apps-conversion',
      },
      {
        link: '/integrations/customer-support',
        label: 'Customer Support',
        id: 'apps-customer-support',
      },
      {
        link: '/integrations/data',
        label: 'Data',
        id: 'apps-data',
      },
      {
        link: '/integrations/email',
        label: 'Email',
        id: 'apps-email',
      },
      {
        link: '/integrations/list-growth',
        label: 'List Growth',
        id: 'apps-list-growth',
      },
      {
        link: '/integrations/reviews-loyalty',
        label: 'Reviews & Loyalty',
        id: 'apps-reviews-loyalty',
      },
      {
        link: '/integrations/shipping',
        label: 'Shipping',
        id: 'apps-shipping',
      },
      {
        link: '/integrations/subscriptions',
        label: 'Subscription',
        id: 'apps-subscriptions',
      },
    ],
  },
  {
    ariaLabelledBy: 'nav-account-title',
    icon: IconSet.Settings2,
    label: 'Account',
    id: 'account',
    subMenu: [
      { link: '$selectShop', label: 'Select Shop', id: 'account-select-shop' },
      {
        link: '/account/settings',
        label: 'Shop Settings',
        id: 'account-settings',
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/profile',
        label: 'Profile',
        id: 'profile',
      },
      {
        link: '/account/users',
        label: 'Users',
        id: 'account-users',
        permission: PERMISSIONS.user_read,
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/account/tracking',
        label: 'Tracking',
        id: 'account-tracking',
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/account/plan',
        label: 'Plan',
        id: 'account-plan',
        featureFlag: USAGE_BILLING_ENABLED,
        featureFlagOptOut: true,
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/account/billing',
        label: 'Billing',
        id: 'account-billing',
        featureFlag: USAGE_BILLING_ENABLED,
        featureFlagOptOut: true,
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/billing',
        label: 'Billing',
        id: 'account-usage-billing',
        featureFlag: USAGE_BILLING_ENABLED,
        permission: PERMISSIONS.billing_read,
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/account/api',
        label: 'API',
        id: 'account-api',
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/account/ps-labs',
        label: 'PS Labs',
        id: 'account-ps-labs',
        featureFlag: PS_LABS,
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/account/contact-card',
        label: 'Shop Contact Card',
        id: 'account-contact-card',
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      { link: '$logOut', label: 'Logout', id: 'account-logout' },
    ],
  },
  {
    ariaLabelledBy: 'nav-admin-title',
    icon: IconSet.Key,
    label: 'Postscript Admin',
    id: 'admin',
    subMenu: [
      {
        link: '/account-overview',
        label: 'Account Overview',
        id: 'admin-account-overview',
      },
      {
        link: '/admin/templates',
        label: 'Templates',
        id: 'admin-templates',
      },
      {
        link: '/admin/billing',
        label: 'Billing',
        id: 'admin-billing',
        featureFlag: USAGE_BILLING_ENABLED,
        featureFlagOptOut: true,
      },
      {
        link: '/admin/messaging-status',
        label: 'Messaging',
        id: 'admin-messaging',
      },
      {
        link: '/admin/announcements',
        label: 'Service Announcements',
        id: 'admin-announcements',
      },
      {
        link: '/admin/ps-labs',
        label: 'PS Labs',
        id: 'admin-ps-labs',
      },
      { link: '/admin/partners', label: 'Partners', id: 'admin-partners' },
    ],
  },
];

export const salesMenuConfig: NavMenuItem[] = [
  {
    ariaLabelledBy: 'nav-sales-conversations-title',
    icon: IconSet.Message,
    label: 'My Prospects',
    id: 'inbox',
    link: '/workspace/my-prospects',
  },
  {
    ariaLabelledBy: 'nav-sales-all-prospects-title',
    icon: IconSet.Users,
    label: 'All Prospects',
    id: 'all-prospects',
    link: '/workspace/all-prospects',
  },
  {
    ariaLabelledBy: 'nav-sales-marketing-title',
    icon: IconSet.DoorExit,
    label: 'Marketing',
    id: 'marketing',
    link: '/',
  },
];

import { api } from 'controllers/network/apiClient';
import { ShopRole, User, UserProfile } from './types';

const usersUrl = '/v2/users/';
const userProfileUrl = '/v2/users/profile';

export async function getUsers(): Promise<User[]> {
  const { users } = await api.get(usersUrl);
  return users;
}

export interface AddShopUserPayload {
  username: string;
  roles: number[];
}

export async function addUser({
  username,
  roles,
}: AddShopUserPayload): Promise<void> {
  await api.post(usersUrl, { username, roles });
}

export async function updateUser(user: User): Promise<User> {
  await api.put(usersUrl, user);
  return user;
}

export async function deleteUser(guid: string): Promise<void> {
  await api.delete(`${usersUrl}${guid}`);
}

export async function inviteUsers(users: AddShopUserPayload[]): Promise<void> {
  await Promise.all(users.map((user) => api.post(usersUrl, user)));
}

export async function getUserProfile(): Promise<UserProfile> {
  const profile = await api.get(userProfileUrl);
  return profile;
}

export async function updateUserProfile(user: User): Promise<User> {
  await api.put(userProfileUrl, {
    ...user.profile,
    // We cannot send an empty string as the value of avatarUrl
    avatarUrl: user.profile?.avatarUrl || undefined,
  });
  return user;
}

export async function getShopRoles(): Promise<ShopRole[]> {
  const { roles } = await api.get('/v2/users/shop_roles');
  return roles;
}

export interface UpdateUserShopRolesPayload {
  userId: string;
  shopId: number;
  roles: number[];
}

export async function updateUserShopRoles({
  userId,
  shopId,
  roles,
}: UpdateUserShopRolesPayload): Promise<void> {
  await api.put(`/v2/users/${userId}/shop/${shopId}/roles`, {
    roles,
  });
}

import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { hasIn } from 'lodash';
import { SENTRY_RELEASE, SENTRY_SAMPLE_RATE } from 'utils/envVars';

const silentErrors = [
  `Unexpected token '<'`,
  `Can't find variable: _hsq`,
  '_hsq is not defined',
  'Please load Stripe',
  'pendo is not defined',
  `Can't find variable: pendo`,
  'amplitude is not defined',
  `Can't find variable: amplitude`,
  'window.zE is not a function',
];

export const initSentry = (environment) => {
  Sentry.init({
    dsn: 'https://63a92b5c13944fcab35b6f19347b4c97@o142330.ingest.sentry.io/1251963',
    release: `ps-app-prod@dev-${SENTRY_RELEASE}`,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: parseFloat(SENTRY_SAMPLE_RATE),
    environment,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    beforeSend(event, hint) {
      const error = hint.originalException;

      if (!hasIn(error, 'message')) return event;

      // Check that error isn't an error we want to swallow
      if (
        !silentErrors.some((silentError) => error.message.includes(silentError))
      )
        return event;

      return null;
    },
  });
};

// the provider for the charsets hook

import LoadingSpinner from '../components/generic/Loading/LoadingSpinner';
import { useCharsets } from '../hooks';

export const charsets = (Component) => {
  return (props) => {
    const {
      assessText,
      charsets,
      fetching,
      filterText,
      getCharacterCount,
      illegalCharsFromText,
      filterTextWithRichTextCharacters,
    } = useCharsets();
    if (fetching) {
      return <LoadingSpinner />;
    }

    return (
      <Component
        {...props}
        assessText={assessText}
        charsets={charsets}
        filterText={filterText}
        getCharacterCount={getCharacterCount}
        illegalCharsFromText={illegalCharsFromText}
        filterTextWithRichTextCharacters={filterTextWithRichTextCharacters}
      />
    );
  };
};

export default charsets;

import { InvoiceStatuses, MessageRateInput } from './types';

export const CHARACTER_COUNT_HELP_URL =
  'https://help.postscript.io/hc/en-us/articles/1260804629950';

export const MMS_ENABLED_COUNTRY_CODES = ['US', 'CA'];

export const DEFAULT_INITIAL_MESSAGE_RATES: MessageRateInput[] = [
  {
    countryCode: 'US',
    formattedSmsRate: '$0.00',
    formattedMmsRate: '$0.00',
  },
  {
    countryCode: 'CA',
    formattedSmsRate: '$0.00',
    formattedMmsRate: '$0.00',
  },
  {
    countryCode: '*',
    formattedSmsRate: '$0.00',
    formattedMmsRate: 'N/A',
  },
];

export const TYPICAL_MESSAGE_RATE_RANGES = {
  US: {
    sms: {
      low: 0.3,
      high: 1.5,
    },
    mms: {
      low: 0.9,
      high: 4.5,
    },
  },
  CA: {
    sms: {
      low: 0.3,
      high: 1.5,
    },
    mms: {
      low: 0.9,
      high: 4.5,
    },
  },
  '*': {
    sms: {
      low: 3,
      high: 15,
    },
    mms: {
      low: 0,
      high: 0,
    },
  },
} as const;

export const HUBSPOT_FORM_IDS = {
  PROFESSIONAL: '89f5db50-e3e6-4b02-b10b-13558f637101',
  ENTERPRISE: 'd65eb76d-cfe0-47ca-b12c-f31636fdf517',
};

export const LEDGER_RECORD_TYPES = {
  SMS_SALES_REVENUE_SHARE: {
    value: 'SMS_SALES_REVENUE_SHARE',
    name: 'SMS Sales Revenue Share',
  },
  USAGE_CHARGE: {
    value: 'USAGE_CHARGE',
    name: 'Messaging Fees',
  },
  SAAS_CHARGE: {
    value: 'SAAS_CHARGE',
    name: 'Platform Fee',
  },
  CARRIER_FEES: {
    value: 'CARRIER_FEES',
    name: 'Carrier Fees',
  },
  REFUND: {
    value: 'REFUND',
    name: 'Refund',
  },
  SHORT_CODE: {
    value: 'SHORT_CODE',
    name: 'Short Code',
  },
  RECURRING: {
    value: 'RECURRING',
    name: 'Recurring Fee',
  },
  CREDIT: {
    value: 'CREDIT',
    name: 'Credit',
  },
  USAGE_CREDIT: {
    value: 'USAGE_CREDIT',
    name: 'Usage Credit',
  },
  MINIMUM_SPEND_COMMITMENT: {
    value: 'MINIMUM_SPEND_COMMITMENT',
    name: 'Minimum Spend Commitment',
  },
  OTHER: {
    value: 'OTHER',
    name: 'Other',
  },
};

export const PACKAGES = {
  Starter: {
    description: 'Brands just getting started with SMS',
    includedFeaturesPlanName: undefined,
    hasFreeTrialCTA: false,
    features: [
      'Campaigns and Automations',
      'Unlimited Segments',
      'All subscriber acquisition tools, with 2 default opt-in Keywords',
      'Integrations',
      'Help center',
      'Unique toll-free number',
      'Reporting',
    ],
  },
  Growth: {
    description: 'Brands building up their SMS program',
    hasFreeTrialCTA: false,
    includedFeaturesPlanName: 'Starter',
    features: ['Unlimited keywords', 'Live chat and email support'],
  },
  Professional: {
    description:
      'Established brands who are scaling up SMS as a top revenue channel',
    hasFreeTrialCTA: true,
    includedFeaturesPlanName: 'Growth',
    features: [
      'Onboarding Specialist to help you launch',
      'Dedicated Customer Success Manager for SMS strategy',
      'Priority live chat and email support',
      'Tier 1 engineering support',
      'API access for custom integrations',
      '"Powered by Postscript" removed from opt-in tools',
      'Custom branded short link subdomains',
    ],
  },
  Enterprise: {
    description:
      'Established brands with a long-term commitment to SMS success',
    hasFreeTrialCTA: false,
    includedFeaturesPlanName: 'Professional',
    features: [
      'Dedicated Short Code included',
      'Dedicated Enterprise Customer Success Manager for SMS strategy',
      'Data Warehouse Push',
      'Send personalized messages with custom product recommendations',
    ],
  },
};

export const MULTIPLIER_FRACTION_DIGITS = 10;

export const INVOICING_STRATEGIES = {
  IMMEDIATE: {
    value: 'IMMEDIATE',
    name: 'Immediate',
    description: 'on every ledger record (excludes carrier fees)',
  },
  MONTHLY: {
    value: 'MONTHLY',
    name: 'Monthly',
    description: 'end of the month',
  },
};

export const INVOICING_FREQUENCIES = {
  MONTHLY: 'MONTHLY',
  IMMEDIATE: 'IMMEDIATE',
};

export const PLAN_TYPES = {
  STANDARD: 'standard',
  FREE_TRIAL: 'free_trial',
};

export const LONG_DATE_FORMAT = 'MMM D, YYYY';
export const SHORT_DATE_FORMAT = 'MM/DD/YYYY';
export const MONTH_YEAR_DATE_FORMAT = 'MMMM YYYY';

export const PAYMENT_PROVIDERS = { SHOPIFY: 'SHOPIFY', STRIPE: 'STRIPE' };

export const RAC_APPROVED_SUCCESS_PATH = '/dashboard?rac_approved=true';

export const RAC_STATUSES = {
  PENDING: 'pending',
  ACTIVE: 'active',
};

export const PAYMENT_METHOD_BANNER_ID = 'payment-method-banner';

export const SHOP_PAST_DUE_BANNER_ID = 'shop-past-due-banner';

export const SHOP_FAILED_INVOICE_BANNER_ID = 'shop-failed-invoice-banner';

export const RAC_FLOW_BANNER_ID = 'rac-flow-banner';

export const RAC_APPROVED_BANNER_ID = 'rac-approved-banner';

export const LEGACY_BILLING_PAST_DUE_BANNER_ID =
  'legacy-billing-past-due-banner';

export const INVOICE_PAYMENT_WARNING_SHOPIFY =
  'Please confirm your pending Shopify Recurring Application Charge.';

export const INVOICE_PAYMENT_WARNING_STRIPE = 'Please add a payment method.';

export const RECURRING_FEE_FREQUENCIES = {
  DAILY: {
    value: 'DAILY',
    name: 'Daily',
  },
  WEEKLY: {
    value: 'WEEKLY',
    name: 'Weekly',
  },
  MONTHLY: {
    value: 'MONTHLY',
    name: 'Monthly',
  },
  QUARTERLY: {
    value: 'QUARTERLY',
    name: 'Quarterly',
  },
  ANNUALLY: {
    value: 'ANNUALLY',
    name: 'Annually',
  },
};

export const RECURRING_FEE_TYPES = {
  GENERIC: {
    value: 'GENERIC',
    name: 'Generic',
  },
  SAAS_FEE: {
    value: 'SAAS_FEE',
    name: 'Platform fee',
  },
  CARRIER_FEES: {
    value: 'CARRIER_FEES',
    name: 'Carrier Fees',
  },
  PS_PLUS: {
    value: 'PS_PLUS',
    name: 'PS Plus',
  },
  SHORT_CODE: {
    value: 'SHORT_CODE',
    name: 'Short Code',
  },
  SMS_SALES_FIXED_FEE: {
    value: 'SMS_SALES_FIXED_FEE',
    name: 'SMS Sales Fixed Fee',
  },
};

export const DEFAULT_PREFERENCES = {
  hideInactiveRecurringFees: true,
};

export const DATEPICKER_DATE_FORMAT = 'YYYY-MM-DD';

export const INVOICE_STATUSES = {
  PROCESSING: 'PROCESSING',
  PENDING: 'PENDING',
  PAID: 'PAID',
  FAILED: 'FAILED',
  FORGIVEN: 'FORGIVEN',
} as const;

export const PAYABLE_INVOICE_STATUSES: Partial<InvoiceStatuses>[] = [
  INVOICE_STATUSES.PENDING,
  INVOICE_STATUSES.FAILED,
];

export const SETTLED_INVOICE_STATUSES: Partial<InvoiceStatuses>[] = [
  INVOICE_STATUSES.PAID,
  INVOICE_STATUSES.FORGIVEN,
];

export const FEATURE_KEYS = {
  powered_by_postscript_disabled: 'powered_by_postscript_disabled',
  keywords: 'keywords',
  api_access: 'api_access',
  use_subscriber_tz: 'use_subscriber_tz',
  popup_ab_testing: 'popup_ab_testing',
  text_to_buy: 'pspay',
};

export const FREE_USAGE_CREDIT_BANNER_ID = 'free-usage-credit-banner';

export const FREE_TRIAL_BANNER_ID = 'free-trial-banner';

export const FREE_USAGE_CREDIT_AND_FREE_TRIAL_BANNER_ID =
  'free-usage-credit-and-free-trial-banner';

export const TYPICAL_CARRIER_FEES_BY_COUNTRY = {
  US: {
    sms: 0.275,
    mms: 0.6,
  },
  CA: {
    sms: 0.65,
    mms: 1.5,
  },
};

import { toast } from '@postscript/components';
import { USAGE_BILLING_ENABLED } from 'components/admin/utils/feature-flags';
import {
  CustomPlanInput,
  Cycle,
  FeatureKeys,
  FreeTrial,
  FreeTrialInput,
  HasPackageFeatureArgs,
  Invoice,
  InvoicesResponse,
  InvoiceStatuses,
  InvoicingSettings,
  InvoicingStatus,
  LedgerRecordInput,
  PaymentMethod,
  PaymentProviders,
  PlanTypes,
  Preferences,
  RecurringApplicationCharge,
  SearchParams,
  ValueOf,
} from 'components/billing/common/types';
import { getCents } from 'components/billing/common/utils';
import { BankAccount, Card } from 'components/generic/types';
import { useGlobalModal } from 'components/GlobalModal/globalModal';
import { useFeatureFlags } from 'controllers/contexts/featureFlags';
import { useUsers } from 'controllers/contexts/user';
import { api } from 'controllers/network/apiClient';
import produce from 'immer';
import qs from 'qs';
import { createContext, useContext, useEffect, useReducer } from 'react';
import {
  DEFAULT_PREFERENCES,
  INVOICE_STATUSES,
  PAYMENT_PROVIDERS,
  RAC_APPROVED_SUCCESS_PATH,
  RAC_STATUSES,
} from '../common/constants';
import PaymentMethodsModal from '../modules/payments/PaymentMethodsModal';
import { useGetCurrentPlan } from './useBilling';

const UPDATE_PAYMENT_PROVIDER = 'UPDATE_PAYMENT_PROVIDER';
const UPDATE_RECURRING_APPLICATION_CHARGE =
  'UPDATE_RECURRING_APPLICATION_CHARGE';
const UPDATE_CARD = 'UPDATE_CARD';
const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD';
const UPDATE_INVOICES = 'UPDATE_INVOICES';
const UPDATE_INVOICE_SETTINGS = 'UPDATE_INVOICE_SETTINGS';
const UPDATE_CAN_PAY_INVOICE = 'UPDATE_CAN_PAY_INVOICE';
const UPDATE_INVOICING_STATUS = 'UPDATE_INVOICING_STATUS';
const UPDATE_PREFERENCES = 'UPDATE_PREFERENCES';
const UPDATE_FAILED_INVOICES = 'UPDATE_FAILED_INVOICES';
const UPDATE_FREE_TRIAL = 'UPDATE_FREE_TRIAL';
const UPDATE_SUMMARY_TOTAL = 'UPDATE_SUMMARY_TOTAL';
const UPDATE_IS_INITIAL_DATA_LOADED = 'UPDATE_IS_INITIAL_DATA_LOADED';
const UPDATE_SELECTED_CYCLE = 'UPDATE_SELECTED_CYCLE';

interface UpdatePaymentProvider {
  type: typeof UPDATE_PAYMENT_PROVIDER;
  paymentProvider: PaymentProviders;
}

interface UpdateRecurringApplicationCharge {
  type: typeof UPDATE_RECURRING_APPLICATION_CHARGE;
  recurringApplicationCharge: RecurringApplicationCharge | null;
}

interface UpdateCard {
  type: typeof UPDATE_CARD;
  card: Card | null;
}

interface UpdatePaymentMethod {
  type: typeof UPDATE_PAYMENT_METHOD;
  card: Card | null;
  usBankAccount: BankAccount | null;
}

interface UpdateInvoices {
  type: typeof UPDATE_INVOICES;
  invoices: Invoice[];
}

interface UpdateInvoicingSettings {
  type: typeof UPDATE_INVOICE_SETTINGS;
  invoicingSettings: InvoicingSettings;
}

interface UpdateCanPayInvoice {
  type: typeof UPDATE_CAN_PAY_INVOICE;
  canPayInvoice: boolean;
}

interface UpdateInvoicingStatus {
  type: typeof UPDATE_INVOICING_STATUS;
  invoicingStatus: InvoicingStatus;
}

interface UpdatePreferences {
  type: typeof UPDATE_PREFERENCES;
  preferences: Preferences;
}

interface UpdateFailedInvoices {
  type: typeof UPDATE_FAILED_INVOICES;
  unpaidInvoices: Invoice[];
}

interface UpdateSelectedCycle {
  type: typeof UPDATE_SELECTED_CYCLE;
  cycle: Cycle;
}

interface UpdateFreeTrial {
  type: typeof UPDATE_FREE_TRIAL;
  freeTrial: FreeTrial;
}

interface UpdateSummaryTotal {
  type: typeof UPDATE_SUMMARY_TOTAL;
  summaryTotal: number;
}

interface UpdateIsInitialDataLoaded {
  type: typeof UPDATE_IS_INITIAL_DATA_LOADED;
  isInitialDataLoaded: boolean;
}

type ReducerAction =
  | UpdatePaymentProvider
  | UpdateRecurringApplicationCharge
  | UpdateCard
  | UpdatePaymentMethod
  | UpdateInvoices
  | UpdateInvoicingSettings
  | UpdateCanPayInvoice
  | UpdateInvoicingStatus
  | UpdatePreferences
  | UpdateFailedInvoices
  | UpdateSelectedCycle
  | UpdateFreeTrial
  | UpdateSummaryTotal
  | UpdateIsInitialDataLoaded;

interface State {
  paymentProvider: PaymentProviders | null;
  card: Card | null;
  usBankAccount: BankAccount | null;
  recurringApplicationCharge: RecurringApplicationCharge | null;
  selectedCycle: Cycle | null;
  invoices: Invoice[];
  unpaidInvoices: Invoice[];
  invoicingSettings: InvoicingSettings;
  canPayInvoice: boolean;
  invoicingStatus: InvoicingStatus;
  preferences: Preferences;
  freeTrial: FreeTrial | null;
  summaryTotal: number;
  isInitialDataLoaded: boolean;
  getPaymentMethod: () => Promise<PaymentMethod | undefined>;
  addCustomPlan: (plan: CustomPlanInput) => Promise<void>;
  removeCustomPlan: (planId: number) => Promise<void>;
  updateSelectedCycle: (cycle: Cycle) => Promise<Cycle | undefined>;
  enableUsageBilling: (planType: string) => Promise<void>;
  getInvoices: (search?: SearchParams) => Promise<InvoicesResponse | undefined>;
  getUnpaidInvoices: () => Promise<InvoicesResponse | undefined>;
  generateInvoice: () => Promise<void>;
  payInvoice: (invoiceId: number) => Promise<void>;
  updateInvoiceStatus: (
    invoiceId: number,
    reason: string,
    status: InvoiceStatuses,
  ) => Promise<void>;
  addLedgerRecord: (record: LedgerRecordInput) => Promise<void>;
  getInvoicingSettings: () => Promise<InvoicingSettings | undefined>;
  setInvoicingSettings: (invoicingSettings: InvoicingSettings) => Promise<void>;
  generateRac: (
    returnUrl?: string,
  ) => Promise<RecurringApplicationCharge | undefined>;
  updateInvoicingStatus: () => Promise<void>;
  updatePreferences: (preferences: Preferences) => void;
  hasPackageFeature: ({
    featureKey,
    legacyBillingShopHasFeature,
    internalUserHasFeature,
  }: HasPackageFeatureArgs) => boolean;
  updateSummaryTotal: (summaryTotal: number) => void;
  showPaymentMethodsModal: () => void;
  getFreeTrial: () => Promise<FreeTrial | undefined>;
  createFreeTrial: (freeTrial: FreeTrialInput) => Promise<void>;
  updateFreeTrial: (id: number, expiresAt: string) => Promise<void>;
  moveToStripe: () => Promise<void>;
  changeToNoChargeCap: (shopId: number) => Promise<void>;
  getFeatureSettings: (
    featureKey: FeatureKeys,
  ) => { [key: string]: any } | Record<string, unknown>;
}

const initialState: State = {
  paymentProvider: null,
  card: null,
  usBankAccount: null,
  recurringApplicationCharge: null,
  selectedCycle: null,
  invoices: [],
  unpaidInvoices: [],
  invoicingSettings: {
    frequency: null,
    paymentTermDays: 0,
  },
  canPayInvoice: false,
  invoicingStatus: {
    numberOfFailedInvoices: 0,
    isPastDue: false,
  },
  preferences: DEFAULT_PREFERENCES,
  freeTrial: null,
  summaryTotal: 0,
  isInitialDataLoaded: false,
  getPaymentMethod: async () => undefined,
  addCustomPlan: async () => undefined,
  removeCustomPlan: async () => undefined,
  enableUsageBilling: async () => undefined,
  getInvoices: async () => undefined,
  getUnpaidInvoices: async () => undefined,
  updateSelectedCycle: async () => undefined,
  generateInvoice: async () => undefined,
  payInvoice: async () => undefined,
  updateInvoiceStatus: async () => undefined,
  addLedgerRecord: async () => undefined,
  getInvoicingSettings: async () => undefined,
  setInvoicingSettings: async () => undefined,
  generateRac: async () => undefined,
  updateInvoicingStatus: async () => undefined,
  updatePreferences: async () => undefined,
  hasPackageFeature: () => false,
  updateSummaryTotal: () => undefined,
  showPaymentMethodsModal: () => undefined,
  getFreeTrial: async () => undefined,
  createFreeTrial: async () => undefined,
  updateFreeTrial: async () => undefined,
  moveToStripe: async () => undefined,
  changeToNoChargeCap: async () => undefined,
  getFeatureSettings: () => ({}),
};

const reducerFn = (draft: State, action: ReducerAction) => {
  switch (action.type) {
    case UPDATE_PAYMENT_PROVIDER:
      draft.paymentProvider = action.paymentProvider;
      break;
    case UPDATE_RECURRING_APPLICATION_CHARGE:
      draft.recurringApplicationCharge = action.recurringApplicationCharge;
      break;
    case UPDATE_CARD:
      draft.card = action.card;
      break;
    case UPDATE_PAYMENT_METHOD:
      draft.card = action.card;
      draft.usBankAccount = action.usBankAccount;
      break;
    case UPDATE_INVOICES:
      draft.invoices = action.invoices;
      break;
    case UPDATE_INVOICE_SETTINGS:
      draft.invoicingSettings = action.invoicingSettings;
      break;
    case UPDATE_CAN_PAY_INVOICE:
      draft.canPayInvoice = action.canPayInvoice;
      break;
    case UPDATE_INVOICING_STATUS:
      draft.invoicingStatus = action.invoicingStatus;
      break;
    case UPDATE_PREFERENCES:
      draft.preferences = action.preferences;
      break;
    case UPDATE_FAILED_INVOICES:
      draft.unpaidInvoices = action.unpaidInvoices;
      break;
    case UPDATE_SELECTED_CYCLE:
      draft.selectedCycle = action.cycle;
      break;
    case UPDATE_FREE_TRIAL:
      draft.freeTrial = action.freeTrial;
      break;
    case UPDATE_SUMMARY_TOTAL:
      draft.summaryTotal = action.summaryTotal;
      break;
    case UPDATE_IS_INITIAL_DATA_LOADED:
      draft.isInitialDataLoaded = action.isInitialDataLoaded;
      break;
    default:
      throw new Error('Unsupported action type dispatched.');
  }
};

export const UsageBillingContext = createContext(initialState);
export const useUsageBilling = (): State => useContext(UsageBillingContext);

interface Props {
  children: JSX.Element;
}

export const UsageBillingProvider = ({ children }: Props): JSX.Element => {
  const reducer = produce(reducerFn);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    user: { username, is_admin: userIsAdmin },
  }: any = useUsers();
  const { hasFlag, flags }: any = useFeatureFlags();
  const { showModal, hideModal } = useGlobalModal();
  const { data: currentPlan } = useGetCurrentPlan();

  const getPaymentMethod = async (): Promise<PaymentMethod | undefined> => {
    try {
      const {
        card,
        usBankAccount,
        paymentProvider,
        recurringApplicationCharge,
      }: PaymentMethod = await api.get('/v2/billing/payments/payment_method');

      dispatch({
        type: UPDATE_PAYMENT_METHOD,
        card,
        usBankAccount,
      });

      dispatch({
        type: UPDATE_PAYMENT_PROVIDER,
        paymentProvider,
      });

      dispatch({
        type: UPDATE_RECURRING_APPLICATION_CHARGE,
        recurringApplicationCharge,
      });

      return {
        card,
        usBankAccount,
        paymentProvider,
        recurringApplicationCharge,
      };
    } catch (error) {
      toast.error(error as string);
    }
  };

  const changeToNoChargeCap = async (shopId: number) => {
    try {
      await api.post(`/v2/billing/admin/usage/mark_noop`, { shopId });

      toast.success('Shop scheduled to no longer use charge cap.');
    } catch (error) {
      toast.error(
        'Unable to schedule for no charge cap because this shop already has been invoiced.',
      );
    }
  };

  const moveToStripe = async () => {
    await api.put('/v2/billing/payments/stripe');
    await getPaymentMethod();
  };

  const addCustomPlan = async (plan: CustomPlanInput) => {
    try {
      const {
        formattedSaasFee,
        formattedChargeCap,
        formattedMinimumCommittedSpend,
        messageRates,
      } = plan;

      const usSmsRate = messageRates.find(
        (rate) => rate.countryCode === 'US' && rate.messageType === 'SMS',
      );

      await api.post('/v2/billing/admin/plans/custom', {
        ...plan,
        saasFeeCents: getCents(formattedSaasFee),
        messageMultiplier: usSmsRate?.messageRate,
        chargeCapCents: getCents(formattedChargeCap),
        minimumCommittedSpendCents: getCents(formattedMinimumCommittedSpend),
      });

      toast.success('Custom plan added.');
    } catch (error) {
      toast.error(error as string);
    }
  };

  const removeCustomPlan = async (planId: number) => {
    try {
      await api.delete(`/v2/billing/admin/plans/custom/${planId}`);

      toast.success('Custom plan removed.');
    } catch (error) {
      toast.error(error as string);
    }
  };

  const updateSelectedCycle = async (
    cycle: Cycle,
  ): Promise<Cycle | undefined> => {
    try {
      dispatch({
        type: UPDATE_SELECTED_CYCLE,
        cycle,
      });

      return cycle;
    } catch (error) {
      toast.error(error as string);
    }
  };

  const updateInvoicingStatus = async () => {
    try {
      const { numberOfFailedInvoices, isPastDue } = await api.get(
        '/v2/billing/invoices/status',
      );

      dispatch({
        type: UPDATE_INVOICING_STATUS,
        invoicingStatus: {
          numberOfFailedInvoices,
          isPastDue,
        },
      });
    } catch (error) {
      toast.error(error as string);
    }
  };

  const getInvoices = async (
    search?: SearchParams,
  ): Promise<InvoicesResponse | undefined> => {
    try {
      const queryParams = qs.stringify(search);

      const response: InvoicesResponse = await api.get(
        `/v2/billing/invoices/shop_invoices?${queryParams}`,
      );

      dispatch({
        type: UPDATE_INVOICES,
        invoices: response.shopInvoices,
      });

      return response;
    } catch (error) {
      toast.error(error as string);
    }
  };

  const getUnpaidInvoices = async (): Promise<InvoicesResponse | undefined> => {
    try {
      const queryParams = qs.stringify({
        status__in: `${INVOICE_STATUSES.PENDING},${INVOICE_STATUSES.PROCESSING},${INVOICE_STATUSES.FAILED}`,
      });

      const response: InvoicesResponse = await api.get(
        `/v2/billing/invoices/shop_invoices?${queryParams}`,
      );

      const { shopInvoices } = response;

      dispatch({
        type: UPDATE_FAILED_INVOICES,
        unpaidInvoices: shopInvoices,
      });

      return response;
    } catch (error) {
      toast.error(error as string);
    }
  };

  const generateInvoice = async () => {
    try {
      const invoice: Invoice = await api.post(
        '/v2/billing/invoices/shop_invoices',
      );

      dispatch({
        type: UPDATE_INVOICES,
        invoices: [invoice, ...state.invoices],
      });

      toast.success('Invoice generated.');
    } catch (error) {
      toast.error(error as string);
    }
  };

  const payInvoice = async (invoiceId: number) => {
    try {
      const invoice: Invoice = await api.post(
        `/v2/billing/invoices/shop_invoices/${invoiceId}/charge`,
      );

      await getUnpaidInvoices();

      if (invoice.status === 'FAILED') {
        toast.error('Invoice payment failed.');
        return;
      }

      toast.success('Invoice paid.');

      updateInvoicingStatus();
    } catch (error) {
      toast.error(error as string);
    }
  };

  const updateInvoiceStatus = async (
    invoiceId: number,
    reason: string,
    status: InvoiceStatuses,
  ) => {
    await api.post(`/v2/billing/invoices/shop_invoices/${invoiceId}/status`, {
      reason,
      status,
    });

    await getUnpaidInvoices();
    toast.success('Invoice status updated.');
    updateInvoicingStatus();
  };

  const addLedgerRecord = async (record: LedgerRecordInput) => {
    try {
      await api.post('/v2/billing/core/ledger_records', {
        ...record,
        createdBy: username,
        amount: getCents(record.formattedAmount),
      });

      toast.success('Ledger record added.');
    } catch (error) {
      toast.error(error as string);
    }
  };

  const getInvoicingSettings = async (): Promise<
    InvoicingSettings | undefined
  > => {
    try {
      const invoicingSettings: InvoicingSettings = await api.get(
        '/v2/billing/invoices/settings',
      );

      dispatch({
        type: UPDATE_INVOICE_SETTINGS,
        invoicingSettings,
      });

      return invoicingSettings;
    } catch (error) {
      toast.error(error as string);
    }
  };

  const setInvoicingSettings = async (invoicingSettings: InvoicingSettings) => {
    try {
      const { frequency, paymentTermDays } = invoicingSettings;

      const settings: InvoicingSettings = await api.put(
        '/v2/billing/invoices/settings',
        {
          frequency,
          paymentTermDays,
          dayOfMonth: null,
        },
      );

      dispatch({
        type: UPDATE_INVOICE_SETTINGS,
        invoicingSettings: settings,
      });
    } catch (error) {
      toast.error(error as string);
    }
  };

  const showPaymentMethodsModal = () => {
    showModal({
      dismissOnBackdropClick: false,
      children: <PaymentMethodsModal close={hideModal} />,
    });
  };

  const generateRac = async (
    returnUrl?: string,
  ): Promise<RecurringApplicationCharge | undefined> => {
    const recurringApplicationCharge: RecurringApplicationCharge =
      await api.post(
        '/v2/billing/payments/shopify/recurring_application_charge',
        {
          returnUrl:
            window.location.origin + (returnUrl || RAC_APPROVED_SUCCESS_PATH),
        },
      );

    dispatch({
      type: UPDATE_RECURRING_APPLICATION_CHARGE,
      recurringApplicationCharge,
    });

    return recurringApplicationCharge;
  };

  const enableUsageBilling = async (planType: ValueOf<PlanTypes>) => {
    await api.post(`/v2/billing/core/init/${planType}`);
  };

  const updatePreferences = (preferences: Preferences): void => {
    dispatch({
      type: UPDATE_PREFERENCES,
      preferences,
    });
  };

  const hasPackageFeature = ({
    featureKey,
    legacyBillingShopHasFeature = true,
    internalUserHasFeature = true,
  }: HasPackageFeatureArgs): boolean => {
    // Shop is not on Usage Billing and is not subject to feature gating
    if (!hasFlag(USAGE_BILLING_ENABLED)) return legacyBillingShopHasFeature;

    // Shop has no current plan or it has not yet been loaded; re-render after plan state change will cause this function to be called again
    if (!currentPlan) return false;

    // User is internal/admin and is not subject to feature gating
    if (userIsAdmin && internalUserHasFeature) return true;

    // Shop is pre-packages and is not subject to feature gating
    if (!currentPlan.package) return true;

    const {
      package: { features },
    } = currentPlan;

    return !!features[featureKey as FeatureKeys];
  };

  const getFeatureSettings = (
    featureKey: FeatureKeys,
  ): Record<string, unknown> => {
    return currentPlan?.package?.featureSettings?.[featureKey]?.settings || {};
  };

  const updateSummaryTotal = (summaryTotal: number) => {
    dispatch({
      type: UPDATE_SUMMARY_TOTAL,
      summaryTotal,
    });
  };

  const getFreeTrial = async (): Promise<FreeTrial | undefined> => {
    try {
      const { freeTrialPeriods } = await api.get(
        '/v2/billing/plans/cycle/current/trials',
      );

      const freeTrial = freeTrialPeriods[0];

      dispatch({
        type: UPDATE_FREE_TRIAL,
        freeTrial: freeTrial || null,
      });

      return freeTrial;
    } catch (error) {
      toast.error(error as string);
    }
  };

  const createFreeTrial = async (freeTrial: FreeTrialInput): Promise<void> => {
    const newFreeTrial = await api.post(
      '/v2/billing/admin/plans/trial',
      freeTrial,
    );

    dispatch({
      type: UPDATE_FREE_TRIAL,
      freeTrial: newFreeTrial,
    });
  };

  const updateFreeTrial = async (
    id: number,
    expiresAt: string,
  ): Promise<void> => {
    const updatedFreeTrial = await api.patch(
      `/v2/billing/admin/plans/trial/${id}`,
      { expiresAt },
    );

    dispatch({
      type: UPDATE_FREE_TRIAL,
      freeTrial: updatedFreeTrial,
    });
  };

  useEffect(() => {
    if (!hasFlag(USAGE_BILLING_ENABLED)) return;

    (async () => {
      await Promise.all([
        getPaymentMethod(),
        getFreeTrial(),
        getInvoicingSettings(),
      ]);

      dispatch({
        type: UPDATE_IS_INITIAL_DATA_LOADED,
        isInitialDataLoaded: true,
      });

      updateInvoicingStatus();
    })();
  }, [flags]);

  useEffect(() => {
    if (!hasFlag(USAGE_BILLING_ENABLED)) return;

    const { paymentProvider, card, usBankAccount, recurringApplicationCharge } =
      state;

    const canPayInvoice = !!(
      (paymentProvider === PAYMENT_PROVIDERS.STRIPE &&
        (card || usBankAccount)) ||
      (paymentProvider === PAYMENT_PROVIDERS.SHOPIFY &&
        recurringApplicationCharge?.status === RAC_STATUSES.ACTIVE)
    );

    dispatch({
      type: UPDATE_CAN_PAY_INVOICE,
      canPayInvoice,
    });
  }, [
    state.paymentProvider,
    state.card,
    state.usBankAccount,
    state.recurringApplicationCharge,
  ]);

  return (
    <UsageBillingContext.Provider
      value={{
        ...state,
        getPaymentMethod,
        addCustomPlan,
        removeCustomPlan,
        enableUsageBilling,
        getInvoices,
        getUnpaidInvoices,
        generateInvoice,
        payInvoice,
        updateInvoiceStatus,
        addLedgerRecord,
        getInvoicingSettings,
        setInvoicingSettings,
        generateRac,
        updateInvoicingStatus,
        updatePreferences,
        hasPackageFeature,
        updateSummaryTotal,
        showPaymentMethodsModal,
        getFreeTrial,
        createFreeTrial,
        updateFreeTrial,
        moveToStripe,
        changeToNoChargeCap,
        getFeatureSettings,
        updateSelectedCycle,
      }}
    >
      {children}
    </UsageBillingContext.Provider>
  );
};

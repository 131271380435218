import {
  ActionTypesNames,
  ADD_TYPE,
  AND_JOIN_TYPE,
  MESSAGE_INITIAL_VALUE,
  StepTypesNames,
  TextToBuySidebarNames,
  WaitActionCategories,
} from 'components/flowBuilder/constants';
import {
  Action,
  AutomationTriggerStep,
  BinaryCondition,
  CampaignTriggerStep,
  Condition,
  ConditionGroup,
  EndAction,
  EventSplitBranch,
  SendMessageAction,
  SplitAction,
  SubscriberGroup,
  TextToBuyAction,
  TextToBuyProduct,
  TextToBuySidebarType,
  TriggerEventSplitAction,
  UpdateSubscriberAction,
  WaitAction,
  WaitForEventSplitAction,
} from 'components/flowBuilder/types';
import {
  DynamicAction,
  DynamicActionType,
} from 'components/flowBuilder/types/dynamicActions';
import { isDynamicAction } from 'components/flowBuilder/types/dynamicActions/typeGuards';
import {
  isAnySplitAction,
  isSendMessageAction,
  isSendMessageActionParams,
  isSplitAction,
  isSplitActionParams,
  isStepParams,
  isTextToBuyAction,
  isTextToBuyActionParams,
  isTriggerEventSplitAction,
  isTriggerEventSplitActionParams,
  isUpdateSubscriberAction,
  isUpdateSubscriberActionParams,
  isWaitAction,
  isWaitActionParams,
  isWaitForEventSplitAction,
  isWaitForEventSplitActionParams,
} from 'components/flowBuilder/types/typeGuards';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const createAutomationTriggerStep = (): AutomationTriggerStep => ({
  guid: uuidv4(),
  type: StepTypesNames.AUTOMATION_TRIGGER,
  next: [],
  params: {
    triggerEvents: [],
    cancelEvents: [],
    userFilterCriteria: [],
    triggerFilters: [],
    transactional: false,
  },
});

export const createCampaignTriggerStep = (): CampaignTriggerStep => ({
  guid: uuidv4(),
  type: StepTypesNames.CAMPAIGN_TRIGGER,
  next: [],
  params: {
    safeSend: false,
    segmentIds: [],
    excludeSegmentIds: [],
    cancelEvents: [],
  },
});

export const createEmptyTextToBuyProduct = (): TextToBuyProduct => {
  return {
    shopify_product_id: null,
    shopify_product_name: null,
    shopify_variant_id: null,
    shopify_variant_name: null,
    price: null,
    min_price: null,
    max_price: null,
    quantity: 1,
    guid: uuidv4(),
  };
};

export const createTextToBuyAction = (): TextToBuyAction => ({
  guid: uuidv4(),
  type: StepTypesNames.TEXT_TO_BUY,
  next: [],
  params: {
    cases: [
      {
        action_guid: '',
        event: 'PayOrderConfirmed',
      },
      {
        action_guid: '',
        event: 'PayOrderCancelled',
      },
      {
        action_guid: '',
        event: 'PayOrderExpired',
      },
      {
        action_guid: '',
        event: 'FlowScheduledEvent',
      },
    ],
    content: MESSAGE_INITIAL_VALUE,
    mediaUrl: null,
    products: [createEmptyTextToBuyProduct()],
  },
});

const createWaitAction = (): WaitAction => ({
  guid: uuidv4(),
  type: ActionTypesNames.WAIT,
  params: {
    category: null,
    useSubscriberTz: false,
    clientTzOffset: '+00:00',
  },
  next: [],
});

export const createEmptyBinaryCondition = (): BinaryCondition => {
  return {
    operator: '',
    type: 'Binary',
    value: '',
    variable: '',
    allow_approximate_matches: false,
  };
};

export const createConditionGroup = (): ConditionGroup => {
  return {
    type: AND_JOIN_TYPE,
    value: [createEmptyBinaryCondition()],
  };
};

export const createSubscriberTag = (): SubscriberGroup => {
  return {
    type: ADD_TYPE,
    value: '',
  };
};

export const createEmptyBranchConditions = (): BinaryCondition[] => {
  return [createEmptyBinaryCondition()];
};

export const createEmptyWFESBranch = (): EventSplitBranch => {
  return {
    action_guid: '',
    conditions: createEmptyBranchConditions(),
  };
};

export const createSendMessageAction = (): SendMessageAction => ({
  guid: uuidv4(),
  type: ActionTypesNames.SEND_MESSAGE,
  params: {
    content: MESSAGE_INITIAL_VALUE,
    mediaUrl: null,
  },
  next: [],
});

export const createSplitCondition = (value: number): Condition => {
  return {
    variable: '_proportional_split',
    operator: '<',
    value,
  };
};
const createSplitAction = (): SplitAction => ({
  guid: uuidv4(),
  type: ActionTypesNames.SPLIT,
  params: {
    cases: [
      {
        // action_guid must be replaced with the first action that follows.
        action_guid: '',
        conditions: [createSplitCondition(0.5)],
      },
      {
        action_guid: '',
        conditions: [],
      },
    ],
  },
  next: [],
});

const createWaitForEventSplitAction = (): WaitForEventSplitAction => ({
  guid: uuidv4(),
  type: ActionTypesNames.WAIT_FOR_EVENT_SPLIT,
  params: {
    duration: 'PT24H',
    event_type: '',
    cases: [
      {
        action_guid: '',
        conditions: [
          {
            type: 'Binary',
            variable: '',
            operator: '',
            value: '',
          },
        ],
      },
      {
        action_guid: '',
        conditions: [],
      },
    ],
  },
  next: [],
});

const createTriggerEventSplitAction = (): TriggerEventSplitAction => ({
  guid: uuidv4(),
  type: ActionTypesNames.TRIGGER_EVENT_SPLIT,
  params: {
    cases: [
      {
        action_guid: '',
        conditions: [
          {
            type: 'Binary',
            variable: '',
            operator: '',
            value: '',
          },
        ],
      },
      {
        action_guid: '',
        conditions: [],
      },
    ],
  },
  next: [],
});

const createUpdateSubscriberAction = (): UpdateSubscriberAction => ({
  guid: uuidv4(),
  type: ActionTypesNames.UPDATE_SUBSCRIBER,
  params: {
    addTags: [],
    removeTags: [],
  },
  next: [],
});

export const createEndAction = (): EndAction => ({
  guid: uuidv4(),
  type: ActionTypesNames.END,
  params: {},
  next: [],
});

const createDynamicAction = (type: string): DynamicAction => ({
  guid: uuidv4(),
  type,
  params: {},
  next: [],
});

export interface CreateNewEdgeMeta {
  defaultWaitCategory?: WaitActionCategories;
}

export const createAction = (
  type: string,
  dynamicActionTypes: DynamicActionType[],
): Action | null => {
  switch (type) {
    case ActionTypesNames.WAIT:
      return createWaitAction();
    case ActionTypesNames.SEND_MESSAGE:
      return createSendMessageAction();
    case ActionTypesNames.SPLIT:
      return createSplitAction();
    case ActionTypesNames.WAIT_FOR_EVENT_SPLIT:
      return createWaitForEventSplitAction();
    case ActionTypesNames.TRIGGER_EVENT_SPLIT:
      return createTriggerEventSplitAction();
    case ActionTypesNames.UPDATE_SUBSCRIBER:
      return createUpdateSubscriberAction();
    case ActionTypesNames.TEXT_TO_BUY:
      return createTextToBuyAction();
    case ActionTypesNames.END:
      return createEndAction();
    default: {
      const dynamicActionTypesNames = dynamicActionTypes.map(
        ({ type }) => type,
      );
      if (dynamicActionTypesNames.includes(type))
        return createDynamicAction(type);
      return null;
    }
  }
};

export const createActionCopy = (action: Action): Action => {
  return {
    ...cloneDeep(action),
    guid: uuidv4(),
  };
};

export const findActionIndexByGuid = (
  guid: string,
  actions: Action[],
): number | null => {
  const index = actions.findIndex((draftAction) => draftAction.guid === guid);
  return index !== -1 ? index : null;
};

export const findActionByGuid = (
  guid: string,
  actions: Action[],
): Action | null => {
  const index = findActionIndexByGuid(guid, actions);
  return index !== null ? actions[index] : null;
};

export const findActionByNextGuid = (
  guid: string,
  actions: Action[],
): Action | null => {
  const index = actions.findIndex((action) => action.next.includes(guid));
  return index !== -1 ? actions[index] : null;
};

export const replaceNextGuidInAction = (
  oldGuid: string,
  newGuids: string[],
  action: Action,
): void => {
  const index = action.next.indexOf(oldGuid);
  if (index !== -1) action.next.splice(index, 1, ...newGuids);
};

export const updateActionParams = (action: Action, params: unknown): Action => {
  if (isWaitAction(action) && isWaitActionParams(params)) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (isSendMessageAction(action) && isSendMessageActionParams(params)) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (isSplitAction(action) && isSplitActionParams(params)) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (
    isUpdateSubscriberAction(action) &&
    isUpdateSubscriberActionParams(params)
  ) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (
    isWaitForEventSplitAction(action) &&
    isWaitForEventSplitActionParams(params)
  ) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (
    isTriggerEventSplitAction(action) &&
    isTriggerEventSplitActionParams(params)
  ) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (isTextToBuyAction(action) && isTextToBuyActionParams(params)) {
    return { ...action, params: { ...action.params, ...params } };
  }

  if (isDynamicAction(action) && isStepParams(params)) {
    return { ...action, params: { ...action.params, ...params } };
  }

  return action;
};

export const updateActionNext = (action: Action, next: string[]): Action => {
  const newAction = { ...action, next };

  return newAction;
};

export const textToBuyEventToSidebarType = (
  event: string,
): TextToBuySidebarType | null => {
  const map: Record<string, TextToBuySidebarType> = {
    PayOrderConfirmed: TextToBuySidebarNames.T2B_PREVIEW_PURCHASED,
    PayOrderCancelled: TextToBuySidebarNames.T2B_PREVIEW_CANCELLED,
    PayOrderExpired: TextToBuySidebarNames.T2B_PREVIEW_DIDNT_PURCHASE,
  };

  return map[event] || null;
};

export const findSourceActions = (
  guid: string,
  actions: Action[],
): Action[] => {
  return actions.filter((action) => action.next.includes(guid));
};

export const replaceActionGuidInActions = (
  oldGuid: string,
  newGuid: string,
  actions: Action[],
): Action[] => {
  const newActions = cloneDeep(actions);

  for (let i = 0; i < newActions.length; i += 1) {
    const action = newActions[i];

    if (action.guid === oldGuid) action.guid = newGuid;

    for (let j = 0; j < action.next.length; j += 1) {
      if (action.next[j] === oldGuid) action.next[j] = newGuid;
    }

    if (isAnySplitAction(action)) {
      for (let j = 0; j < action.params.cases.length; j += 1) {
        const branch = action.params.cases[j];
        if (branch.action_guid === oldGuid) branch.action_guid = newGuid;
      }
    }
  }

  return newActions;
};

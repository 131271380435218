/* eslint-disable max-classes-per-file */
import { hasShopSalesAgentWorkspaceEnabled } from 'components/account/AccountView/users/helpers';
import React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { lazyLoadWithChunkRefresh } from 'utils/lazyLoadWithChunkRefresh';

const PromptTable = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('components/responses/PromptTable'),
    'PromptTable',
  ),
);
const PromptCreate = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('components/responses/PromptCreate'),
    'PromptCreate',
  ),
);
const PromptEdit = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('components/responses/PromptEdit'),
    'PromptEdit',
  ),
);

const MyProspectsInbox = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./pages/MyProspectsInbox'),
    'MyProspectsInbox',
  ),
);

const AllProspectsInbox = React.lazy(() =>
  lazyLoadWithChunkRefresh(
    () => import('./pages/AllProspectsInbox'),
    'AllProspectsInbox',
  ),
);

export const SALES_ROUTE_PREFIX = '/workspace';

const Routes = ({ match }: RouteComponentProps): JSX.Element => {
  if (!hasShopSalesAgentWorkspaceEnabled()) {
    return <Redirect to="/" />;
  }
  return (
    <Switch>
      <Route path={`${match.url}/ai/prompts/create`} component={PromptCreate} />
      <Route path={`${match.url}/ai/prompts/:id`} component={PromptEdit} />
      <Route path={`${match.url}/ai/prompts`} component={PromptTable} />
      <Route
        path={`${match.url}/inbox/:id?`}
        exact
        component={MyProspectsInbox}
      />
      <Route
        path={`${match.url}/all-prospects/:id?`}
        exact
        component={AllProspectsInbox}
      />
      <Redirect to={`${match.url}/inbox`} />
    </Switch>
  );
};

export default withRouter(Routes);

import { StepTypesNames } from 'components/flowBuilder/constants';
import { DynamicFieldsTypesNames } from 'components/flowBuilder/constants/dynamicActions';
import type {
  BaseDynamicField,
  CheckboxDynamicField,
  DynamicAction,
  DynamicActionType,
  DynamicField,
  DynamicFieldsTypesName,
  FlowSelectionDynamicField,
} from 'components/flowBuilder/types/dynamicActions';
import { isBaseStep } from 'components/flowBuilder/types/typeGuards';

const isDynamicFieldsTypesName = (
  name: unknown,
): name is DynamicFieldsTypesName => {
  const nameValue = name as DynamicFieldsTypesName;
  return Object.values(DynamicFieldsTypesNames).includes(nameValue);
};

const isBaseDynamicField = (field: unknown): field is BaseDynamicField => {
  const fieldValue = field as BaseDynamicField;

  if (!fieldValue || typeof fieldValue !== 'object') return false;
  if (!fieldValue.options || typeof fieldValue.options !== 'object')
    return false;
  if (!isDynamicFieldsTypesName(fieldValue.type)) return false;

  return true;
};

export const isCheckboxDynamicField = (
  field: unknown,
): field is CheckboxDynamicField => {
  const fieldValue = field as CheckboxDynamicField;

  if (!isBaseDynamicField(fieldValue)) return false;
  if (fieldValue.type !== DynamicFieldsTypesNames.CHECKBOX) return false;
  if (
    !fieldValue.options.checkboxParamName ||
    typeof fieldValue.options.checkboxParamName !== 'string'
  )
    return false;
  if (
    fieldValue.options.iconName !== null &&
    typeof fieldValue.options.iconName !== 'string'
  )
    return false;
  if (!fieldValue.options.label || typeof fieldValue.options.label !== 'string')
    return false;
  if (
    !fieldValue.options.shortLabel ||
    typeof fieldValue.options.shortLabel !== 'string'
  )
    return false;

  return true;
};

export const isFlowSelectionDynamicField = (
  field: unknown,
): field is FlowSelectionDynamicField => {
  const fieldValue = field as FlowSelectionDynamicField;

  if (!isBaseDynamicField(fieldValue)) return false;
  if (fieldValue.type !== DynamicFieldsTypesNames.FLOW_SELECTION) return false;
  if (
    !fieldValue.options.flowIdParamName ||
    typeof fieldValue.options.flowIdParamName !== 'string'
  )
    return false;
  if (!fieldValue.options.label || typeof fieldValue.options.label !== 'string')
    return false;

  return true;
};

const isDynamicField = (field: unknown): field is DynamicField => {
  return isCheckboxDynamicField(field) || isFlowSelectionDynamicField(field);
};

export const isDynamicActionType = (
  actionType: unknown,
): actionType is DynamicActionType => {
  const actionTypeValue = actionType as DynamicActionType;

  if (!actionTypeValue || typeof actionTypeValue !== 'object') return false;
  if (
    actionTypeValue.badge !== null &&
    typeof actionTypeValue.badge !== 'string'
  )
    return false;
  if (!actionTypeValue.category || typeof actionTypeValue.category !== 'string')
    return false;
  if (
    !actionTypeValue.description ||
    typeof actionTypeValue.description !== 'string'
  )
    return false;
  if (
    actionTypeValue.featureFlag !== null &&
    typeof actionTypeValue.featureFlag !== 'string'
  )
    return false;
  if (actionTypeValue.fields.some((field) => !isDynamicField(field)))
    return false;
  if (!actionTypeValue.helpText || typeof actionTypeValue.helpText !== 'string')
    return false;
  if (!actionTypeValue.iconName || typeof actionTypeValue.iconName !== 'string')
    return false;
  if (!actionTypeValue.label || typeof actionTypeValue.label !== 'string')
    return false;
  if (!actionTypeValue.type || typeof actionTypeValue.type !== 'string')
    return false;

  return true;
};

export const isDynamicAction = (action: unknown): action is DynamicAction => {
  const actionValue = action as DynamicAction;

  if (!isBaseStep(actionValue)) return false;
  if (Object.values<string>(StepTypesNames).includes(actionValue.type))
    return false;
  if (!actionValue.params || typeof actionValue.params !== 'object')
    return false;

  return true;
};

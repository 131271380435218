/* eslint-disable no-restricted-syntax */
import { useState } from 'react';
import { Requests } from '../controllers/network';
import invalidCharacters from '../json/invalid-characters.json';

const useCharsets = () => {
  const [charsetData, setCharsetData] = useState({});
  const [fetching, setFetching] = useState(true);

  const splitString = (text = '') => {
    // json stringify to replace new lines properly
    const txt = text.replace(/(\r\n|\n|\r)/gm, '\\n');
    return txt.split('');
  };

  const filterTextWithRichTextCharacters = (text = '') => {
    const formattedText = invalidCharacters.reduce(
      (acc, { invalid, valid }) => {
        return acc?.replace(new RegExp(invalid, 'gm'), valid);
      },
      text,
    );

    return formattedText;
  };

  // filter out text for specific charsets
  const assessTextWithCharsets = (text, withCharsets) => {
    const formattedText = filterTextWithRichTextCharacters(text);
    const sanitizedWithCharsets = Array.sanitize(withCharsets, true);

    if (!sanitizedWithCharsets || !sanitizedWithCharsets.length) return true;
    const charset = Object.keys(charsetData).reduce((acc, cur) => {
      if (sanitizedWithCharsets.includes(cur))
        return acc + Object.keys(charsetData[cur]);
      return acc;
    }, []);

    return !splitString(formattedText).some((char) => !charset.includes(char));
  };

  // get the illegal chars from the characterset
  const illegalCharsFromTextWithCharsets = (text, withCharsets) => {
    const sanitizedWithCharsets = Array.sanitize(withCharsets, true);

    if (!sanitizedWithCharsets || !sanitizedWithCharsets.length) return text;
    const charset = Object.keys(charsetData).reduce((acc, cur) => {
      if (sanitizedWithCharsets.includes(cur))
        return acc + Object.keys(charsetData[cur]);
      return acc;
    }, []);
    return splitString(text).filter((char) => !charset.includes(char));
  };

  const getUnicodeCharacterCount = (text) => {
    return text.length;
  };

  // filter out text for specific charsets
  const filterTextWithCharsets = (text, withCharsets) => {
    const sanitizedWithCharsets = Array.sanitize(withCharsets, true);
    if (!sanitizedWithCharsets || !sanitizedWithCharsets.length)
      return getUnicodeCharacterCount(text);
    const charset = Object.keys(charsetData).reduce((acc, cur) => {
      if (sanitizedWithCharsets.includes(cur))
        return acc + Object.keys(charsetData[cur]);
      return acc;
    }, []);
    return splitString(text)
      .map((char) => (charset.includes(char) ? char : ''))
      .join('')
      .replace(/\\n/gm, '\n');
  };

  // get the character count and single message link
  // this is characterset specific
  const getCharacterCountWithCharsets = (text) => {
    const withCharsets = ['gsm7', 'gsm7ext'];

    const formatNewlineCharacters = text.replace(/\n/g, ',');
    const formattedText = filterTextWithRichTextCharacters(
      formatNewlineCharacters,
    );

    // unicode
    if (!assessTextWithCharsets(formattedText, withCharsets))
      return formattedText.length;
    const charset = Object.keys(charsetData).reduce((acc, cur) => {
      if (withCharsets.includes(cur)) {
        const result = { ...acc };
        // eslint-disable-next-line guard-for-in
        for (const char in charsetData[cur]) {
          const charData = charsetData[cur][char];
          if ('decimal' in charData && Array.isArray(charData.decimal)) {
            result[char] = charData.decimal.length;
          } else {
            result[char] = 1;
          }
        }
        return result;
      }
      return acc;
    }, {});

    return splitString(formattedText).reduce((acc, cur) => {
      if (charset[cur]) return acc + charset[cur];
      return acc;
    }, 0);
  };

  // the request function for loading the charset objects
  const getCharsets = () => {
    return Requests.get('/charsets')
      .then((res) => {
        if (res && res.success && res.charsets) setCharsetData(res.charsets);
        setFetching(false);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        setFetching(false);
      });
  };

  // load all the charsets
  if (!charsetData || !Object.keys(charsetData).length) getCharsets();

  return {
    fetching,
    charsets: charsetData,
    assessText: assessTextWithCharsets,
    illegalCharsFromText: illegalCharsFromTextWithCharsets,
    filterText: filterTextWithCharsets,
    getCharacterCount: getCharacterCountWithCharsets,
    filterTextWithRichTextCharacters,
  };
};

export default useCharsets;

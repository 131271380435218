import { BodyText, ButtonLink, Card, Heading } from '@postscript/components';
import {
  SELF_MIGRATE_TO_USAGE_BILLING,
  USAGE_BILLING_ENABLED,
} from 'components/admin/utils/feature-flags';
import { useFeatureFlags } from 'controllers/contexts/featureFlags';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import styled from 'styled-components';
import { logLinkClickEvent, PRODUCT_AREAS } from 'utils/events';
import { ReactComponent as Sparkles } from './svg/sparkles.svg';

const StyledCard = styled(Card)`
  background: var(--purple-9);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-3);
  margin: var(--spacing-3) 0;
  box-shadow: none;
`;

const StyledHeading = styled(Heading)`
  display: flex;
  margin-bottom: var(--spacing-2);

  span {
    margin: auto 0 auto var(--spacing-2);
  }
`;

const StyledButton = styled(ButtonLink)`
  margin-top: var(--spacing-2);
`;

export default function UsageBillingAnnouncement(): JSX.Element | null {
  const { hasInitialized, hasLabsFlag } = usePSLabs();
  const { hasFlag }: any = useFeatureFlags();

  if (
    hasFlag(USAGE_BILLING_ENABLED) ||
    !hasInitialized ||
    !hasLabsFlag(SELF_MIGRATE_TO_USAGE_BILLING)
  ) {
    return null;
  }

  return (
    <StyledCard data-theme="dark">
      <StyledHeading size="xx-small">
        <Sparkles />
        <span>Simplify your spend</span>
      </StyledHeading>
      <BodyText size="small" color="var(--text-color)">
        Switch to usage billing today and say goodbye to pre-purchasing credits!
      </BodyText>
      <StyledButton
        to="/billing/switch-to-usage"
        onClick={(event) =>
          logLinkClickEvent(event, { product_area: PRODUCT_AREAS.GLOBAL })
        }
      >
        View Usage Billing Plans
      </StyledButton>
    </StyledCard>
  );
}

// Custom hook for getting Shopify metadata.
import { toast } from '@postscript/components';
import { useEffect, useState } from 'react';
import { Requests } from '../controllers/network';
import { rejectErrors } from '../controllers/utils';

const useShopifyMetadata = () => {
  // Store the shopify metadata in state.
  // Shopify's data is read only, so this is okay.
  const [meta, changeMeta] = useState(null);

  useEffect(() => {
    Requests.get(`/me/shopify_metadata`)
      .then(rejectErrors)
      .then(changeMeta)
      .catch(toast.error);
  }, []);

  return meta;
};

export default useShopifyMetadata;

import { IconSet } from '@postscript/components';
import {
  LegacyTagDelimiters,
  TagDelimiters,
} from 'components/flowBuilder/constants/tags';

export const BASE_FLOW_BUILDER_API_URL = '/v2/flowbuilder/';
export const BASE_FLOWS_URL = `${BASE_FLOW_BUILDER_API_URL}flows/`;

export const ActionWithParamsTypesNames = {
  SEND_MESSAGE: 'SendMessage',
  WAIT: 'Wait',
  SPLIT: 'Split',
  WAIT_FOR_EVENT_SPLIT: 'WaitForEventSplit',
  TRIGGER_EVENT_SPLIT: 'TriggerEventSplit',
  UPDATE_SUBSCRIBER: 'UpdateSubscriber',
  TEXT_TO_BUY: 'TextToBuy',
  END: 'End',
} as const;

export const ActionTypesNames = {
  ...ActionWithParamsTypesNames,
} as const;

export const StepWithParamsTypesNames = {
  ...ActionWithParamsTypesNames,
  AUTOMATION_TRIGGER: 'AutomationTrigger',
  CAMPAIGN_TRIGGER: 'CampaignTrigger',
  BRANCH_INFO: 'BranchInfo',
  RECONVENING_BRANCHES: 'ReconveningBranches',
  TEXT_TO_BUY_BRANCH_INFO: 'TextToBuyBranchInfo',
  END: 'End',
} as const;

export const StepTypesNames = {
  ...StepWithParamsTypesNames,
} as const;

export const ReactFlowDefaults = {
  X_POSITION: 400,
  ENABLED_X_POSITION: 36,
  Y_POSITION: 36,
} as const;

export const BASE_STEP_ELEMENT_WIDTH = 300;

export const StepElementsWidths = {
  END: 24,
  SEND_MESSAGE: BASE_STEP_ELEMENT_WIDTH,
  SPLIT: BASE_STEP_ELEMENT_WIDTH,
  WAIT_FOR_EVENT_SPLIT: 360,
  TRIGGER_EVENT_SPLIT: BASE_STEP_ELEMENT_WIDTH,
  AUTOMATION_TRIGGER: BASE_STEP_ELEMENT_WIDTH,
  CAMPAIGN_TRIGGER: BASE_STEP_ELEMENT_WIDTH,
  WAIT: BASE_STEP_ELEMENT_WIDTH,
  UPDATE_SUBSCRIBER: BASE_STEP_ELEMENT_WIDTH,
  TEXT_TO_BUY: BASE_STEP_ELEMENT_WIDTH,
  DYNAMIC: BASE_STEP_ELEMENT_WIDTH,
} as const;

export const EdgeTypesNames = {
  DROPPABLE: 'Droppable',
  STATIC: 'smoothstep',
} as const;

export const SubActionClassName = 'subaction';

export enum AnalyticsTypesNames {
  PSPAY_ORDERS = 'pspay_orders',
  PSPAY_ATTRIBUTED_REVENUE = 'pspay_attributed_revenue',
  CONVERSION_RATE = 'conversion_rate',
  CREDITS_USED = 'credits_used',
  CTR = 'ctr',
  EPM = 'epm',
  ORDERS = 'orders',
  REVENUE = 'revenue',
  TOTAL_CLICKS = 'total_clicks',
  TOTAL_SENT = 'total_sent',
  TOTAL_SUBSCRIBERS = 'total_subscribers',
  TOTAL_UNSUBS = 'total_unsubs',
  UNIQUE_CLICKS = 'unique_clicks',
  UNIQUE_SUBSCRIBERS = 'unique_subscribers',
  UNSUBSCRIBE_RATE = 'unsubscribe_rate',
  VALID_MESSAGE_STATISTICS = 'valid_message_statistics',
}

export const AnalyticsGroupsTypesNamesWithoutSubscribers = {
  TOTAL_SENT_AND_UNSUBSCRIBE_RATE: 'TOTAL_SENT_AND_UNSUBSCRIBE_RATE',
  TOTAL_CLICKS_AND_CTR: 'TOTAL_CLICKS_AND_CTR',
  ORDERS_AND_CONVERSION_RATE: 'ORDERS_AND_CONVERSION_RATE',
  REVENUE_AND_EPM: 'REVENUE_AND_EPM',
} as const;

export const AnalyticsGroupsTypesNames = {
  TOTAL_SUBSCRIBERS_AND_UNIQUE_SUBSCRIBERS:
    'TOTAL_SUBSCRIBERS_AND_UNIQUE_SUBSCRIBERS',
  ...AnalyticsGroupsTypesNamesWithoutSubscribers,
} as const;

const BaseFlowStatuses = {
  DRAFT: 'DRAFT',
  ENABLED: 'ENABLED',
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  APPROVAL_DENIED: 'APPROVAL_DENIED',
  SCHEDULED: 'SCHEDULED',
  ENDED: 'ENDED',
} as const;

export const AutomationFlowStatuses = {
  ...BaseFlowStatuses,
};

export const CampaignFlowStatuses = {
  ...BaseFlowStatuses,
  COMPLETED: 'COMPLETED',
} as const;

export const FlowStatuses = {
  ...AutomationFlowStatuses,
  ...CampaignFlowStatuses,
} as const;

export const FlowTypes = {
  CAMPAIGN: 'CAMPAIGN',
  AUTOMATION: 'AUTOMATION',
} as const;

export const FlowOrigins = {
  TEMPLATE: 'TEMPLATE',
  NEW: 'NEW',
} as const;

export const CampaignFlowStatusesMetadata = {
  [CampaignFlowStatuses.DRAFT]: {
    badgeVariant: 'neutral',
    label: 'Draft',
    value: CampaignFlowStatuses.DRAFT,
  },
  [CampaignFlowStatuses.ENABLED]: {
    badgeVariant: 'success',
    label: 'In progress',
    value: CampaignFlowStatuses.ENABLED,
  },
  [CampaignFlowStatuses.APPROVAL_PENDING]: {
    badgeVariant: 'info',
    label: 'Approval pending',
    value: CampaignFlowStatuses.APPROVAL_PENDING,
  },
  [CampaignFlowStatuses.APPROVAL_DENIED]: {
    badgeVariant: 'error',
    label: 'Approval denied',
    value: CampaignFlowStatuses.APPROVAL_DENIED,
  },
  [CampaignFlowStatuses.SCHEDULED]: {
    badgeVariant: 'info',
    label: 'Scheduled',
    value: CampaignFlowStatuses.SCHEDULED,
  },
  [CampaignFlowStatuses.COMPLETED]: {
    badgeVariant: 'success',
    label: 'Completed',
    value: CampaignFlowStatuses.COMPLETED,
  },
  [CampaignFlowStatuses.ENDED]: {
    badgeVariant: 'neutral',
    label: 'Ended',
    value: CampaignFlowStatuses.ENDED,
  },
} as const;

export const AutomationFlowStatusesMetadata = {
  [AutomationFlowStatuses.DRAFT]: {
    badgeVariant: 'neutral',
    label: 'Draft',
    value: AutomationFlowStatuses.DRAFT,
  },
  [AutomationFlowStatuses.ENABLED]: {
    badgeVariant: 'success',
    label: 'Activated',
    value: AutomationFlowStatuses.ENABLED,
  },
  [AutomationFlowStatuses.APPROVAL_PENDING]: {
    badgeVariant: 'info',
    label: 'Approval pending',
    value: AutomationFlowStatuses.APPROVAL_PENDING,
  },
  [AutomationFlowStatuses.APPROVAL_DENIED]: {
    badgeVariant: 'error',
    label: 'Approval denied',
    value: AutomationFlowStatuses.APPROVAL_DENIED,
  },
  [AutomationFlowStatuses.SCHEDULED]: {
    badgeVariant: 'info',
    label: 'Scheduled',
    value: AutomationFlowStatuses.SCHEDULED,
  },
  [AutomationFlowStatuses.ENDED]: {
    badgeVariant: 'neutral',
    label: 'Deactivated',
    value: AutomationFlowStatuses.ENDED,
  },
} as const;

export const EMPTY_FLOW_ANALYTICS = {
  [AnalyticsTypesNames.TOTAL_SENT]: 0,
  [AnalyticsTypesNames.CREDITS_USED]: 0,
  [AnalyticsTypesNames.TOTAL_UNSUBS]: 0,
  [AnalyticsTypesNames.TOTAL_SUBSCRIBERS]: 0,
  [AnalyticsTypesNames.TOTAL_CLICKS]: 0,
  [AnalyticsTypesNames.UNIQUE_CLICKS]: 0,
  [AnalyticsTypesNames.ORDERS]: 0,
  [AnalyticsTypesNames.REVENUE]: 0,
} as const;

export const EMPTY_FLOWS_ANALYTICS = {
  analytics: {},
  errors: {},
};

export const ANALYTICS_REFETCH_INTERVAL = 1000 * 30; // 30 seconds

export const operationsDict: any = {
  // SIMPLE VALUES
  eq: 'is',
  neq: 'is not',
  in: 'is',
  not_in: 'is not',
  contains: 'contains',
  not_contains: 'does not contain',
  lt: 'is less than',
  gt: 'is greater than',
  lte: 'is at most',
  gte: 'is at least',
  between: 'is within',
  not_between: 'is not within',
  boolean: 'boolean',
  // ANY
  any_eq: 'any are',
  any_in: 'any are',
  any_contains: 'any contain',
  any_not_contains: 'any do not contain',
  any_neq: 'any are not',
  any_not_in: 'any are not',
  any_lt: 'any are less than',
  any_gt: 'any are greater than',
  any_lte: 'any are at most',
  any_gte: 'any are at least',
  any_between: 'any are within',
  any_not_between: 'any not within',
  // ALL
  all_contains: 'all contain',
  all_not_contains: 'all do not contain',
  all_eq: 'all are',
  all_in: 'all are',
  all_neq: 'all are not',
  all_not_in: 'all are not',
  all_lt: 'all are less than',
  all_gt: 'all are greater than',
  all_lte: 'all are at most',
  all_gte: 'all are at least',
  all_between: 'all are within',
  all_not_between: 'all not within',
  // NONE
  none_eq: 'none are',
  none_in: 'none are',
  none_neq: 'none are not',
  none_not_in: 'none are not',
  none_gt: 'none are greater than',
  none_gte: 'none are at least',
  none_lt: 'none are less than',
  none_lte: 'none are at most',
  none_between: 'none are within',
  none_not_between: 'none not within',
  none_contains: 'none contain',
  none_not_contains: 'none do not contain',
  none_boolean: 'none boolean',
};

export const IN_OPERATOR_VARIANTS = [
  'in',
  'not_in',
  'any_in',
  'any_not_in',
  'all_in',
  'all_not_in',
  'none_in',
  'none_not_in',
];
export const EQ_OPERATOR_VARIANTS = [
  'eq',
  'neq',
  'any_eq',
  'any_neq',
  'all_eq',
  'all_neq',
  'none_eq',
  'none_neq',
];
export const CONTAINS_OPERATOR_VARIANTS = [
  'contains',
  'not_contains',
  'any_contains',
  'any_not_contains',
  'all_contains',
  'all_not_contains',
  'none_contains',
  'none_not_contains',
];

export const ADD_TYPE = 'Add';
export const REMOVE_TYPE = 'Remove';

export const AND_JOIN_TYPE = 'And';
export const OR_JOIN_TYPE = 'Or';

const PLACEHOLDER_FIRST_NAME = 'Elizabeth';
const PLACEHOLDER_LAST_NAME = 'Smith';

export const PLACEHOLDER_SUBSCRIBER_MESSAGE_TEMPLATE_CONTEXT = {
  first_name: PLACEHOLDER_FIRST_NAME,
  last_name: PLACEHOLDER_LAST_NAME,
} as const;

export const QueryCacheTimes = {
  SHORT: 1000 * 60 * 5, // 5 minutes
  MEDIUM: 1000 * 60 * 15, // 15 minutes
} as const;

// These are just used by legacy stuff
export const MessageMaxChars = {
  SMS: 160,
  SMS_MULTIPART: 153,
  SMS_WITH_EMOJI: 70,
  SMS_WITH_EMOJI_MULTIPART: 67,
  MMS: 1600,
} as const;

export const MESSAGE_SHOP_NAME_TAG_KEY_PREFIX = `${TagDelimiters.START}shop.name${TagDelimiters.END}: `;
export const MESSAGE_LEGACY_SHOP_NAME_TAG_KEY_PREFIX = `${LegacyTagDelimiters.START}shop_name${LegacyTagDelimiters.END}: `;
export const MESSAGE_INITIAL_VALUE = `${MESSAGE_SHOP_NAME_TAG_KEY_PREFIX}Hi! This is a sample message.`;

export const FlowTemplateTypes = {
  AUTOMATION: 'flow-automation',
  CAMPAIGN: 'flow-campaign',
} as const;

// For compliance reasons, we restrict non-determinate wait categories for some events
export const WaitTimeDeltaOnlyEventTypes = [
  'ShopifyCheckoutStarted',
  'SubscriberViewedProduct',
  'ProductAddedToCart',
  'RechargeCheckoutStarted',
];

export enum WaitActionCategories {
  TIMEDELTA = 'timedelta',
  SPECIFIC_DATETIME = 'specific_datetime',
  RELATIVE_WEEK = 'relative_week',
}

export const WAIT_ACTION_DESCRIPTION =
  'Temporarily delays the next action for subscribers.';

export const TRANSACTIONAL_MESSAGE_ELIGIBLE_EVENTS = [
  'ShopifyOrderCreated',
  'ShopifyOrderDelivered',
  'ShopifyOrderFulfilled',
  'ShopifyOrderInTransit',
  'ShopifyFulfillmentCreated',
  'ShopifyAttemptedDelivery',
  'ShopifyOrderOutForDelivery',
  'ShopifyOrderReadyForPickup',
  'AftershipDelivered',
  'AftershipFailedAttempt',
  'AftershipInTransit',
  'AftershipOutForDelivery',
  'RechargeChargeCreated',
  'RechargeChargePaid',
  'RechargeChargeUpcoming',
  'RechargeCustomerActivated',
  'RechargeCustomerDeactivated',
  'RechargeOrderCreated',
];

export const FLOW_SESSION_STORAGE_KEY = 'flow';

export const TextToBuySidebarNames = {
  T2B_PREVIEW_PURCHASED: 't2b-preview-purchased',
  T2B_PREVIEW_CANCELLED: 't2b-preview-cancelled',
  T2B_PREVIEW_DIDNT_PURCHASE: 't2b-preview-didnt-purchase',
  T2B_PREVIEW_TIMED_OUT: 't2b-preview-timed-out',
} as const;

export const SidebarNames = {
  ...TextToBuySidebarNames,
  ACTIVATE: 'activate',
} as const;

export const DEFAULT_ELSE_BRANCH_LABEL =
  'None of the above, or the event never occurred';

export const DynamicIconSet = IconSet as unknown as Record<
  string,
  () => JSX.Element
>;

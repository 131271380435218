import {
  Button,
  Input,
  Layout,
  ModalBody,
  ModalFooter,
  toast,
} from '@postscript/components';
import { useStripe } from '@stripe/react-stripe-js';
import { usePaymentMethods } from 'components/billing/context/paymentMethods';
import { useUsers } from 'controllers/contexts/user';
import { api } from 'controllers/network/apiClient';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import styled from 'styled-components';
import { logEvent } from 'utils/events';
import * as yup from 'yup';

const StyledLayout = styled(Layout).attrs({ vertical: true })`
  * {
    width: 100%;
  }
`;

export default function PaymentMethodACHAccountholderForm(): JSX.Element {
  const stripe = useStripe();
  const { updateStep } = usePaymentMethods();
  const [isLoading, setIsLoading] = useState(false);
  const {
    user: {
      data: { first_name: firstName, last_name: lastName },
      postscript_contact_email: userEmail,
    },
  }: any = useUsers();
  const userName = `${firstName} ${lastName}`;

  const initStripeCollectionProcess = async ({
    name,
    email,
  }: {
    name: string;
    email: string;
  }) => {
    if (!stripe) return;

    setIsLoading(true);

    const { clientSecret } = await api.post(
      '/v2/billing/payments/stripe/setup_intents',
    );

    const { setupIntent, error } = await (
      stripe.collectBankAccountForSetup as any
    )(
      // TS declaration included with Stripe library is borked; casting to any for now
      {
        clientSecret,
        params: {
          payment_method_type: 'us_bank_account',
          payment_method_data: {
            billing_details: {
              name,
              email,
            },
          },
        },
        expand: ['payment_method'],
      },
    );

    if (error || !setupIntent) {
      setIsLoading(false);
      return toast.error(error || 'Please try again.');
    }

    const { status } = setupIntent;

    if (status === 'requires_payment_method') {
      return updateStep('typeChoice');
    }

    const {
      payment_method: {
        us_bank_account: {
          bank_name: bankName,
          last4,
          routing_number: routingNumber,
        },
      },
    } = setupIntent;

    return updateStep('stripeAchMandate', {
      achDetails: {
        clientSecret,
        name,
        email,
        bankName,
        last4,
        routingNumber,
      },
    });
  };

  return (
    <Formik
      validationSchema={yup.object({
        name: yup.string().required('Please provide an accountholder name.'),
        email: yup
          .string()
          .required('Please provide an accountholder email address.'),
      })}
      initialValues={{
        name: userName,
        email: userEmail,
      }}
      onSubmit={initStripeCollectionProcess}
      validateOnChange={false}
    >
      {({ errors, touched, handleChange }) => {
        const handleAndLogChange = (event: any) => {
          handleChange(event);
          logEvent('accountholder details changed');
        };

        return (
          <Form>
            <ModalBody>
              <StyledLayout>
                <Field
                  id="accountholder-name"
                  name="name"
                  label="Accountholder Name"
                  as={Input}
                  error={touched.name && errors.name}
                  errorMessage={errors.name}
                  onChange={handleAndLogChange}
                  disabled={isLoading}
                  data-cy="accountholder-name-input"
                />
                <Field
                  id="accountholder-email"
                  name="email"
                  type="email"
                  label="Accountholder Email Address"
                  message="May be used for account verification and legally-mandated communication"
                  as={Input}
                  error={touched.email && errors.email}
                  errorMessage={errors.email}
                  onChange={handleAndLogChange}
                  disabled={isLoading}
                  data-cy="accountholder-email-input"
                />
              </StyledLayout>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" disabled={isLoading}>
                Continue
              </Button>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
}

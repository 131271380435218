export const PERMISSIONS = {
  billing_read: 'billing_read',
  user_read: 'user_read',
  user_write: 'user_write',
  user_delete: 'user_delete',
  limit_sms_sales_agent_access: 'limit_sms_sales_agent_access',
} as const;

export const LEGACY_ROLES = {
  SUPERUSER: 'SUPERUSER',
  ROLE_MANAGER: 'ROLE_MANAGER',
  CONTENT_REVIEWER: 'CONTENT_REVIEWER',
  QA_TESTER: 'QA_TESTER',
  GTM_NOTIFICATION_RECIPIENT: 'GTM_NOTIFICATION_RECIPIENT',
  CONVERT_SALES_AGENT: 'CONVERT_SALES_AGENT',
  AI_ALPHA_TESTER: 'AI_ALPHA_TESTER',
  SHOP_SALES_AGENT_WORKSPACE_TESTER: 'SHOP_SALES_AGENT_WORKSPACE_TESTER',
} as const;

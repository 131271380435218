import { BodyText, Layout } from '@postscript/components';
import { StripeSetupIntentStatuses } from 'components/billing/common/types';
import { BankAccount, Card } from 'components/generic/types';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as GenericBankAccount } from '../../../../img/billing-bank-account-generic.svg';
import { ReactComponent as GenericCard } from '../../../../img/billing-credit-card-generic.svg';
import { ReactComponent as AmericanExpressLogo } from '../../../../img/card-brands/billing-american-express.svg';
import { ReactComponent as DiscoverLogo } from '../../../../img/card-brands/billing-discover.svg';
import { ReactComponent as MastercardLogo } from '../../../../img/card-brands/billing-mastercard.svg';
import { ReactComponent as VisaLogo } from '../../../../img/card-brands/billing-visa.svg';

const MethodDetails = styled.div`
  margin: auto 0;
  color: var(--text-color);
`;

const MetaText = styled(BodyText).attrs({
  size: 'small',
})`
  margin: 0;
`;

const StyledLogo = styled.div`
  max-width: 68px;
  display: flex;

  svg {
    width: 100%;
    height: auto;
  }
`;

const brands = {
  amex: {
    name: 'American Express',
    logo: AmericanExpressLogo,
  },
  visa: {
    name: 'Visa',
    logo: VisaLogo,
  },
  mastercard: {
    name: 'Mastercard',
    logo: MastercardLogo,
  },
  discover: {
    name: 'Discover',
    logo: DiscoverLogo,
  },
};

type KnownBrands = keyof typeof brands;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  card: Card | null;
  usBankAccount: BankAccount | null;
  setupIntentStatus?: StripeSetupIntentStatuses;
  isSummary?: boolean;
}

export default function PaymentMethodDetails({
  card,
  usBankAccount,
  setupIntentStatus,
  isSummary,
  ...props
}: Props): JSX.Element {
  const cardBrand = brands[card?.brand as KnownBrands];
  const CardBrandLogo = cardBrand?.logo;
  const cardBrandName = cardBrand?.name || 'Debit/Credit Card';

  return (
    <Layout gap="var(--spacing-3)" {...props}>
      {card ? (
        <>
          <StyledLogo>
            {CardBrandLogo ? (
              <CardBrandLogo data-testid="card-brand-logo" />
            ) : (
              <GenericCard data-testid="generic-card" />
            )}
          </StyledLogo>
          <MethodDetails data-testid="card-details">
            {`${cardBrandName} ••••${card.last4}`}
            <br />
            <MetaText data-testid="card-expiration">
              Expires {`0${card.expMonth.toString()}`.slice(-2)}/
              {card.expYear.toString().slice(-2)}
            </MetaText>
          </MethodDetails>
        </>
      ) : usBankAccount ? (
        <>
          <StyledLogo>
            <GenericBankAccount data-testid="generic-bank-account" />
          </StyledLogo>
          <MethodDetails data-testid="bank-account-details">
            {`${usBankAccount.bankName} ••••${usBankAccount.last4}`} <br />
            {!isSummary ? (
              <MetaText data-testid="bank-account-status">
                {setupIntentStatus && setupIntentStatus !== 'succeeded'
                  ? 'Needs verification'
                  : 'Verified'}
              </MetaText>
            ) : null}
          </MethodDetails>
        </>
      ) : (
        <>
          <StyledLogo>
            <GenericCard data-testid="generic-card" />
          </StyledLogo>
          <MethodDetails data-testid="no-payment-method">
            No default payment method
          </MethodDetails>
        </>
      )}
    </Layout>
  );
}

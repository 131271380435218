import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentMethodsProvider } from 'components/billing/context/paymentMethods';
import { useEffect } from 'react';
import { STRIPE_PUBLIC_KEY } from 'utils/envVars';
import { COMMON_EVENT_NAMES, logEvent, MODAL_TYPES } from 'utils/events';
import PaymentMethodSteps from './PaymentMethodSteps';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY || '');

interface Props {
  close: () => void;
}

export default function PaymentMethodsModal({ close }: Props): JSX.Element {
  useEffect(() => {
    logEvent(COMMON_EVENT_NAMES.MODAL_OPENED, {
      modal_type: MODAL_TYPES.BILLING_PAYMENT_METHODS,
    });
  }, []);

  return (
    <PaymentMethodsProvider>
      <Elements stripe={stripePromise}>
        <PaymentMethodSteps close={close} />
      </Elements>
    </PaymentMethodsProvider>
  );
}

import {
  Badge,
  Button,
  DropdownButton,
  DropdownMenu,
  DropdownMenuItem,
  IconSet,
  Layout,
  ModalBody,
  ModalFooter,
  toast,
} from '@postscript/components';
import { usePaymentMethods } from 'components/billing/context/paymentMethods';
import { useUsageBilling } from 'components/billing/context/usageBilling';
import LoadingSpinner from 'components/generic/Loading/LoadingSpinner';
import { useState } from 'react';
import styled from 'styled-components';
import { logButtonClickEvent, logEvent, MODAL_TYPES } from 'utils/events';
import PaymentMethodDetails from './PaymentMethodDetails';

const StyledPaymentMethod = styled.div`
  display: flex;
  width: 100%;

  & > * {
    margin: auto 0;
  }

  & > :first-child {
    flex: 1;
  }
`;

const StyledBadge = styled(Badge)`
  margin: auto 0;
`;

interface Props {
  closeModal: () => void;
}

export default function PaymentMethodManagement({
  closeModal,
}: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const { getPaymentMethod } = useUsageBilling();
  const {
    updateStep,
    paymentMethods,
    unverifiedPaymentMethods,
    defaultPaymentMethodId,
    isLoading: arePaymentMethodsLoading,
    setDefaultPaymentMethod,
  } = usePaymentMethods();

  const setNewDefaultMethod = async (id: string) => {
    try {
      if (id === defaultPaymentMethodId) return;

      setIsLoading(true);

      const toPaymentMethodType =
        paymentMethods.find((method) => method.id === id)?.card !== null
          ? 'card'
          : 'ach';
      const fromPaymentMethodType =
        paymentMethods.find((method) => method.id === defaultPaymentMethodId)
          ?.card !== null
          ? 'card'
          : 'ach';

      await setDefaultPaymentMethod(id);

      logEvent('default payment method changed', {
        to_payment_method_type: toPaymentMethodType,
        from_payment_method_type: fromPaymentMethodType,
      });

      await getPaymentMethod(); // Updates the payment method summary on the overview page

      toast.success('Default payment method changed.');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error as string);
    }
  };

  const PaymentMethodMenu = ({ id }: { id: string }) => (
    <DropdownButton
      id={`${id}_method_menu`}
      icon={IconSet.DotsHorizontal}
      iconFlipAnimation={false}
      variant="secondary"
      disabled={isLoading}
      data-cy="menu-button"
    >
      <DropdownMenu
        aria-labelledby={`${id}_method_menu`}
        menuAnchor="right"
        data-cy="drop-down-menu"
      >
        <DropdownMenuItem>
          <button
            type="button"
            onClick={() => {
              setNewDefaultMethod(id);
            }}
            data-cy="set-as-default-button"
          >
            Set as Default Payment Method
          </button>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <button
            type="button"
            onClick={() => {
              updateStep('deleteConfirmation', {
                paymentMethodToDeleteId: id,
              });
            }}
            data-cy="delete-button"
          >
            Delete Payment Method
          </button>
        </DropdownMenuItem>
      </DropdownMenu>
    </DropdownButton>
  );

  return (
    <>
      <ModalBody>
        {arePaymentMethodsLoading ? (
          <LoadingSpinner>Loading payment methods</LoadingSpinner>
        ) : (
          <Layout vertical gap="var(--spacing-3)">
            {[...paymentMethods, ...unverifiedPaymentMethods].map(
              ({ id, card, usBankAccount, setupIntentStatus }) =>
                card || usBankAccount ? (
                  <StyledPaymentMethod
                    key={`payment_method_${id}`}
                    data-cy={`payment_method_${id}`}
                  >
                    <PaymentMethodDetails
                      card={card || null}
                      usBankAccount={usBankAccount || null}
                      setupIntentStatus={setupIntentStatus}
                      isSummary={false}
                    />
                    {!setupIntentStatus ? (
                      <>
                        {id === defaultPaymentMethodId ? (
                          <StyledBadge
                            variant="success"
                            data-cy="current-badge"
                          >
                            Current payment method
                          </StyledBadge>
                        ) : (
                          <PaymentMethodMenu id={id} />
                        )}
                      </>
                    ) : null}
                  </StyledPaymentMethod>
                ) : null,
            )}
          </Layout>
        )}
        <Button
          variant="secondary"
          size="small"
          icon={IconSet.Plus}
          style={{ marginTop: 'var(--spacing-3)' }}
          onClick={(event) => {
            logButtonClickEvent(event, {
              modal_type: MODAL_TYPES.BILLING_PAYMENT_METHODS,
            });
            updateStep('typeChoice');
          }}
          disabled={arePaymentMethodsLoading || isLoading}
          data-cy="add-payment-method-button"
        >
          Add Payment Method
        </Button>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} disabled={isLoading}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
}

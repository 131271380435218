// labs feature flags
export const DYNAMIC_TERMS = 'dynamic_terms';
export const PS_PAY = 'pspay';
export const TEXT_TO_BUY_VARIANTS = 'text_to_buy_variants';
export const POPUP_DATA_COLLECTION = 'popup_data_collection';
export const POPUP_NEW_DEFAULTS = 'popup_new_defaults';
export const POPUP_SPLIT_TEST = 'popup_split_test';
export const POPUP_MM_DD_COLLECTION = 'popup_mm_dd_collection';
export const FB_FLOW_TEMPLATES = 'fb_flow_templates';
export const SLTV_DASHBOARD_DISABLED = 'sltv_dashboard_disabled';
export const SLTV_DASHBOARD_CTAS_DISABLED = 'sltv_dashboard_ctas_disabled';
export const FB_WAIT_SUBSCRIBER_TZ = 'fb_wait_subscriber_tz';
export const CONVERT = 'convert';
export const FLOW_BUILDER_INTEGRATION_INTO_LEGACY =
  'flow_builder_integration_into_legacy';
export const FB_DYNAMIC_ACTION_TYPES = 'fb_dynamic_action_types';
export const UNIFY_CAMPAIGN_MESSAGING_UX = 'unify_campaign_messaging_ux';
export const WEB_SOCKETS = 'web_sockets';
export const USAGE_BILLING_L30_REPORT = 'billing_usage_report';
export const SELF_MIGRATE_TO_USAGE_BILLING = 'self_migrate_to_usage_billing';
export const HIDE_PAYMENT_METHOD = 'hide_payment_method';
export const AVAILABLE_PAYMENT_METHODS_MANAGEMENT =
  'available_payment_methods_management';
export const BILLING_SPEND_NOTIFICATIONS = 'billing_spend_notifications';
export const BILLING_SALES_PLANS = 'billing_sales_plans';
export const DELIVERABILITY_STATS = 'deliverability_stats';

// feature flags
export const SUBSCRIBER_ANALYTICS_ENABLED = 'subscriber-analytics';
export const USAGE_BILLING_ENABLED = 'usage_billing';
export const DARK_MODE = 'dark_mode';
export const EXEMPT_FROM_SHOP_TOS_GATE =
  'exempt_shop_from_terms_of_service_gate';
export const TRANSACTIONAL_MESSAGES_ENABLED = 'transactional_messages_enabled';
export const PS_LABS = 'ps_labs';
export const GET_KLAVIYO_EVENTS_ENABLED = 'klaviyo_incoming_events';
export const FLOW_BUILDER_TRANSACTIONAL_AUTOMATIONS =
  'flow_builder_transactional_automations';
export const GORGIAS_V2 = 'gorgias_v2';
export const YOTPO_LOYALTY_V2 = 'yotpo_loyalty_v2';
export const LISTRAK_INTEGRATION = 'listrak_integration';
export const MAILCHIMP_INTEGRATION = 'mailchimp_integration';
export const CORDIAL_INTEGRATION = 'cordial_integration';
export const OMNISEND_INTEGRATION = 'omnisend_integration';
export const CARRIER_SEGMENTS_ENABLED = 'carrier_segments';
export const FB_APPROXIMATE_MATCHES = 'fb_approximate_matches';

const featureFlags = [
  {
    label: 'Analytics',
    value: SUBSCRIBER_ANALYTICS_ENABLED,
  },
  {
    label: 'Usage Billing',
    value: USAGE_BILLING_ENABLED,
    disabled: true,
  },
  {
    label: 'Dark mode',
    value: DARK_MODE,
  },
  {
    label: '(Legal Only) Exempt Shop from Terms of Service Gate',
    value: EXEMPT_FROM_SHOP_TOS_GATE,
  },
  {
    label: 'Postscript hosts dynamic Terms of Service and Privacy Policy pages',
    value: DYNAMIC_TERMS,
  },
  {
    label: 'Transactional Messages',
    value: TRANSACTIONAL_MESSAGES_ENABLED,
  },
  {
    label: 'Postscript Labs',
    value: PS_LABS,
  },
  {
    label: 'Get Klaviyo Events',
    value: GET_KLAVIYO_EVENTS_ENABLED,
  },
];

export default featureFlags;

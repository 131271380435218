import axios from 'axios';

interface ApiError {
  msg: string;
  type: 'value_error';
}

interface ApiErrorResponse {
  errors: ApiError[];
}

const isApiError = (error: unknown): error is ApiError => {
  const errorValue = error as ApiError;

  if (!errorValue || typeof errorValue !== 'object') return false;
  if (!errorValue.msg || typeof errorValue.msg !== 'string') return false;
  if (errorValue.type !== 'value_error') return false;

  return true;
};

const isApiErrorResponse = (
  response: unknown,
): response is ApiErrorResponse => {
  const responseValue = response as ApiErrorResponse;

  if (!responseValue || typeof responseValue !== 'object') return false;
  if (
    !responseValue.errors ||
    !Array.isArray(responseValue.errors) ||
    responseValue.errors.length === 0
  )
    return false;
  if (responseValue.errors.some((error) => !isApiError(error))) return false;

  return true;
};

export const formatErrorMessage = (err: unknown): string => {
  if (err && axios.isAxiosError(err)) {
    const apiErrorResponse = err?.response?.data;
    if (isApiErrorResponse(apiErrorResponse) && apiErrorResponse.errors[0]?.msg)
      return apiErrorResponse.errors[0].msg;
    const errorMessage =
      err?.response?.data?.error_message || err?.response?.data?.message;
    if (errorMessage) return errorMessage;
  }

  if (err instanceof Error && err.message) return err.message;

  if (typeof err === 'string' && err) return err;

  return 'Error';
};

import { BodyText, Card, Heading } from '@postscript/components';
import PageHeader from 'components/layout/PageHeader';
import { useUsers } from 'controllers/contexts/user';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { userHasLegacyRole } from '../helpers';
import { useGetUserProfile } from '../hooks/useUsers';
import ProfileForm from './ProfileForm';
import UserSettingsForm from './UserSettingsForm';

const StyledHeading = styled(Heading)`
  margin-bottom: var(--spacing-1);
`;

const StyledBodyText = styled(BodyText)`
  margin-bottom: var(--spacing-6);
`;

const StyledCard = styled(Card)`
  margin-bottom: var(--spacing-6);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export default function Profile(): JSX.Element | null {
  const { push } = useHistory();
  const {
    user: { guid, username },
  }: any = useUsers();
  const { data } = useGetUserProfile();
  const isMyShopifyUser = username.includes('.myshopify.com');

  const isAiAlphaTester = userHasLegacyRole(`AI_ALPHA_TESTER`);
  const isSalesAgentWorkspaceTester = userHasLegacyRole(
    `SHOP_SALES_AGENT_WORKSPACE_TESTER`,
  );

  if (isMyShopifyUser) {
    push('/');
    return null;
  }

  const user = {
    guid,
    username,
    profile: data,
  };

  const showUserSettings = isAiAlphaTester || isSalesAgentWorkspaceTester;

  return (
    <>
      <PageHeader pageTitle="Profile" />
      <StyledCard>
        <StyledHeading size="x-small">Basic Information</StyledHeading>
        <StyledBodyText size="small">
          This information is shared internally with your team
        </StyledBodyText>
        <ProfileForm user={user} />
      </StyledCard>
      {showUserSettings && (
        <StyledCard>
          <StyledHeading size="x-small">User Settings</StyledHeading>
          <StyledBodyText size="small">
            User functionality and settings
          </StyledBodyText>
          <UserSettingsForm />
        </StyledCard>
      )}
    </>
  );
}

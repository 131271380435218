// Grab the credits used by the current shop.

import { useEffect, useState } from 'react';
import { Requests } from '../controllers/network';

const useCreditCount = () => {
  const [credits, changeCredits] = useState({});

  const getCredits = () => {
    const creditUsageRequests = [
      Requests.get('/shop/credits_used'),
      Requests.get('/shops/credit_usage'),
    ];

    Promise.all(creditUsageRequests).then(([creditCount, usageSummary]) => {
      changeCredits({
        creditCount,
        usageSummary: usageSummary.data,
      });
    });
  };

  useEffect(getCredits, []);

  return credits;
};

export default useCreditCount;

import { useGetMarketingPlans } from 'components/billing/context/useBilling';
import ChangePlanModal from 'components/billing/modules/plans/ChangePlanModal';
import { useBanners } from 'components/GlobalBanners/globalBanners';
import { useGlobalModal } from 'components/GlobalModal/globalModal';
import qs from 'qs';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { logEvent } from 'utils/events';
import { RAC_APPROVED_BANNER_ID } from '../constants';

export default function RacApprovedConfirmation(): null {
  const { addBanner, removeBanner } = useBanners();
  const { showModal, hideModal } = useGlobalModal();
  const { data: plans } = useGetMarketingPlans();
  const { search } = useLocation();
  const params = qs.parse(search, {
    ignoreQueryPrefix: true,
  });
  const { push } = useHistory();

  useEffect(() => {
    const { rac_approved: approved } = params;

    if (!approved) return;

    logEvent('rac approved');

    addBanner({
      id: RAC_APPROVED_BANNER_ID,
      isDismissable: true,
      data: {
        variant: 'success',
        heading: 'Billing setup complete',
        bodyText: 'Thanks for setting up Postscript billing with Shopify!',
        primaryAction: {
          text: 'Visit Billing Overview',
          onClick: () => {
            removeBanner(RAC_APPROVED_BANNER_ID);
            push('/billing/overview');
          },
        },
      },
    });
  }, []);

  useEffect(() => {
    const { target_plan_id: targetPlanId } = params;

    if (!targetPlanId || !plans) return;

    const toPlan = plans.find(
      ({ id }) => id === parseInt(targetPlanId.toString()),
    );

    if (!toPlan) return;

    showModal({
      dismissOnBackdropClick: false,
      children: <ChangePlanModal toPlan={toPlan} isUpgrade close={hideModal} />,
    });
  }, [plans]);

  return null;
}

/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect } from 'react';
import styled, { css } from 'styled-components';

interface HubspotFormBlock {
  target?: string;
  hubspotFormId: string;
  isSingleField: boolean;
  theme?: string;
  onFormSubmitted?: (form: any, callback: () => void) => void;
}

const breakpoints = {
  small: '768px',
  medium: '1024px',
  large: '1248px',
  xLarge: '1439px',
};

declare global {
  interface Window {
    jQuery: any;
  }
}

const Form = styled.div`
  ${(props: { singleField?: boolean }) =>
    props.singleField
      ? css`
          .hs-form {
            display: flex;
            flex-flow: row wrap;
            gap: var(--spacing-4);
            @media screen and (max-width: ${breakpoints.medium}) {
              display: grid;
            }
          }
          .hs-form-field label:not(.hs-error-msg) {
            display: none;
          }
          .hs-form-field {
            max-width: 375px;
            @media screen and (max-width: ${breakpoints.medium}) {
              max-width: 100%;
            }
          }
        `
      : css`
          .hs-form-field label {
            display: block;
            margin-bottom: var(--spacing-2);
          }
        `}

  ${(props: { theme?: string }) =>
    props.theme === 'dark-ui'
      ? css`
          --black: white;
          --btn-text-color: black;
          p {
            color: white;
          }
          .hs-form-field label {
            color: white;
          }
          .hs-button.primary.large {
            color: white;
          }
        `
      : css`
          --btn-text-color: white;
          --link-color: var(--purple-core);
          --link-color-hover: var(--purple-8);
        `}

  .hs-form fieldset {
    max-width: 100%;
    margin: var(--spacing-4) 0;
    @media screen and (min-width: ${breakpoints.small}) {
      display: flex;
      gap: var(--spacing-6);
    }
  }

  .hs-recaptcha {
    margin: var(--spacing-4) 0;
  }

  .hs-form-field {
    width: 100%;
    position: relative;
  }

  .hs-button.primary.large {
    font: var(--heading-x-small);
    background: var(--black);
    color: var(--btn-text-color);
    padding: var(--spacing-2) var(--spacing-4);
    border: none;
    border-radius: var(--border-radius-round);
    transition: var(--transition-background);
    cursor: pointer;
    @media screen and (max-width: ${breakpoints.small}) {
      width: 100%;
      font: var(--heading-x-small-responsive);
      padding: var(--spacing-1) var(--spacing-3);
      min-height: auto;
    }
  }
  .hs-button.primary.large:hover {
    transition: var(--transition-background);
    background: var(--btn-color);
  }
  .hs-button.primary.large:active {
    background: var(--btn-color);
  }
  .hs-button.primary.large:focus-visible {
    outline: none;
    border-color: var(--purple-core);
    background: var(--btn-color-focus);
  }

  fieldset.form-columns-1 .hs-input[type='text'],
  fieldset.form-columns-1 .hs-input[type='tel'],
  fieldset.form-columns-2 .hs-input[type='text'],
  textarea {
    width: 100% !important;
  }

  .hs-form .form-columns-1:empty {
    margin: 0px !important;
  }

  .hs-input {
    width: 100%;
    min-height: 54px;
    padding: var(--spacing-2) var(--spacing-4);
    border: 1px solid var(--field-border-color);
    border-radius: var(--border-radius-small);
    font: var(--body-text-medium);
    transition: border ease 300ms;
  }

  .hs-input[type='email'] {
    width: 100% !important;
  }

  .hs-form-checkbox {
    width: auto !important;
    min-height: auto !important;
    display: flex;
    flex-wrap: nowrap;
  }

  .hs-form-radio,
  .hs-form-checkbox,
  .hs-form-booleancheckbox {
    list-style: none;
  }

  .hs-form-radio label,
  .hs-form-checkbox label {
    display: flex;
    align-items: center;
    gap: var(--spacing-2);
  }

  .hs-form-radio-display input,
  .hs-form-checkbox-display input,
  .hs-form-booleancheckbox-display input {
    width: auto;
    min-height: auto;
    accent-color: var(--link-color);
    width: var(--spacing-3);
    height: var(--spacing-3);
  }

  .hs-form-booleancheckbox .hs-form-booleancheckbox-display {
    display: flex;
    align-items: center;
    gap: var(--spacing-2);
  }

  .hs-input:hover {
    border-color: var(--highlight-color);
  }

  .hs-input.invalid.error {
    border-color: var(--error-color);
    box-shadow: 0 0 5px var(--error-color);
  }

  .hs-input:focus-visible {
    outline: none;
  }

  .hs-input:focus {
    box-shadow: 0 0 5px var(--highlight-color);
    border-color: var(--highlight-color);
  }

  .hs-error-msgs {
    color: var(--text-color-dim);
    font: var(--body-text-x-small);
    font-weight: 600;
    list-style: none;
    position: relative;
    top: 10px;
  }

  .hs_error_rollup {
    display: none;
  }

  .submitted-message {
    font: var(--eyebrow-text-large-responsive);
    color: var(--highlight-color);
    text-align: center;
  }

  #hsForm_f885bbc3-947c-4707-9754-01ed2124a4bb .hs-email + div {
    order: 1;
  }

  #hsForm_f885bbc3-947c-4707-9754-01ed2124a4bb .hs-button.primary.large {
    font: var(--heading-x-small);
    line-height: 1;
    background: none;
    color: var(--purple-9);
    padding: var(--spacing-2) var(--spacing-4);
    border: 3px solid var(--purple-9);
    border-radius: var(--border-radius-round);
    transition: border ease 300ms;
    cursor: pointer;
    &:hover {
      border-color: var(--purple-core);
    }
    &:active {
      background: var(--purple-core);
      color: var(--white);
    }
    &:focus-visible {
      outline: none;
      border-color: var(--purple-core);
      background: var(--purple-1);
    }

    @media screen and (max-width: ${breakpoints.small}) {
      font-size: 14px;
    }
  }

  #hsForm_f9ad5635-19ba-4c67-94bc-787d7754078f fieldset {
    &:nth-of-type(4) {
      margin: 0;
    }
    &:nth-of-type(5) {
      margin: 0;
    }
    &:nth-of-type(6) {
      margin: 0;
    }
    &:nth-of-type(7) {
      margin: 0;
    }
  }

  @media screen and (max-width: 480px) {
    .hs-button {
      width: 100%;
    }
    .hs-submit {
      width: 100%;
    }
  }
`;

const HubSpotForm = ({
  target,
  hubspotFormId,
  isSingleField,
  theme,
  onFormSubmitted,
}: HubspotFormBlock): JSX.Element => {
  useEffect(() => {
    window.jQuery =
      window.jQuery ||
      (() => ({
        // these are all methods required by HubSpot
        change: () => {},
        trigger: () => {},
      }));

    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @ts-expect-error: window.hbspt
      if (!window.hbspt) return;

      // @ts-expect-error: window.hbspt
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '6202253',
        formId: hubspotFormId,
        target: `#${target}`,
        onFormSubmitted,
      });
    });
  }, []);

  return (
    <>
      <script id="hubspot-form" src="//js.hsforms.net/forms/v2.js" />
      <div>
        <Form theme={theme} singleField={isSingleField} id={target} />
      </div>
    </>
  );
};
export default HubSpotForm;

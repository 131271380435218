// User context object.

import { createContext, useContext } from 'react';

const UserContext = createContext({
  user: {},
  getUserInfo: () => {},
  updateUserInfo: (user) => user,
  shops: [],
  updateShops: () => {},
  isFetchingShops: false,
});

export const useUsers = () => useContext(UserContext);

export default UserContext;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { toast } from '@postscript/components';
import ConfirmationModal from 'components/billing/common/ConfirmationModal';
import {
  useGetCurrentAndNextCycles,
  useGetCurrentPlan,
  useSetNextPlan,
} from 'components/billing/context/useBilling';
import { useGlobalModal } from 'components/GlobalModal/globalModal';

export default function CancelPlanChangeModal(): JSX.Element {
  const { hideModal } = useGlobalModal();
  const { mutateAsync: setNextPlan } = useSetNextPlan();
  const { data: { nextCycle } = {} } = useGetCurrentAndNextCycles();
  const { data: currentPlan } = useGetCurrentPlan();
  const planId = currentPlan!.id;
  const currentPackageName = currentPlan?.package?.name;

  return (
    <ConfirmationModal
      isRenderedWithGlobalModal
      heading="Cancel scheduled plan change"
      content={
        <>
          If you cancel this upcoming plan change, you&apos;ll stay on{' '}
          <strong>{currentPackageName}</strong>.
        </>
      }
      confirmation="Cancel Scheduled Plan Change"
      cancellation="Never mind"
      onConfirm={async () => {
        await setNextPlan({
          planId,
          cycleExists: !!nextCycle,
        });

        toast.success(
          `Plan change cancelled. You'll stay on your current plan.`,
        );
      }}
      isOpen
      close={hideModal}
    />
  );
}

import {
  BodyText,
  Button,
  Checkbox,
  Icon,
  IconSet,
  LinkText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@postscript/components';
import { useState } from 'react';
import styled from 'styled-components';
import { useTOS } from '../../controllers/contexts/termsOfService';

const UpdatedHeader = styled(BodyText)`
  margin-top: var(--spacing-4);
  color: var(--body-text);
  font-weight: bold;
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

const StyledButton = styled(Button)`
  height: fit-content;
`;

const TOSLink = () => {
  return (
    <LinkText
      href="https://www.postscript.io/subscriber-messaging-terms"
      target="_blank"
    >
      terms of service <Icon component={IconSet.Share} size={14} />
    </LinkText>
  );
};

const PrivacyPolicyLink = () => {
  return (
    <LinkText href="https://www.postscript.io/privacy" target="_blank">
      privacy policy.* <Icon component={IconSet.Share} size={14} />
    </LinkText>
  );
};

const AcceptTOSView = (): JSX.Element | null => {
  const { latestDate, acceptTerms, hasAcceptedLatestTOS } = useTOS();
  const [accepted, setAccepted] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const handleCheckbox = () => {
    setAccepted(!accepted);
  };

  if (hasAcceptedLatestTOS) {
    return null;
  }

  const acceptTOS = () => {
    setIsOpen(false);
    setTimeout(() => {
      acceptTerms?.();
    }, 300);
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Modal isOpen={isOpen} onCancel={() => {}} dismissOnBackdropClick={false}>
      <ModalHeader>Updated Terms of Service</ModalHeader>
      <ModalBody>
        You must accept the latest terms of service and privacy policy before
        continuing.
        <UpdatedHeader>
          Updated: {latestDate?.toLocaleDateString()}
        </UpdatedHeader>
      </ModalBody>
      <ModalFooter>
        <StyledFooter>
          <Checkbox
            id="dtos-accept"
            className="dtos-accept-checkbox"
            label={
              <>
                {`I understand and agree to Postscript's `}
                <TOSLink />
                {` and `}
                <PrivacyPolicyLink />
              </>
            }
            checked={accepted}
            onChange={() => handleCheckbox()}
          />
          <StyledButton disabled={!accepted} onClick={acceptTOS}>
            Continue
          </StyledButton>
        </StyledFooter>
      </ModalFooter>
    </Modal>
  );
};

export default AcceptTOSView;

import LinkButton from 'components/billing/common/LinkButton';
import { MessageRate } from 'components/billing/common/types';
import { findMessageRateByCodeAndType } from 'components/billing/common/utils';
import { useGlobalModal } from 'components/GlobalModal/globalModal';
import AllMessageRatesModal from './AllMessageRatesModal';
import CountryMessageRates from './CountryMessageRates';

interface Props {
  messageRates?: MessageRate[];
  smsMultiplier?: number;
  mmsMultiplier?: number;
}

export default function PlanMessageRatesSummary({
  messageRates,
  smsMultiplier,
  mmsMultiplier,
}: Props): JSX.Element {
  const { showModal, hideModal } = useGlobalModal();

  const hasMessageRates = !!(messageRates && smsMultiplier && mmsMultiplier);

  const showAllMessageRatesModal = () => {
    if (!hasMessageRates) return;

    showModal({
      children: (
        <AllMessageRatesModal
          messageRates={messageRates}
          smsMultiplier={smsMultiplier}
          mmsMultiplier={mmsMultiplier}
          close={hideModal}
        />
      ),
      dismissOnBackdropClick: true,
    });
  };

  return (
    <>
      <CountryMessageRates
        countryCode="US"
        smsRate={
          findMessageRateByCodeAndType(messageRates, 'US', 'SMS') ||
          smsMultiplier
        }
        mmsRate={
          findMessageRateByCodeAndType(messageRates, 'US', 'MMS') ||
          mmsMultiplier
        }
      />
      <CountryMessageRates
        countryCode="CA"
        smsRate={
          findMessageRateByCodeAndType(messageRates, 'CA', 'SMS') ||
          smsMultiplier
        }
        mmsRate={
          findMessageRateByCodeAndType(messageRates, 'CA', 'MMS') ||
          mmsMultiplier
        }
      />
      <CountryMessageRates
        countryCode="PR"
        smsRate={
          findMessageRateByCodeAndType(messageRates, 'PR', 'SMS') ||
          findMessageRateByCodeAndType(messageRates, '*', 'SMS') ||
          (smsMultiplier || 0) * 10
        }
      />
      <LinkButton
        type="button"
        onClick={showAllMessageRatesModal}
        style={hasMessageRates ? {} : { opacity: 0, pointerEvents: 'none' }} // leaving this button in place when "hidden" beacuse otherwise we lose proper sizing of the packages list
      >
        View all messaging rates
      </LinkButton>
    </>
  );
}

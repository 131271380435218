/* eslint-disable camelcase */

import { EXEMPT_FROM_SHOP_TOS_GATE } from 'components/admin/utils/feature-flags';
import { api } from 'controllers/network/apiClient';
import { createContext, useContext, useEffect, useState } from 'react';
import { FeatureFlagsContext } from './featureFlags';
import UserContext from './user';

type AcceptTermsResponse = {
  terms_updated_at: string;
  terms_accepted_at: string;
  has_accepted_latest_terms: string;
};

type TOSProps = {
  acceptTerms?: () => void;
  latestDate?: Date;
  lastAcceptedDate?: Date;
  hasAcceptedLatestTOS?: boolean;
};

const defaultState: TOSProps = {
  acceptTerms: undefined,
  latestDate: undefined,
  lastAcceptedDate: undefined,
  hasAcceptedLatestTOS: undefined,
};

export const AcceptedTOSContext = createContext(defaultState);
export const useTOS = (): TOSProps => useContext(AcceptedTOSContext);

type AcceptedTOSProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export const AcceptedTOSProvider = ({
  children,
}: AcceptedTOSProviderProps): JSX.Element => {
  const [latestDate, setLatestDate] = useState<Date>();
  const [lastAcceptedDate, setLastAcceptedDate] = useState<Date>();
  const [hasAcceptedLatestTOS, setHasAcceptedLatestTOS] = useState(true);

  const { hasFlag, flags }: any = useContext(FeatureFlagsContext);
  const { user } = useContext(UserContext);

  const acceptTerms = () => {
    setLastAcceptedDate(new Date());
    setHasAcceptedLatestTOS(true);

    api.post('/v2/legal/shop/accepted_terms').catch();
  };

  useEffect(() => {
    const isAdminIndex = Object.keys(user).indexOf('is_admin');
    const isAdmin =
      isAdminIndex !== -1 ? Object.values(user)[isAdminIndex] : false;

    if (hasFlag(EXEMPT_FROM_SHOP_TOS_GATE) || isAdmin) return;

    api
      .get('/v2/legal/shop/accepted_terms')
      .then((result) => {
        if (result) {
          const {
            terms_updated_at,
            terms_accepted_at,
            has_accepted_latest_terms,
          }: AcceptTermsResponse = result;

          if (terms_updated_at) {
            const updated = new Date(terms_updated_at);
            setLatestDate(updated);

            const accepted = new Date(terms_accepted_at);
            setLastAcceptedDate(accepted);

            setHasAcceptedLatestTOS(JSON.parse(has_accepted_latest_terms));
          } else {
            setHasAcceptedLatestTOS(true);
          }
        }
      })
      .catch();
  }, [flags]);

  return (
    <AcceptedTOSContext.Provider
      value={{
        acceptTerms,
        latestDate,
        lastAcceptedDate,
        hasAcceptedLatestTOS,
      }}
    >
      {children}
    </AcceptedTOSContext.Provider>
  );
};

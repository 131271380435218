// the provider for the modals context

import { useContext } from 'react';
import { ModalContext } from '../controllers/contexts';

export const modals = (Component) => {
  return (props) => {
    const { modals, addModal, addDialog } = useContext(ModalContext);
    return (
      <Component
        {...props}
        modals={modals}
        addModal={addModal}
        addDialog={addDialog}
      />
    );
  };
};

export default modals;

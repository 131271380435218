import { FieldError, Icon, IconSet } from '@postscript/components';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

export type ChangeEvent = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

export interface EditableHeaderTextProps
  extends React.HTMLAttributes<HTMLElement> {
  'data-cy'?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  errorSize?: 'small' | 'medium' | 'large';
  iconSize?: number;
  id?: string;
  labelledBy?: string;
  multiLine?: boolean;
  name?: string;
  onChange?: (e: ChangeEvent) => void;
  placeholder?: string;
  value?: string | number;
  variant?: 'title' | 'description';
}

// needs hidden abilities

// prettier-ignore
const EditIcon = styled(Icon)<EditableHeaderTextProps>`
  display: ${({ disabled }) => (disabled ? 'none' : 'inline-flex')};
  position: relative;
  cursor: pointer;
  flex: 0 1 var(--icon-size);
  width: var(--icon-size);
  color: var(--text-color-dim);
  opacity: 0;
  transform: translateX(var(--spacing-1));
  transition-property: color, opacity, transform;
  transition-duration: var(--hover-transition);
`;

const FauxInput = styled.h1<EditableHeaderTextProps>`
  font: inherit;
  text-overflow: ellipsis;
  max-width: ${({ variant }) => (variant === 'description' ? '80ch' : '100%')};
  margin: 0;
  transition: font-size var(--nav-menu-transition-speed), padding 250ms ease;
  opacity: ${({ disabled }) => (disabled ? '.5' : '1')};
  pointer-events: none;
  position: relative;
  z-index: 1;

  *:placeholder-shown + & {
    color: var(--gray-5);
  }

  ${({ variant }) =>
    variant === 'description' &&
    css`
      color: var(--gray-5);
    `}
`;

// prettier-ignore
const StyledInputWrapper = styled.div<EditableHeaderTextProps>`
  position: relative;
  cursor: pointer;
  padding: 0;
  color: inherit;
  display: inline-flex;
  flex: 1 0;
  flex-basis: 0;
  min-width: 0;
  overflow: hidden;
  transition: border-color var(--hover-transition) ease;
  text-overflow: ellipsis;
  width: auto;
  max-width: 100%;

  &:after {
    display: ${({ disabled }) => (disabled ? 'none' : 'block')};
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 1px dotted var(--editable-header-text-border-color);
    opacity: ${({ error }) => (error ? '1' : '0')};
    transition: border-color 250ms;
  }

  input,
  textarea {
    appearance: none;
    display: block;
    background: var(--main-bkg-color);
    color: inherit;
    box-shadow: none;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    transition: font-size var(--nav-menu-transition-speed), padding 250ms ease;
    resize: none;
    font: inherit;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    max-width: 100%;

    opacity: 0;

    &:focus {
      user-select: text;
      cursor: text;
      outline: none;
      text-overflow: clip;
    }

    &:hover {
      box-shadow: none;
    }

    &::-moz-focus-inner {
      margin-top: -2px;
      margin-bottom: -2px;
      border: 0;
      padding: 0;
    }

    ::-webkit-input-placeholder{ padding-top: 0; }

    ::placeholder {
      opacity: 1;
      font: inherit;
      color: var(--gray-5);
    }

    &[disabled] {
      opacity: .5;
      pointer-events: none;
    }
  }

  ${FauxInput} {
    overflow: ${({ multiLine }) => (multiLine ? 'visible' : 'hidden')};
    white-space: ${({ multiLine }) => (multiLine ? 'pre-wrap' : 'pre')};
  }

  textarea {
    min-height: 100%;
    overflow: hidden;
  }

  &:hover:after {
    opacity: 1;
  }
`;

// prettier-ignore
const StyledEditableHeaderText = styled.label<EditableHeaderTextProps & { height: number; width: number }>`
  --editable-header-text-height: ${({ height }) => `${height}px`};
  --editable-header-text-width: ${({ width }) => `${width}px`};
  --editable-header-text-border-color: ${({ error }) => error ? 'var(--error-color)' : 'var(--highlight-color)'};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  max-width: 100%;

  &:focus-within {
    ${StyledInputWrapper}:after {
      opacity: 1;
      border-bottom-style: solid;
    }

    textarea {
      height: var(--editable-header-text-height);
    }

    input,
    textarea {
      opacity: 1;
    }

    ${FauxInput} {
      opacity: 0;
    }

    input,
    textarea,
    ${FauxInput} {
      padding: 0 var(--spacing-1);
    }
  }

  &.is-empty ${EditIcon} {
    opacity: 1;
    transform: translateX(0);
  }

  &:focus-within,
  &:hover {
    ${EditIcon} {
      opacity: 1;
      transform: translateX(0);
      color: var(--highlight-color);
    }
  }
`;

const StyledFieldError = styled(FieldError)`
  padding: var(--spacing-1) 0;
  margin-left: -3px;
`;

const EditableHeaderText = ({
  className,
  disabled = false,
  error = false,
  errorMessage = `There's an error with this field`,
  errorSize = 'medium',
  iconSize,
  id = '',
  labelledBy,
  multiLine = false,
  name = '',
  onChange,
  placeholder = 'Enter a value',
  tabIndex = 0,
  value,
  variant = 'title',
  ...props
}: EditableHeaderTextProps): JSX.Element => {
  const fauxInput = useRef<HTMLHeadingElement>(null);
  const [content, setContent] = useState('');
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setWidth(fauxInput?.current?.offsetWidth || 84);
    setHeight(fauxInput?.current?.offsetHeight || 84);
  }, [content]);

  const changeHandler = (event: ChangeEvent) => {
    let newValue = placeholder;
    const eventValue = event?.currentTarget?.value;
    if (eventValue && eventValue !== '') {
      newValue = eventValue;
    }
    setContent(newValue);
    setWidth(fauxInput?.current?.offsetWidth || 84);
    setHeight(fauxInput?.current?.offsetHeight || 84);
    onChange?.(event);
  };

  return (
    <>
      <StyledEditableHeaderText
        className={`${className} ${!value || value === '' ? 'is-empty' : ''}`}
        error={error}
        height={height}
        htmlFor={id}
        width={width}
      >
        <StyledInputWrapper
          disabled={disabled}
          error={error}
          multiLine={multiLine}
        >
          {multiLine ? (
            <textarea
              aria-label={placeholder}
              aria-labelledby={labelledBy}
              disabled={disabled}
              id={id}
              name={name}
              onChange={changeHandler}
              placeholder={placeholder}
              tabIndex={tabIndex}
              value={value}
              {...props}
            />
          ) : (
            <input
              aria-label={placeholder}
              aria-labelledby={labelledBy}
              disabled={disabled}
              id={id}
              name={name}
              onChange={changeHandler}
              placeholder={placeholder}
              tabIndex={tabIndex}
              title={value ? value.toString() : undefined}
              type="text"
              value={value}
              {...props}
            />
          )}
          <FauxInput
            as={variant === 'description' ? 'p' : undefined}
            disabled={disabled}
            ref={fauxInput}
            variant={variant}
          >
            {!value || value === '' ? placeholder : value}
          </FauxInput>
        </StyledInputWrapper>
        {variant === 'title' && (
          <EditIcon
            component={IconSet.Pencil}
            disabled={disabled}
            size={iconSize}
          />
        )}
      </StyledEditableHeaderText>
      {errorMessage && error && (
        <StyledFieldError fieldSize={errorSize} htmlFor={id}>
          {errorMessage}
        </StyledFieldError>
      )}
    </>
  );
};

export default EditableHeaderText;

/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */

import { toast } from '@postscript/components';
import AnnouncementBanner from 'components/admin/Announcements/AnnouncementBanner';
import {
  AnnouncementType,
  Banner,
  DEFAULT_COLOR,
  formatShopIDs,
  MMSFilterType,
  validateShopIDs,
} from 'components/admin/Announcements/utils';
import MessagePlannerBanner from 'components/MessagePlanner/MessagePlannerBanner';
import { api } from 'controllers/network/apiClient';
import produce from 'immer';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { useLegacyBanners } from './legacyGlobalBanners';
import { useUsers } from './user';

type AnnouncementPayload = {
  id: number;
  text?: string;
  cta_url?: string;
  cta_url_text?: string;
  shop_filter?: { phone_number_type: 'dsc' | 'tfn' };
  shop_ids?: number[];
  color?: string;
  start_at?: Date;
  end_at?: Date;
  enabled?: boolean;
  announcement_type?: string;
  icon?: string;
  icon_color?: string;
};

const SET_ANNOUNCEMENTS = 'SET_ANNOUNCEMENTS';
const ALL_ANNOUNCEMENTS = 'ALL_ANNOUNCEMENTS';

export interface Announcement {
  id: number;
  data: Banner;
}

const payloadToAnnouncements = (payload: AnnouncementPayload[]): Banner[] => {
  const getTarget = (announcement: AnnouncementPayload): MMSFilterType => {
    if (!announcement.shop_filter)
      if (announcement.shop_ids) return 'shopId';
      else return 'all';

    switch (announcement?.shop_filter.phone_number_type) {
      case 'tfn':
        return 'tfn';
      case 'dsc':
        return 'shortcode';
      default:
        return 'all';
    }
  };

  const status: Banner[] = [];
  payload.forEach((announcementPayload) => {
    const newBanner: Banner = {
      title: 'Announcement',
      message: announcementPayload.text ?? '',
      ctaText: announcementPayload.cta_url_text,
      ctaUrl: announcementPayload.cta_url,
      idList: formatShopIDs(announcementPayload.shop_ids),
      target: getTarget(announcementPayload),
      id: announcementPayload.id,
      color:
        announcementPayload.color !== null
          ? announcementPayload.color
          : undefined,
      startAt: announcementPayload.start_at?.toString(),
      endAt: announcementPayload.end_at?.toString(),
      enabled: announcementPayload.enabled,
      announcementType: announcementPayload.announcement_type,
      icon: announcementPayload.icon,
      iconColor: announcementPayload.icon_color,
    };
    status.push(newBanner);
  });

  return status;
};

const announcementToPayload = (
  announcement: Announcement,
): AnnouncementPayload => {
  const getFilter = (
    target?: MMSFilterType,
  ): { phone_number_type: 'dsc' | 'tfn' } | undefined => {
    if (!target) return undefined;

    switch (target) {
      case 'tfn':
        return { phone_number_type: 'tfn' };
      case 'shortcode':
        return { phone_number_type: 'dsc' };
      default:
        return undefined;
    }
  };

  let target: { phone_number_type: 'dsc' | 'tfn' } | undefined;
  let ids: number[] | undefined;

  if (announcement.data.target === 'shopId' && announcement.data.idList) {
    ids = validateShopIDs(announcement.data.idList);
  } else {
    target = getFilter(announcement.data?.target);
  }
  const payload: AnnouncementPayload = {
    id: announcement.id,
    text: announcement.data?.message,
    cta_url: announcement.data?.ctaUrl,
    cta_url_text: announcement.data?.ctaText,
    shop_filter: target,
    shop_ids: ids,
    color: announcement.data?.color,
    start_at:
      announcement.data?.startAt && announcement.data?.startAt !== null
        ? new Date(announcement.data?.startAt)
        : undefined,
    end_at:
      announcement.data?.endAt && announcement.data?.endAt !== null
        ? new Date(announcement.data?.endAt)
        : undefined,
    enabled: announcement.data?.enabled,
    announcement_type: announcement.data?.announcementType,
    icon: announcement.data?.icon,
    icon_color: announcement.data?.iconColor,
  };

  return payload;
};

interface SetAnnouncements {
  type: typeof SET_ANNOUNCEMENTS;
  data: Banner[];
}

interface AllAnnouncements {
  type: typeof ALL_ANNOUNCEMENTS;
  data: Banner[];
}

type ReducerAction = SetAnnouncements | AllAnnouncements;

interface State {
  announcements: Announcement[];
  allAnnouncements: Announcement[];
  addAnnouncement: (announcement: Announcement) => void;
  removeAnnouncement: (id: number) => void;
}

const initialState: State = {
  announcements: [],
  allAnnouncements: [],
  addAnnouncement: () => undefined,
  removeAnnouncement: () => undefined,
};

const reducerFn = (draft: State, action: ReducerAction) => {
  switch (action.type) {
    case SET_ANNOUNCEMENTS:
      const setAnnouncements: Announcement[] = [];
      action.data.forEach((announcement) =>
        setAnnouncements.push({ id: announcement.id, data: announcement }),
      );
      draft.announcements = setAnnouncements;
      break;
    case ALL_ANNOUNCEMENTS:
      const allAnnouncements: Announcement[] = [];
      action.data.forEach((announcement) =>
        allAnnouncements.push({ id: announcement.id, data: announcement }),
      );
      draft.allAnnouncements = allAnnouncements;
      break;
    default:
      throw new Error('Unsupported action type dispatched.');
  }
};

export const AnnouncementsContext = createContext(initialState);
export const useAnnouncements = (): State => useContext(AnnouncementsContext);

interface Props {
  children: JSX.Element;
}

export const AnnouncementsProvider = ({ children }: Props): JSX.Element => {
  const reducer = produce(reducerFn);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { banners, addBanner, removeBanner } = useLegacyBanners();
  const { user }: any = useUsers();

  const setAnnouncements = (announcements: Banner[]) => {
    dispatch({
      type: SET_ANNOUNCEMENTS,
      data: announcements,
    });
  };

  const allAnnouncements = (announcements: Banner[]) => {
    dispatch({
      type: ALL_ANNOUNCEMENTS,
      data: announcements,
    });
  };

  const getAnnouncements = async () => {
    try {
      const payload = await api.get('/v2/messaging/ps-announcements');
      const announcements = payloadToAnnouncements(payload.announcements);
      allAnnouncements(announcements);
    } catch (err) {
      console.error(err);
    }
  };

  const getUserAnnouncements = () => {
    try {
      const payload = user?.ps_announcements;
      const announcements = payloadToAnnouncements(payload);
      setAnnouncements(announcements);
    } catch (err) {
      console.error(err);
    }
  };

  const removeAnnouncement = async (id: number) => {
    try {
      await api.delete(`/v2/messaging/ps-announcements/${id}`);

      await getAnnouncements();
    } catch (err) {
      console.error(err);
    }
  };

  const createAnnouncement = async (announcement: Announcement) => {
    try {
      const payload = announcementToPayload(announcement);
      await api.post('/v2/messaging/ps-announcements', payload);
    } catch (err: any) {
      toast.error(err);
    }
  };

  const updateAnnouncement = async (announcement: Announcement) => {
    try {
      const payload = announcementToPayload(announcement);
      await api.patch(
        `/v2/messaging/ps-announcements/${announcement.id}`,
        payload,
      );
    } catch (err: any) {
      toast.error(err);
    }
  };

  const addAnnouncement = async ({ id, data }: Announcement) => {
    if (state.allAnnouncements.some((announcement) => announcement.id === id)) {
      await updateAnnouncement({ id, data });
    } else {
      await createAnnouncement({ id, data });
    }

    getAnnouncements();
  };

  useEffect(() => {
    getAnnouncements();
    getUserAnnouncements();
  }, []);

  useEffect(() => {
    state.announcements.forEach((announcement) => {
      const stringId = String(announcement.data.id);
      if (banners.find((value) => value.id === stringId)) {
        removeBanner(stringId);
      }

      const bannerColor =
        announcement.data.color && announcement.data.color !== ''
          ? announcement.data.color
          : DEFAULT_COLOR;

      const ctaHandler = announcement.data.ctaUrl
        ? () => {
            const newWindow = window.open(
              announcement.data.ctaUrl,
              '_blank',
              'noopener,noreferrer',
            );
            if (newWindow) newWindow.opener = null;
          }
        : undefined;

      addBanner({
        id: stringId,
        data: {
          type: 'custom',
          announcementType: announcement.data.announcementType,
          closeable: true,
          color: bannerColor,
          message: announcement.data.message,
          primaryAction:
            announcement.data.ctaText && ctaHandler
              ? {
                  text: announcement.data.ctaText,
                  onClick: ctaHandler,
                }
              : undefined,
          content:
            announcement.data.announcementType ===
            AnnouncementType.MESSAGE_PLANNER ? (
              <MessagePlannerBanner
                onClose={() => removeBanner(stringId)}
                bannerColor={bannerColor}
                ctaMessage={announcement.data.ctaText}
                ctaHandler={ctaHandler}
                body={announcement.data.message}
                icon={announcement.data.icon}
                iconColor={announcement.data.iconColor}
              />
            ) : (
              <AnnouncementBanner
                bodyText={announcement.data.message}
                color={bannerColor}
                primaryAction={
                  announcement.data.ctaText && ctaHandler
                    ? {
                        text: announcement.data.ctaText,
                        onClick: ctaHandler,
                      }
                    : undefined
                }
              />
            ),
        },
      });
    });
  }, [state.announcements]);

  return (
    <AnnouncementsContext.Provider
      value={{
        ...state,
        addAnnouncement,
        removeAnnouncement,
      }}
    >
      {children}
    </AnnouncementsContext.Provider>
  );
};

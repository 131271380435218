/**
 *
 * @param importFn - The function that imports the chunk (`import('./chunk')`)
 * @param name - The name of the chunk (e.g. 'chunk'), which is used for tracking reload attempts
 *
 * This function is used to lazy load a chunk and if it fails to load, it will attempt to reload the page.
 * Without the reload attempt, chunks would become invalid after deploys, and would need to be manually refreshed.
 *
 */

import { ComponentType } from 'react';

type ModuleImport = { default: ComponentType<any> };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const lazyLoadWithChunkRefresh = (
  importFn: () => Promise<ModuleImport>,
  name: string,
) => {
  const key = `chunk-${name}-reload-attempted`;

  return new Promise<ModuleImport>((resolve, reject) => {
    importFn()
      .then((module) => {
        window.localStorage.removeItem(key);
        resolve(module);
      })
      .catch((error: unknown) => {
        const hasReloadedBeenAttempted = window.localStorage.getItem(key);

        if (!hasReloadedBeenAttempted) {
          window.localStorage.setItem(key, 'true');
          window.location.reload();
        } else {
          reject(error);
        }
      });
  });
};

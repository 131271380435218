// Please do not use process.env for environment variables.
//
// There should be very few cases where you use `process.env` (for example,
// NODE_ENV).
//
// In order to keep the application immutable, we should declare the
// environment variables in public/index.html on the `window` object, then set
// them to constants here.
//
// In order to use environment variables, make sure that the environment
// variable is named 'REACT_APP_<ENV_VAR>' so that `create-react-app` can
// interpolate the env var into `public/index.html`.
//
// For more information about immutable webapps, check out:
//
// https://immutablewebapps.org/#-workflow
export const { NODE_ENV } = process.env;

window.env = window.env || {};

export const PUBLIC_URL =
  window.env.REACT_APP_PUBLIC_URL || process.env.PUBLIC_URL; // We should just migrate this one over
export const API_HOST = window.env.REACT_APP_API_HOST;
export const INTERNAL_API_HOST = window.env.REACT_APP_INTERNAL_API_HOST;
export const SDK_HOST = window.env.REACT_APP_SDK_HOST;
export const SENTRY_RELEASE = window.env.REACT_APP_SENTRY_RELEASE;
export const SENTRY_SAMPLE_RATE = window.env.REACT_APP_SENTRY_SAMPLE_RATE;
export const STRIPE_PUBLIC_KEY = window.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const WEBSOCKET_HOST = window.env.REACT_APP_WEBSOCKET_HOST;

import { useState } from 'react';

const useFlipFlop = (values) => {
  const [store, changeStore] = useState({
    on: [],
    off: values,
  });

  const toggleVal = (val) => {
    const { on, off } = store;

    if (on.includes(val)) {
      changeStore({
        on: [],
        off: values,
      });
    } else {
      const newOff = off.filter((id) => id !== val);
      changeStore({
        on: [val],
        off: newOff,
      });
    }
  };
  return { on: store.on, off: store.off, toggleVal };
};

export default useFlipFlop;

import {
  ActionTypesNames,
  ActionWithParamsTypesNames,
  AutomationFlowStatuses,
  CampaignFlowStatuses,
  FlowOrigins,
  FlowTemplateTypes,
  FlowTypes,
  StepTypesNames,
  StepWithParamsTypesNames,
  TextToBuySidebarNames,
  WaitActionCategories,
} from 'components/flowBuilder/constants';
import type {
  Action,
  ActionsAnalytics,
  ActionType,
  ActionTypesName,
  ActionWithParams,
  ActionWithParamsTypesName,
  AnySplitAction,
  AutomationFlowStatus,
  AutomationTriggerStep,
  BaseActionWithParams,
  BaseStep,
  BaseStepWithParams,
  BaseWaitActionParams,
  BinaryCondition,
  CampaignFlowStatus,
  CampaignTriggerStep,
  CharacterCodes,
  Charset,
  Charsets,
  ClonedFlowResponse,
  Condition,
  DraftFlow,
  DraggableItem,
  EndAction,
  EventSplitBranch,
  Flow,
  FlowAnalytics,
  FlowOrigin,
  FlowStatus,
  FlowTemplate,
  FlowTemplateType,
  FlowType,
  RelativeWeekWaitAction,
  RelativeWeekWaitActionParams,
  SendMessageAction,
  SendMessageActionParams,
  SidebarType,
  SpecificDateTimeWaitAction,
  SpecificDateTimeWaitActionParams,
  SplitAction,
  SplitActionBranch,
  SplitActionParams,
  Step,
  StepNode,
  StepNodeData,
  StepParams,
  StepWithForm,
  StepWithParams,
  StepWithParamsTypesName,
  SubscriberEvent,
  TextToBuyAction,
  TextToBuyActionParams,
  TextToBuySidebarType,
  TimeDeltaWaitAction,
  TimeDeltaWaitActionParams,
  TriggerEventSplitAction,
  TriggerEventSplitActionParams,
  TriggerStep,
  UpdateSubscriberAction,
  UpdateSubscriberActionParams,
  WaitAction,
  WaitActionParams,
  WaitForEventSplitAction,
  WaitForEventSplitActionParams,
} from 'components/flowBuilder/types';
import { isDynamicAction } from 'components/flowBuilder/types/dynamicActions/typeGuards';
import { isResourceTemplate } from 'hooks/useResourceTemplates';
import { isNode } from 'reactflow';
import { DynamicActionType } from './dynamicActions';

export const isAutomationFlowStatus = (
  status: unknown,
): status is AutomationFlowStatus => {
  const statusValue = status as AutomationFlowStatus;
  return Object.values(AutomationFlowStatuses).includes(statusValue);
};

export const isCampaignFlowStatus = (
  status: unknown,
): status is CampaignFlowStatus => {
  const statusValue = status as CampaignFlowStatus;
  return Object.values(CampaignFlowStatuses).includes(statusValue);
};

export const isFlowStatus = (status: unknown): status is FlowStatus => {
  return isAutomationFlowStatus(status) || isCampaignFlowStatus(status);
};

export const isFlowType = (type: unknown): type is FlowType => {
  const typeValue = type as FlowType;
  return Object.values(FlowTypes).includes(typeValue);
};

export const isFlowOrigin = (origin: unknown): origin is FlowOrigin => {
  const originValue = origin as FlowOrigin;
  return Object.values(FlowOrigins).includes(originValue);
};

export const isSubscriberEvent = (event: unknown): event is SubscriberEvent => {
  const eventValue = event as SubscriberEvent;

  if (!eventValue || typeof eventValue.description !== 'string') return false;
  if (!eventValue.name || typeof eventValue.name !== 'string') return false;
  if (!eventValue.type || typeof eventValue.type !== 'string') return false;
  if (!eventValue.source || typeof eventValue.source !== 'string') return false;

  return true;
};

const isCondition = (condition: unknown): condition is Condition => {
  const conditionValue = condition as Condition;

  if (!conditionValue.variable || typeof conditionValue.variable !== 'string')
    return false;
  if (!conditionValue.operator || typeof conditionValue.operator !== 'string')
    return false;
  if (typeof conditionValue.value !== 'number') return false;

  return true;
};

export const isBinaryCondition = (
  condition: unknown,
): condition is BinaryCondition => {
  const conditionValue = condition as BinaryCondition;
  if (typeof conditionValue.type !== 'string') return false;
  if (!conditionValue.variable || typeof conditionValue.variable !== 'string')
    return false;
  if (!conditionValue.operator || typeof conditionValue.operator !== 'string')
    return false;
  if (
    typeof conditionValue.value !== 'number' &&
    typeof conditionValue.value !== 'string' &&
    typeof conditionValue.value !== 'boolean' &&
    !Array.isArray(conditionValue.value)
  )
    return false;

  return true;
};

export const isElseCondition = (condition: unknown): boolean => {
  const conditionValue = condition as BinaryCondition;
  if (typeof conditionValue.type !== 'string') return false;
  if (typeof conditionValue.variable !== 'string' || !!conditionValue.variable)
    return false;
  if (typeof conditionValue.operator !== 'string' || !!conditionValue.operator)
    return false;
  if (conditionValue.value) return false;

  return true;
};

/*
  Used to determine if a condition has been fully configured, which is to say
  that it has a valid type, variable, operator, and value.
*/
export const isConditionCompleted = (condition: unknown): boolean => {
  const conditionValue = condition as BinaryCondition;
  if (typeof conditionValue.type !== 'string' || !conditionValue.type)
    return false;
  if (typeof conditionValue.variable !== 'string' || !conditionValue.variable)
    return false;
  if (typeof conditionValue.operator !== 'string' || !conditionValue.operator)
    return false;
  if (conditionValue.value !== 0 && !conditionValue.value) return false;

  return true;
};

// TODO: Deprecate once we can use isBranch for A/B Split.
const isSplitActionBranch = (branch: unknown): branch is SplitActionBranch => {
  const branchValue = branch as SplitActionBranch;

  if (typeof branchValue.action_guid !== 'string') return false;
  if (branchValue.conditions.some((condition) => !isCondition(condition)))
    return false;

  return true;
};

export const isEventSplitBranch = (
  branch: unknown,
): branch is EventSplitBranch => {
  const branchValue = branch as EventSplitBranch;
  if (typeof branchValue.action_guid !== 'string') return false;
  if (
    !branchValue.conditions.every(
      (condition) => isBinaryCondition(condition) || isElseCondition(condition),
    )
  )
    return false;

  return true;
};

export const isEventBranchCompleted = (
  branch: unknown,
): branch is EventSplitBranch => {
  const branchValue = branch as EventSplitBranch;
  if (typeof branchValue.action_guid !== 'string') return false;
  return branchValue.conditions.every((condition) =>
    isConditionCompleted(condition),
  );
};

export const isBaseStep = (step: unknown): step is BaseStep => {
  const stepValue = step as BaseStep;

  if (!stepValue || typeof stepValue !== 'object') return false;
  if (!stepValue.guid || typeof stepValue.guid !== 'string') return false;
  if (!stepValue.type || typeof stepValue.type !== 'string') return false;
  if (!stepValue.next || !Array.isArray(stepValue.next)) return false;
  if (stepValue.next.some((guid) => typeof guid !== 'string')) return false;

  return true;
};

const isStepWithParamsTypesName = (
  name: unknown,
): name is StepWithParamsTypesName => {
  const nameValue = name as StepWithParamsTypesName;
  return Object.values(StepWithParamsTypesNames).includes(nameValue);
};

const isActionWithParamsTypesName = (
  name: unknown,
): name is ActionWithParamsTypesName => {
  const nameValue = name as ActionWithParamsTypesName;
  return Object.values(ActionWithParamsTypesNames).includes(nameValue);
};

export const isStepParams = (params: unknown): params is StepParams => {
  return !!params && typeof params === 'object';
};

export const isStepWithParams = (step: unknown): step is StepWithParams => {
  const stepValue = step as BaseStepWithParams;

  if (!isBaseStep(stepValue)) return false;
  if (!isStepWithParamsTypesName(stepValue.type)) return false;
  if (!isStepParams(stepValue.params)) return false;

  return true;
};

export const isAutomationTriggerStep = (
  step: unknown,
): step is AutomationTriggerStep => {
  const stepValue = step as AutomationTriggerStep;

  if (!isStepWithParams(stepValue)) return false;
  if (stepValue.type !== StepTypesNames.AUTOMATION_TRIGGER) return false;
  if (
    !stepValue.params.triggerEvents ||
    !Array.isArray(stepValue.params.triggerEvents)
  )
    return false;

  if (
    !stepValue.params.cancelEvents ||
    !Array.isArray(stepValue.params.cancelEvents)
  )
    return false;

  return true;
};

export const isCampaignTriggerStep = (
  step: unknown,
): step is CampaignTriggerStep => {
  const stepValue = step as CampaignTriggerStep;

  if (!isStepWithParams(stepValue)) return false;
  if (stepValue.type !== StepTypesNames.CAMPAIGN_TRIGGER) return false;
  if (
    !stepValue.params.segmentIds ||
    !Array.isArray(stepValue.params.segmentIds)
  )
    return false;

  if (
    stepValue.params.segmentIds.some(
      (segmentId) => typeof segmentId !== 'number',
    )
  )
    return false;

  if (
    !stepValue.params.cancelEvents ||
    !Array.isArray(stepValue.params.cancelEvents)
  )
    return false;

  return true;
};

export const isTriggerStep = (step: unknown): step is TriggerStep => {
  return isAutomationTriggerStep(step) || isCampaignTriggerStep(step);
};

export const isActionTypesName = (name: unknown): name is ActionTypesName => {
  const nameValue = name as ActionTypesName;
  return Object.values(ActionTypesNames).includes(nameValue);
};

const isBaseActionWithParams = (
  action: unknown,
): action is BaseActionWithParams => {
  const actionValue = action as BaseActionWithParams;

  if (!isBaseStep(actionValue)) return false;
  if (!isActionWithParamsTypesName(actionValue.type)) return false;
  if (!actionValue.params || typeof actionValue.params !== 'object')
    return false;

  return true;
};

export const isSendMessageActionParams = (
  params: unknown,
): params is SendMessageActionParams => {
  const paramsValue = params as SendMessageActionParams;

  if (!paramsValue || typeof paramsValue !== 'object') return false;
  if (typeof paramsValue.content !== 'string') return false;
  if (
    typeof paramsValue.mediaUrl !== 'string' &&
    paramsValue.mediaUrl !== null &&
    typeof paramsValue.mediaUrl !== 'undefined'
  )
    return false;

  return true;
};

export const isTimeDeltaWaitActionParams = (
  params: WaitActionParams,
): params is TimeDeltaWaitActionParams => {
  return params.category === WaitActionCategories.TIMEDELTA;
};

export const isNoCategoryWaitActionParams = (
  params: WaitActionParams,
): params is BaseWaitActionParams => {
  return params.category === null;
};

export const isSpecificDateTimeWaitActionParams = (
  params: WaitActionParams,
): params is SpecificDateTimeWaitActionParams => {
  return params.category === WaitActionCategories.SPECIFIC_DATETIME;
};

export const isRelativeWeekWaitActionParams = (
  params: WaitActionParams,
): params is RelativeWeekWaitActionParams => {
  return params.category === WaitActionCategories.RELATIVE_WEEK;
};

export const isTimeDeltaWaitAction = (
  action: WaitAction,
): action is TimeDeltaWaitAction => {
  return isTimeDeltaWaitActionParams(action.params);
};

export const isSpecificDateTimeWaitAction = (
  action: WaitAction,
): action is SpecificDateTimeWaitAction => {
  return isSpecificDateTimeWaitActionParams(action.params);
};

export const isRelativeWeekWaitAction = (
  action: WaitAction,
): action is RelativeWeekWaitAction => {
  return isRelativeWeekWaitActionParams(action.params);
};

export const isWaitActionParams = (
  params: unknown,
): params is WaitActionParams => {
  const paramsValue = params as WaitActionParams;

  const isLegacyWaitActionParams =
    !paramsValue.category && typeof paramsValue.duration === 'string';

  const isNoCategory = isNoCategoryWaitActionParams(paramsValue);
  const isCategoryTimeDelta = isTimeDeltaWaitActionParams(paramsValue);
  const isCategorySpecificDateTime =
    isSpecificDateTimeWaitActionParams(paramsValue);
  const isCategoryRelativeWeek = isRelativeWeekWaitActionParams(paramsValue);

  if (!paramsValue || typeof paramsValue !== 'object') return false;
  if (
    !(
      isNoCategory ||
      isLegacyWaitActionParams ||
      isCategoryTimeDelta ||
      isCategorySpecificDateTime ||
      isCategoryRelativeWeek
    )
  )
    return false;

  return true;
};

export const isSplitActionParams = (
  params: unknown,
): params is SplitActionParams => {
  const paramsValue = params as SplitActionParams;

  if (!paramsValue || typeof paramsValue !== 'object') return false;
  if (paramsValue.cases.some((branch) => !isSplitActionBranch(branch)))
    return false;

  return true;
};

export const isUpdateSubscriberActionParams = (
  params: unknown,
): params is UpdateSubscriberActionParams => {
  const paramsValue = params as UpdateSubscriberActionParams;

  if (!paramsValue || typeof paramsValue !== 'object') return false;
  if (!Array.isArray(paramsValue.addTags)) return false;
  if (!Array.isArray(paramsValue.removeTags)) return false;

  return true;
};

export const isTextToBuyActionParams = (
  params: unknown,
): params is TextToBuyActionParams => {
  const paramsValue = params as TextToBuyActionParams;

  if (!paramsValue || typeof paramsValue !== 'object') return false;
  if (typeof paramsValue.content !== 'string') return false;
  if (
    typeof paramsValue.mediaUrl !== 'string' &&
    paramsValue.mediaUrl !== null &&
    typeof paramsValue.mediaUrl !== 'undefined'
  )
    return false;

  if (!Array.isArray(paramsValue.products)) return false;

  return true;
};

export const isWaitForEventSplitActionParams = (
  params: unknown,
): params is WaitForEventSplitActionParams => {
  const paramsValue = params as WaitForEventSplitActionParams;

  if (!paramsValue || typeof paramsValue !== 'object') return false;
  if (paramsValue.cases.some((branch) => !isEventSplitBranch(branch)))
    return false;

  return true;
};

export const isTriggerEventSplitActionParams = (
  params: unknown,
): params is TriggerEventSplitActionParams => {
  const paramsValue = params as TriggerEventSplitActionParams;

  if (!paramsValue || typeof paramsValue !== 'object') return false;
  if (paramsValue.cases.some((branch) => !isEventSplitBranch(branch)))
    return false;

  return true;
};

export const isSendMessageAction = (
  action: unknown,
): action is SendMessageAction => {
  const actionValue = action as SendMessageAction;

  if (!isBaseActionWithParams(actionValue)) return false;
  if (actionValue.type !== ActionTypesNames.SEND_MESSAGE) return false;
  if (!isSendMessageActionParams(actionValue.params)) return false;

  return true;
};

export const isTextToBuyAction = (
  action: unknown,
): action is TextToBuyAction => {
  const actionValue = action as TextToBuyAction;

  if (!isBaseActionWithParams(actionValue)) return false;
  if (actionValue.type !== ActionTypesNames.TEXT_TO_BUY) return false;
  if (!isTextToBuyActionParams(actionValue.params)) return false;

  return true;
};

export const isUpdateSubscriberAction = (
  action: unknown,
): action is UpdateSubscriberAction => {
  const actionValue = action as UpdateSubscriberAction;

  if (!isBaseActionWithParams(actionValue)) return false;
  if (actionValue.type !== ActionTypesNames.UPDATE_SUBSCRIBER) return false;
  if (!isUpdateSubscriberActionParams(actionValue.params)) return false;

  return true;
};

export const isWaitAction = (action: unknown): action is WaitAction => {
  const actionValue = action as WaitAction;

  if (!isBaseActionWithParams(actionValue)) return false;
  if (actionValue.type !== ActionTypesNames.WAIT) return false;
  if (!isWaitActionParams(actionValue.params)) return false;

  return true;
};

export const isSplitAction = (action: unknown): action is SplitAction => {
  const actionValue = action as SplitAction;

  if (!isBaseActionWithParams(actionValue)) return false;
  if (actionValue.type !== ActionTypesNames.SPLIT) return false;
  if (!isSplitActionParams(actionValue.params)) return false;

  return true;
};

export const isWaitForEventSplitAction = (
  action: unknown,
): action is WaitForEventSplitAction => {
  const actionValue = action as WaitForEventSplitAction;

  if (!isBaseActionWithParams(actionValue)) return false;
  if (actionValue.type !== ActionTypesNames.WAIT_FOR_EVENT_SPLIT) return false;
  if (!isWaitForEventSplitActionParams(actionValue.params)) return false;

  return true;
};

export const isTriggerEventSplitAction = (
  action: unknown,
): action is TriggerEventSplitAction => {
  const actionValue = action as TriggerEventSplitAction;

  if (!isBaseActionWithParams(actionValue)) return false;
  if (actionValue.type !== ActionTypesNames.TRIGGER_EVENT_SPLIT) return false;
  if (!isTriggerEventSplitActionParams(actionValue.params)) return false;

  return true;
};

/*
  All split actions should implement an interface which includes `cases`,
  which is an array of objects that each contain `action_guid`.

  TODO - Enforce the above via types
*/
export const isAnySplitAction = (action: unknown): action is AnySplitAction => {
  return (
    isSplitAction(action) ||
    isWaitForEventSplitAction(action) ||
    isTriggerEventSplitAction(action) ||
    isTextToBuyAction(action)
  );
};

export const isAnySplitActionType = (
  actionType: typeof StepTypesNames[keyof typeof StepTypesNames],
): boolean =>
  actionType === ActionTypesNames.SPLIT ||
  actionType === ActionTypesNames.WAIT_FOR_EVENT_SPLIT ||
  actionType === ActionTypesNames.TRIGGER_EVENT_SPLIT ||
  actionType === ActionTypesNames.TEXT_TO_BUY;

export const isEndAction = (action: unknown): action is EndAction => {
  const actionValue = action as EndAction;

  if (!isBaseActionWithParams(actionValue)) return false;
  if (actionValue.type !== ActionTypesNames.END) return false;

  return true;
};

export const isActionWithParams = (
  action: unknown,
): action is ActionWithParams => {
  return (
    isSendMessageAction(action) ||
    isWaitAction(action) ||
    isAnySplitAction(action) ||
    isUpdateSubscriberAction(action) ||
    isTextToBuyAction(action) ||
    isEndAction(action)
  );
};

export const isAction = (action: unknown): action is Action => {
  return isActionWithParams(action) || isDynamicAction(action);
};

export const isStep = (step: unknown): step is Step => {
  return isStepWithParams(step) || isDynamicAction(step);
};

export const isDraggableItem = (item: unknown): item is DraggableItem => {
  const itemValue = item as DraggableItem;

  if (!itemValue || typeof itemValue !== 'object') return false;
  if (!itemValue.elementRef || typeof itemValue.elementRef !== 'object')
    return false;
  if (
    itemValue.elementRef.current !== null &&
    !(itemValue.elementRef.current instanceof HTMLDivElement)
  )
    return false;
  if (
    typeof itemValue.guid !== 'undefined' &&
    typeof itemValue.guid !== 'string'
  )
    return false;

  return true;
};

export const isDraftFlow = (flow: unknown): flow is DraftFlow => {
  const flowValue = flow as DraftFlow;

  if (!flowValue || typeof flowValue !== 'object') return false;
  if (!flowValue.name || typeof flowValue.name !== 'string') return false;
  if (typeof flowValue.description !== 'string') return false;
  if (!flowValue.start || typeof flowValue.start !== 'string') return false;
  if (!flowValue.segmentIds || !Array.isArray(flowValue.segmentIds))
    return false;
  if (flowValue.segmentIds.some((segmentId) => typeof segmentId !== 'number'))
    return false;
  if (!isFlowStatus(flowValue.status)) return false;
  // if (flowValue.type !== null && !Object.values(FlowTypes).includes(flowValue.type)) return false; // TODO: Uncomment when the API includes this (or a similar) property
  if (
    flowValue.templateId !== undefined &&
    flowValue.templateId !== null &&
    typeof flowValue.templateId !== 'number'
  )
    return false;
  if (
    !flowValue.actions ||
    !Array.isArray(flowValue.actions) ||
    flowValue.actions.length === 0
  )
    return false;
  if (flowValue.actions.some((action) => !isAction(action))) return false;
  if (
    flowValue.scheduledFor !== undefined &&
    flowValue.scheduledFor !== null &&
    typeof flowValue.scheduledFor !== 'string'
  )
    return false;
  if (!['boolean', 'undefined'].includes(typeof flowValue.safeSend)) {
    return false;
  }

  return true;
};

export const isFlow = (flow: unknown): flow is Flow => {
  const flowValue = flow as Flow;

  if (!isDraftFlow(flow)) return false;
  if (!flowValue.guid || typeof flowValue.guid !== 'string') return false;

  return true;
};

const isStepNodeData = (data: unknown): data is StepNodeData => {
  const dataValue = data as StepNodeData;

  if (!dataValue || typeof dataValue !== 'object') return false;
  if (!isStep(dataValue.activeStep) && dataValue.activeStep !== null)
    return false;
  if (!isStep(dataValue.step)) return false;

  return true;
};

export const isStepNode = (node: unknown): node is StepNode => {
  const nodeValue = node as StepNode;

  if (!nodeValue || typeof nodeValue !== 'object') return false;
  if (!isNode(nodeValue)) return false;
  if (!isStepNodeData(nodeValue.data)) return false;

  return true;
};

const isCharacterCodes = (
  characterCodes: unknown,
): characterCodes is CharacterCodes => {
  const characterCodesValue = characterCodes as CharacterCodes;

  if (!characterCodesValue || typeof characterCodesValue !== 'object')
    return false;
  if (Array.isArray(characterCodesValue.decimal)) {
    if (
      characterCodesValue.decimal.some(
        (decimalCode) => typeof decimalCode !== 'number',
      )
    )
      return false;
  } else if (typeof characterCodesValue.decimal !== 'number') {
    return false;
  }

  return true;
};

const isCharset = (charset: unknown): charset is Charset => {
  const charsetValue = charset as Charset;

  if (!charsetValue || typeof charsetValue !== 'object') return false;
  if (
    Object.values(charsetValue).some(
      (characterCodes) => !isCharacterCodes(characterCodes),
    )
  )
    return false;

  return true;
};

export const isCharsets = (charsets: unknown): charsets is Charsets => {
  const charsetsValue = charsets as Charsets;

  if (!charsetsValue || typeof charsetsValue !== 'object') return false;
  if (Object.values(charsetsValue).some((charset) => !isCharset(charset)))
    return false;

  return true;
};

export const isFlowAnalytics = (
  flowsAnalytics: unknown,
): flowsAnalytics is FlowAnalytics => {
  const flowAnalyticsValue = flowsAnalytics as FlowAnalytics;

  if (!flowAnalyticsValue || typeof flowAnalyticsValue !== 'object')
    return false;
  if (typeof flowAnalyticsValue.total_sent !== 'number') return false;
  if (typeof flowAnalyticsValue.credits_used !== 'number') return false;
  if (typeof flowAnalyticsValue.total_unsubs !== 'number') return false;
  if (typeof flowAnalyticsValue.total_subscribers !== 'number') return false;
  if (
    typeof flowAnalyticsValue.queued_subscribers !== 'number' &&
    flowAnalyticsValue.queued_subscribers !== null &&
    typeof flowAnalyticsValue.queued_subscribers !== 'undefined'
  )
    return false;
  if (typeof flowAnalyticsValue.total_clicks !== 'number') return false;
  if (typeof flowAnalyticsValue.unique_clicks !== 'number') return false;
  if (typeof flowAnalyticsValue.orders !== 'number') return false;
  if (typeof flowAnalyticsValue.revenue !== 'number') return false;
  if (
    typeof flowAnalyticsValue.conversion_rate !== 'number' &&
    flowAnalyticsValue.conversion_rate !== null &&
    typeof flowAnalyticsValue.conversion_rate !== 'undefined'
  )
    return false;
  if (
    typeof flowAnalyticsValue.unsubscribe_rate !== 'number' &&
    typeof flowAnalyticsValue.unsubscribe_rate !== 'undefined'
  )
    return false;
  if (
    typeof flowAnalyticsValue.ctr !== 'number' &&
    typeof flowAnalyticsValue.ctr !== 'undefined'
  )
    return false;
  if (
    typeof flowAnalyticsValue.epm !== 'number' &&
    typeof flowAnalyticsValue.epm !== 'undefined'
  )
    return false;
  if (
    typeof flowAnalyticsValue.unique_subscribers !== 'number' &&
    typeof flowAnalyticsValue.unique_subscribers !== 'undefined'
  )
    return false;
  if (
    typeof flowAnalyticsValue.valid_message_statistics !== 'boolean' &&
    typeof flowAnalyticsValue.valid_message_statistics !== 'undefined'
  )
    return false;
  if (
    typeof flowAnalyticsValue.last_updated !== 'string' &&
    flowAnalyticsValue.last_updated !== null &&
    typeof flowAnalyticsValue.last_updated !== 'undefined'
  )
    return false;

  return true;
};

const isActionAnalytics = (
  actionAnalytics: unknown,
): actionAnalytics is FlowAnalytics => {
  const actionAnalyticsValue = actionAnalytics as FlowAnalytics;

  if (!actionAnalyticsValue || typeof actionAnalyticsValue !== 'object') {
    return false;
  }
  if (typeof actionAnalyticsValue.total_sent !== 'number') {
    return false;
  }
  if (typeof actionAnalyticsValue.total_clicks !== 'number') {
    return false;
  }
  if (typeof actionAnalyticsValue.orders !== 'number') {
    return false;
  }
  if (typeof actionAnalyticsValue.revenue !== 'number') {
    return false;
  }
  if (typeof actionAnalyticsValue.total_unsubs !== 'number') {
    return false;
  }
  if (typeof actionAnalyticsValue.unique_clicks !== 'number') {
    return false;
  }
  if (typeof actionAnalyticsValue.credits_used !== 'number') {
    return false;
  }

  return true;
};

export const isActionsAnalytics = (
  actionsAnalytics: unknown,
): actionsAnalytics is ActionsAnalytics => {
  const actionsAnalyticsValue = actionsAnalytics as ActionsAnalytics;
  if (
    Object.values(actionsAnalyticsValue).some(
      (actionAnalytics) => !isActionAnalytics(actionAnalytics),
    )
  )
    return false;
  return true;
};

export const isFlowTemplateType = (type: unknown): type is FlowTemplateType => {
  const typeValue = type as FlowTemplateType;
  return Object.values(FlowTemplateTypes).includes(typeValue);
};

export const isFlowTemplate = (template: unknown): template is FlowTemplate => {
  const templateValue = template as FlowTemplate;

  if (!isResourceTemplate(templateValue)) return false;
  if (!isFlow(templateValue.data)) return false;
  if (!isFlowTemplateType(templateValue.type)) return false;

  return true;
};

export const isTextToBuyComputedStepType = (type: string): boolean => {
  return type === StepTypesNames.TEXT_TO_BUY_BRANCH_INFO;
};

export const isComputedStepType = (type: string): boolean => {
  const computedStepTypes: string[] = [
    StepTypesNames.BRANCH_INFO,
    StepTypesNames.RECONVENING_BRANCHES,
  ];

  return computedStepTypes.includes(type) || isTextToBuyComputedStepType(type);
};

export const isComputedStep = (step: Step): boolean => {
  return isComputedStepType(step.type);
};

export const isStepWithForm = (step: unknown): step is StepWithForm => {
  const stepValue = step as StepWithForm;

  if (!isStepWithParams(stepValue) && !isDynamicAction(stepValue)) return false;
  if (isComputedStep(stepValue)) return false;
  if (isEndAction(stepValue)) return false;

  return true;
};

export const isClonedFlowResponse = (
  response: unknown,
): response is ClonedFlowResponse => {
  const cloned = response as ClonedFlowResponse;

  if (typeof cloned.guid !== 'string') {
    return false;
  }
  return true;
};

export const isTextToBuySidebarType = (
  type: SidebarType | null,
): type is TextToBuySidebarType => {
  return (
    type === TextToBuySidebarNames.T2B_PREVIEW_PURCHASED ||
    type === TextToBuySidebarNames.T2B_PREVIEW_CANCELLED ||
    type === TextToBuySidebarNames.T2B_PREVIEW_DIDNT_PURCHASE ||
    type === TextToBuySidebarNames.T2B_PREVIEW_TIMED_OUT
  );
};

export const isActionType = (
  actionSchema: unknown,
): actionSchema is ActionType => {
  const cloned = actionSchema as ActionType;

  return (
    !!cloned.helpText &&
    !!cloned.category &&
    !!cloned.iconName &&
    !!cloned.label &&
    !!cloned.type
  );
};

export const isDynamicActionType = (
  actionSchema: unknown,
): actionSchema is DynamicActionType => {
  const cloned = actionSchema as DynamicActionType;

  return (
    isActionType(actionSchema) &&
    !!cloned.description &&
    !!Array.isArray(cloned.fields)
  );
};

import { Button, IconSet, ModalHeader } from '@postscript/components';
import {
  PaymentMethodSteps as PaymentMethodStepsType,
  usePaymentMethods,
} from 'components/billing/context/paymentMethods';
import PaymentMethodACHAccountholderForm from './PaymentMethodACHAccountholderForm';
import PaymentMethodACHMandate from './PaymentMethodACHMandate';
import PaymentMethodCardForm from './PaymentMethodCardForm';
import PaymentMethodDeleteConfirmation from './PaymentMethodDeleteConfirmation';
import PaymentMethodManagement from './PaymentMethodManagement';
import PaymentMethodTypeChoice from './PaymentMethodTypeChoice';

interface Props {
  close: () => void;
}

export default function PaymentMethodSteps({ close }: Props): JSX.Element {
  const { steps, step, updateStep } = usePaymentMethods();

  return (
    <>
      {step !== 'management' && (
        <Button
          variant="text"
          size="small"
          icon={IconSet.ChevronLeft}
          onClick={() =>
            updateStep(steps?.[step]?.previousStep as PaymentMethodStepsType)
          }
          data-cy="back-button"
        >
          Back
        </Button>
      )}
      <ModalHeader onCancel={close}>{steps?.[step]?.heading}</ModalHeader>
      {(() => {
        switch (step) {
          case 'management':
            return <PaymentMethodManagement closeModal={close} />;
          case 'typeChoice':
            return <PaymentMethodTypeChoice />;
          case 'stripeCard':
            return <PaymentMethodCardForm />;
          case 'stripeAch':
            return <PaymentMethodACHAccountholderForm />;
          case 'stripeAchMandate':
            return <PaymentMethodACHMandate />;
          case 'deleteConfirmation':
            return <PaymentMethodDeleteConfirmation />;
          default:
            return null;
        }
      })()}
    </>
  );
}

interface NavLogoProps {
  height?: string;
  width?: string;
  isSales?: boolean;
}
const NavLogo = ({
  height = '42',
  width = '135',
  isSales = false,
}: NavLogoProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Postscript</title>
      <path
        d="M0 39.0751C0 20.6549 0 11.4448 5.72242 5.72242C11.4448 0 20.6549 0 39.0751 0H113.022C124.62 0 134.022 9.40202 134.022 21C134.022 32.598 124.62 42 113.022 42H0V39.0751Z"
        fill={isSales ? 'var(--green-core)' : 'var(--logo-bkg-color)'}
      />
      <path
        d="M11.6484 12.9779H18.0586C21.8637 12.9779 24.1509 14.7664 24.1509 18.3687C24.1509 21.6721 22.2947 23.6242 18.3983 23.6242H15.3624V27.8824H11.6484V12.9779ZM18.2473 21.1325C19.8109 21.1325 20.6714 20.2941 20.6714 18.5496C20.6714 16.8507 19.7197 15.899 17.9532 15.899H15.3624V21.1325H18.2473Z"
        fill="var(--logo-text-color)"
      />
      <path
        d="M24.5592 22.3098C24.5592 18.6635 26.9596 16.5116 30.6515 16.5116C34.3435 16.5116 36.7455 18.6635 36.7455 22.3098C36.7455 25.9561 34.3435 28.064 30.6515 28.064C26.9596 28.064 24.5592 25.9341 24.5592 22.3098ZM33.3021 22.3098C33.3021 20.3168 32.3284 19.1386 30.6515 19.1386C28.9747 19.1386 27.9994 20.3168 27.9994 22.3098C27.9994 24.3028 28.9747 25.437 30.65 25.437C32.3253 25.437 33.3021 24.2808 33.3021 22.3098Z"
        fill="var(--logo-text-color)"
      />
      <path
        d="M37.5846 24.1235H40.6646C40.6646 25.1664 41.1853 25.846 43.1563 25.846C44.9448 25.846 45.5803 25.3253 45.5803 24.5089C45.5803 24.1015 45.3758 23.7837 44.7938 23.6485C43.9329 23.4896 43.0629 23.3846 42.1889 23.3338C40.5797 23.1986 39.3338 22.95 38.6543 22.4734C38.3158 22.2163 38.043 21.8827 37.8582 21.4999C37.6734 21.1172 37.5818 20.6961 37.5909 20.2711C37.5909 17.8487 40.081 16.5116 43.1846 16.5116C46.8545 16.5116 48.5329 17.9163 48.5329 20.5213H45.497C45.4749 19.3431 44.9307 18.7311 43.2318 18.7311C41.6918 18.7311 41.0123 19.2518 41.0123 20.0226C41.0123 20.4945 41.1931 20.861 41.8271 21.0199C42.3934 21.1552 43.3907 21.2464 44.5909 21.3597C45.972 21.495 46.9505 21.6302 47.7842 22.1069C48.1686 22.3347 48.4862 22.6598 48.7049 23.0495C48.9236 23.4392 49.0358 23.8796 49.03 24.3264C49.03 26.686 47.0149 28.064 43.1862 28.064C39.2379 28.0655 37.5846 26.3887 37.5846 24.1235Z"
        fill="var(--logo-text-color)"
      />
      <path
        d="M51.0292 24.0321V19.3649H49.3759V16.6907H50.713C51.2337 16.6907 51.4146 16.3966 51.4822 15.717L51.6395 14.1534H54.471V16.6907H58.0449V19.3649H54.4726V23.8512C54.4726 25.0058 55.0168 25.3676 56.3744 25.3676C56.8993 25.3643 57.4228 25.3116 57.938 25.2103V27.8152C57.2234 27.9703 56.4951 28.0536 55.764 28.0638C52.275 28.0638 51.0292 26.5238 51.0292 24.0321Z"
        fill="var(--logo-text-color)"
      />
      <path
        d="M58.9417 24.1235H62.0217C62.0217 25.1664 62.5424 25.846 64.5134 25.846C66.3035 25.846 66.9375 25.3253 66.9375 24.5089C66.9375 24.1015 66.733 23.7837 66.151 23.6485C65.29 23.4894 64.42 23.3844 63.546 23.3338C61.9384 23.1986 60.6925 22.95 60.013 22.4734C59.6743 22.2164 59.4013 21.8828 59.2162 21.5001C59.0311 21.1173 58.9392 20.6962 58.948 20.2711C58.948 17.8487 61.4397 16.5116 64.5433 16.5116C68.2116 16.5116 69.8916 17.9163 69.8916 20.5213H66.8541C66.8321 19.3431 66.2878 18.7311 64.5889 18.7311C63.0489 18.7311 62.3694 19.2518 62.3694 20.0226C62.3694 20.4945 62.5503 20.861 63.1858 21.0199C63.7521 21.1552 64.7478 21.2464 65.948 21.3597C67.3307 21.495 68.3076 21.6302 69.1429 22.1069C69.5273 22.3347 69.8449 22.6598 70.0636 23.0495C70.2823 23.4392 70.3945 23.8796 70.3887 24.3264C70.3887 26.686 68.3721 28.064 64.5449 28.064C60.595 28.0655 58.9417 26.3887 58.9417 24.1235Z"
        fill="var(--logo-text-color)"
      />
      <path
        d="M70.9445 22.3098C70.9445 18.6635 73.3229 16.5116 76.7647 16.5116C80.0476 16.5116 82.1995 18.3458 82.1995 21.4493H79.1699C79.1463 20.0006 78.2858 19.1386 76.8827 19.1386C75.2735 19.1386 74.391 20.2491 74.391 22.2846C74.391 24.2556 75.2971 25.4307 76.8135 25.4307C78.2858 25.4307 79.173 24.5923 79.1919 23.1655H82.2279C82.2279 26.3808 79.9391 28.0577 76.6326 28.0577C73.2804 28.064 70.9445 25.9341 70.9445 22.3098Z"
        fill="var(--logo-text-color)"
      />
      <path
        d="M83.5755 16.6925H87.0189L86.8821 19.8873H86.9277C87.3996 17.7574 88.6045 16.5116 90.8005 16.5116C93.0892 16.5116 94.4185 17.984 94.4185 20.8831C94.4185 21.5123 94.3508 22.5143 94.3052 23.1026H91.1859C91.2315 22.5599 91.2535 22.0015 91.2535 21.6538C91.2535 20.0226 90.5976 19.3651 89.5106 19.3651C88.0382 19.3651 87.0189 20.5669 87.0189 23.0586V27.8831H83.5755V16.6925Z"
        fill="var(--logo-text-color)"
      />
      <path
        d="M95.7003 12.7748H99.1436V15.5166H95.7003V12.7748ZM95.7003 16.6932H99.1436V27.8838H95.7003V16.6932Z"
        fill="var(--logo-text-color)"
      />
      <path
        d="M100.9 16.6925H104.341L104.274 18.9813H104.293C104.722 17.4397 106.149 16.5116 108.068 16.5116C111.195 16.5116 113.234 18.7311 113.234 22.0392C113.234 25.6572 111.189 28.064 108.043 28.064C105.998 28.064 104.739 27.2255 104.237 25.8617H104.214C104.26 26.6089 104.327 27.7195 104.327 28.1269V32.3631H100.9V16.6925ZM109.823 22.1777C109.823 20.3655 108.782 19.1417 107.218 19.1417C105.497 19.1417 104.341 20.3876 104.341 22.2689V22.766C104.341 24.3752 105.497 25.4401 107.242 25.4401C108.782 25.437 109.823 24.1219 109.823 22.1777Z"
        fill="var(--logo-text-color)"
      />
      <path
        d="M115.352 24.0321V19.3649H113.699V16.6907H115.036C115.556 16.6907 115.737 16.3966 115.805 15.717L115.962 14.1534H118.794V16.6907H122.382V19.3649H118.795V23.8512C118.795 25.0058 119.34 25.3676 120.699 25.3676C121.223 25.3642 121.746 25.3115 122.261 25.2103V27.8152C121.546 27.9703 120.818 28.0536 120.087 28.0638C116.598 28.0638 115.352 26.5238 115.352 24.0321Z"
        fill="var(--logo-text-color)"
      />
    </svg>
  );
};

export default NavLogo;

import { useEffect, useState } from 'react';
import { useGetUsageCredit } from '../../../context/useBilling';
import useGetUsageCreditUndeductedUsage from './useGetUsageCreditUndeductedUsage';

export default function useGetUsageCreditRemaining(): number {
  const [remainingAmount, setRemainingAmount] = useState(0);
  const { data: usageCredit, isLoading: loadingUsageCredit } =
    useGetUsageCredit();
  const undeductedUsage = useGetUsageCreditUndeductedUsage();

  useEffect(() => {
    if (!usageCredit || usageCredit.isExpired || loadingUsageCredit) {
      return;
    }

    const usageCreditRemaining =
      usageCredit.amountTotal - usageCredit.amountUsed;

    setRemainingAmount(usageCreditRemaining - undeductedUsage);
  }, [undeductedUsage, usageCredit, loadingUsageCredit]);

  return Math.max(remainingAmount, 0);
}

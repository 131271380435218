import NavLogo from 'img/NavLogo';
import styled from 'styled-components';
import MediaQueries from 'utils/mediaQueries';

// prop allowing for future emoji usage to add fun
interface NavMenuBubProps {
  hamItUp?: boolean;
  isSales?: boolean;
}

const StyledNavMenuBub = styled.div<NavMenuBubProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 78px;
  opacity: 1;
  position: absolute;
  z-index: 1000;
  top: 12px;
  left: 48px;
  transition: opacity var(--nav-menu-transition-speed),
    transform var(--nav-menu-transition-speed);

  ${MediaQueries.desktopOnly} {
    opacity: 0;
    visibility: hidden;
    left: calc(50% - 39px);
    transform: translateY(-36px);

    .full-screen-editor & {
      visibility: visible;
      transform: translateY(-36px);
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* on sidebar open */

  .sidebar-navigation-open & {
    opacity: 0;
  }

  ${MediaQueries.desktopOnly} {
    .sidebar-navigation-open & {
      transform: translate(calc(var(--nav-menu-width) * 0.67), 0);
    }
  }

  /* svg color */

  svg {
    height: 100%;
  }
  svg > path:not(:first-of-type) {
    fill: var(--white);
  }
  svg > path:first-of-type {
    fill: var(--gray-4);
  }

  [data-theme='dark'] & {
    svg > path:not(:first-of-type) {
      fill: var(--main-bkg-color);
    }
    svg > path:first-of-type {
      fill: var(--white);
    }
  }
`;

const NavMenuBub = ({
  hamItUp = false,
  isSales = false,
  ...props
}: NavMenuBubProps): JSX.Element => {
  return (
    <StyledNavMenuBub
      aria-hidden="true"
      data-cy="nav-bub"
      hamItUp={hamItUp}
      {...props}
    >
      <NavLogo isSales={isSales} />
    </StyledNavMenuBub>
  );
};

export default NavMenuBub;

import {
  BASE_FLOWS_URL,
  BASE_FLOW_BUILDER_API_URL,
  FlowStatuses,
} from 'components/flowBuilder/constants';
import { dynamicImageTagsDict } from 'components/flowBuilder/constants/tags';
import type {
  Action,
  CancelEvent,
  DraftFlow,
  EndAction,
  EventSplitBranch,
  FilterOption,
  Flow,
  FlowStatus,
  FlowType,
  SplitAction,
  SplitActionBranch,
  SplitActionParams,
  StaticActionType,
  TextToBuyAction,
  TextToBuyActionParams,
  TextToBuyBranch,
  TextToBuyProduct,
  TriggerEvent,
  TriggerEventSplitAction,
  TriggerEventSplitActionParams,
  WaitForEventSplitAction,
  WaitForEventSplitActionParams,
} from 'components/flowBuilder/types';
import {
  isActionTypesName,
  isAnySplitAction,
  isClonedFlowResponse,
  isEndAction,
  isFlow,
  isSendMessageAction,
  isSplitAction,
  isTextToBuyAction,
} from 'components/flowBuilder/types/typeGuards';
import { updateActionParams } from 'components/flowBuilder/utils/steps';
import { api } from 'controllers/network/apiClient';
import { cloneDeep, omit } from 'lodash';
import { DateTime } from 'luxon';
import { stringify } from 'query-string';
import { v4 as uuidv4, v4 } from 'uuid';
import { convertLegacyMergeTags } from './tags';

interface ApiPayloadFlow extends Omit<Flow, 'cancelEvents' | 'triggerEvents'> {
  cancelEvents: CancelEvent[];
  triggerEvents: TriggerEvent[];
}

export const createDraftFlow = (): DraftFlow => {
  return {
    actions: [],
    description: '',
    name: `${DateTime.local().toLocaleString(DateTime.DATE_SHORT)} | Flow`,
    segmentIds: [],
    excludeSegmentIds: [],
    start: '',
    status: FlowStatuses.DRAFT,
    type: null,
    activatedAt: null,
    scheduledFor: null,
    triggerEvents: [],
    cancelEvents: [],
    safeSend: false,
    userFilterCriteria: [],
    triggerFilters: [],
    // @TODO - Can we include this now, before the BE is ready?
    transactional: false,
  };
};

const createApiPayloadFlowFromFlow = (flow: Flow) => {
  const apiFlow: ApiPayloadFlow = {
    guid: flow.guid ?? uuidv4(),
    name: flow.name,
    description: flow.description,
    segmentIds: flow.segmentIds,
    excludeSegmentIds: flow.excludeSegmentIds,
    actions: flow.actions,
    start: flow.start,
    status: flow.status,
    templateId: flow.templateId,
    type: flow.type,
    activatedAt: flow.activatedAt,
    scheduledFor: flow.scheduledFor,
    triggerEvents: flow.triggerEvents,
    cancelEvents: flow.cancelEvents,
    safeSend: flow.safeSend,
    userFilterCriteria: flow.userFilterCriteria,
    triggerFilters: flow.triggerFilters,
    subscriptionDurationLimit: flow.subscriptionDurationLimit,
    transactional: flow.transactional,
  };

  return apiFlow;
};

const hydrateFlowActions = (actions: Action[]) => {
  return actions.map((action) => {
    if (isTextToBuyAction(action)) {
      return {
        ...action,
        params: {
          ...action.params,
          products: action.params.products.map((product) => {
            return {
              ...product,
              guid: v4(),
            };
          }),
        },
      };
    }

    return action;
  });
};

export const cleanTextToBuyProducts = (
  products: TextToBuyProduct[],
): TextToBuyProduct[] => {
  return products.map((product) => {
    return omit(product, 'guid');
  });
};

const cleanDraftFlowActions = (actions: Action[]) => {
  return actions.map((action) => {
    if (isTextToBuyAction(action)) {
      return {
        ...action,
        params: {
          ...action.params,
          products: cleanTextToBuyProducts(action.params.products),
        },
      };
    }

    return action;
  });
};

const createFlowFromDraftFlow = (draftFlow: DraftFlow) => {
  const flow: Flow = {
    guid: draftFlow.guid ?? uuidv4(),
    name: draftFlow.name,
    description: draftFlow.description,
    segmentIds: draftFlow.segmentIds,
    excludeSegmentIds: draftFlow.excludeSegmentIds,
    actions: cleanDraftFlowActions(draftFlow.actions),
    start: draftFlow.start,
    status: draftFlow.status,
    templateId: draftFlow.templateId,
    type: draftFlow.type,
    activatedAt: draftFlow.activatedAt,
    scheduledFor: draftFlow.scheduledFor,
    triggerEvents: draftFlow.triggerEvents,
    cancelEvents: draftFlow.cancelEvents,
    safeSend: draftFlow.safeSend,
    userFilterCriteria: draftFlow.userFilterCriteria,
    triggerFilters: draftFlow.triggerFilters,
    subscriptionDurationLimit: draftFlow.subscriptionDurationLimit,
    transactional: draftFlow.transactional,
  };

  return flow;
};

export const createFlow = (): Flow => {
  return createFlowFromDraftFlow(createDraftFlow());
};

export interface FlowsResponse {
  flows: Flow[];
  totalPages: number;
}

const isFlowsResponse = (response: unknown): response is FlowsResponse => {
  const responseValue = response as FlowsResponse;

  if (!responseValue || typeof responseValue !== 'object') return false;
  if (!Array.isArray(responseValue.flows)) return false;
  if (responseValue.flows.some((flow) => !isFlow(flow))) return false;
  if (typeof responseValue.totalPages !== 'number') return false;

  return true;
};

export const fetchTriggerEventTypeFields = async (
  triggerEventType: string,
): Promise<FilterOption[]> => {
  const { fields } = await api.get(
    `/v2/flowbuilder/events/${triggerEventType}`,
  );

  return fields;
};

export const SortColumns = {
  CREATED_AT: 'createdAt',
  NAME: 'name',
  SCHEDULED_FOR: 'scheduledFor',
  UPDATED_AT: 'updatedAt',
} as const;

export type SortColumn = typeof SortColumns[keyof typeof SortColumns];

export const SortOrders = {
  ASC: 'asc',
  DESC: 'desc',
} as const;

export type SortOrder = typeof SortOrders[keyof typeof SortOrders];

export interface FlowsParams {
  name?: string;
  page?: number;
  perPage?: number;
  sortColumn?: SortColumn;
  sortOrder?: SortOrder;
  status?: FlowStatus | null;
  type?: FlowType;
}

export const fetchFlows = async ({
  name,
  page,
  perPage,
  sortColumn,
  sortOrder,
  status,
  type,
}: FlowsParams): Promise<FlowsResponse> => {
  const sort = sortColumn && sortOrder ? `${sortColumn}__${sortOrder}` : null;
  const queryParams = {
    name__contains: name,
    page,
    per_page: perPage,
    sort,
    status__eq: status,
    type__eq: type,
  };
  const queryString = stringify(queryParams, {
    skipEmptyString: true,
    skipNull: true,
  });
  const flowsResponse: unknown = await api.get(
    `${BASE_FLOWS_URL}?${queryString}`,
  );

  if (!isFlowsResponse(flowsResponse))
    throw new Error('Unexpected Flows response');

  return flowsResponse;
};

const getFirstEndActionInFlow = (
  firstAction: Action,
  actionMap: Map<string, Action>,
) => {
  let endAction: EndAction | undefined;
  let currentAction: Action | undefined = firstAction;

  while (currentAction) {
    if (isEndAction(currentAction)) endAction = currentAction;
    const nextActionGuid: string | undefined = currentAction.next[0];
    const nextAction: Action | undefined = nextActionGuid
      ? actionMap.get(nextActionGuid)
      : undefined;
    currentAction = nextAction;
  }

  return endAction;
};

// If 'action' is pointing to an EndAction that should be removed, it deletes the EndAction and mutates the action's 'next'/'params' properties
const replaceUnnecessaryEndActionsInAction = (
  action: Action,
  endAction: EndAction,
  actionMap: Map<string, Action>,
) => {
  action.next.forEach((nextActionGuid, i) => {
    const nextAction = actionMap.get(nextActionGuid);
    if (
      !nextAction || // If the action doesn't exist it means it was an EndAction and we've already deleted it
      (isEndAction(nextAction) && nextActionGuid !== endAction.guid)
    ) {
      actionMap.delete(nextActionGuid);
      // eslint-disable-next-line no-param-reassign
      action.next[i] = endAction.guid;
      // If it's a split action we also need to mutate its 'params'
      if (isAnySplitAction(action)) {
        action.params.cases.forEach((branch) => {
          if (branch.action_guid === nextActionGuid)
            // eslint-disable-next-line no-param-reassign
            branch.action_guid = endAction.guid;
        });
      }
    } else {
      replaceUnnecessaryEndActionsInAction(nextAction, endAction, actionMap);
    }
  });
};

// Mutates the flow's actions by retaining a single EndAction
const replaceUnnecessaryEndActionsInFlow = (flow: DraftFlow) => {
  const actionMap = new Map(
    flow.actions.map((action) => [action.guid, action]),
  );

  const firstAction = actionMap.get(flow.start);
  if (!firstAction) return;

  const endAction = getFirstEndActionInFlow(firstAction, actionMap);
  if (!endAction) return;

  replaceUnnecessaryEndActionsInAction(firstAction, endAction, actionMap);

  // eslint-disable-next-line no-param-reassign
  flow.actions = Array.from(actionMap.values());
};

export const ensureFlowActionsReconvene = (flow: DraftFlow): void => {
  if (flow.actions.filter(isEndAction).length < 2) return;
  replaceUnnecessaryEndActionsInFlow(flow);
};

export const fetchFlow = async (guid: string): Promise<Flow> => {
  const flow: unknown = await api.get(`${BASE_FLOWS_URL}${guid}/`);
  if (!isFlow(flow)) throw new Error('Unexpected Flow response');

  // These methods each mutate the flow
  ensureFlowActionsReconvene(flow);
  convertLegacyMergeTags(flow);

  return {
    ...flow,
    actions: hydrateFlowActions(flow.actions),
  };
};

export const deleteFlow = async (guid: string): Promise<void> => {
  await api.delete(`${BASE_FLOWS_URL}${guid}/`);
};

const transformAnySplitActionParams = <
  T extends
    | SplitAction
    | WaitForEventSplitAction
    | TriggerEventSplitAction
    | TextToBuyAction,
>(
  action: T,
  guidsDict: {
    [key: string]: string;
  },
): T['params'] => {
  if (isSplitAction(action)) {
    const newSplitActionParams: SplitActionParams = { cases: [] };

    action.params.cases.forEach((branch, i) => {
      const newBranch: SplitActionBranch = {
        action_guid: guidsDict[branch.action_guid] ?? guidsDict[action.next[i]],
        conditions: branch.conditions,
      };
      newSplitActionParams.cases.push(newBranch);
    });
    return newSplitActionParams;
  }

  if (isTextToBuyAction(action)) {
    const newTextToBuyActionParams: TextToBuyActionParams = {
      ...action.params,
      cases: [],
    };

    action.params.cases.forEach((branch, i) => {
      const newBranch: TextToBuyBranch = {
        action_guid: guidsDict[branch.action_guid] ?? guidsDict[action.next[i]],
        event: branch.event,
      };

      newTextToBuyActionParams.cases.push(newBranch);
    });

    return newTextToBuyActionParams;
  }

  const newEventSplitActionParams:
    | WaitForEventSplitActionParams
    | TriggerEventSplitActionParams = {
    ...action.params,
    cases: [],
  };
  action.params.cases.forEach((branch, i) => {
    const newBranch: EventSplitBranch = {
      action_guid: guidsDict[branch.action_guid] ?? guidsDict[action.next[i]],
      conditions: branch.conditions,
    };
    newEventSplitActionParams.cases.push(newBranch);
  });

  return newEventSplitActionParams;
};

export const createFlowCopy = (
  flow: DraftFlow,
  nameAppendix = '',
): DraftFlow => {
  const actionsToClone = flow.actions;
  const guidsDict: {
    [key: string]: string;
  } = {};

  actionsToClone.forEach((action) => {
    guidsDict[action.guid] = uuidv4();
  });

  const newActions = actionsToClone.map((action): Action => {
    const newAction = isAnySplitAction(action)
      ? updateActionParams(
          action,
          transformAnySplitActionParams(action, guidsDict),
        )
      : action;
    newAction.guid = guidsDict[action.guid];
    newAction.next = action.next.map((nextGuid) => guidsDict[nextGuid]);
    return newAction;
  });

  return {
    ...cloneDeep(flow),
    guid: undefined,
    actions: newActions,
    name: `${flow.name}${nameAppendix}`,
    start: guidsDict[flow.start],
  };
};

/**
 * Return the nodes from the new flow which differ.
 *
 * Note: If oldFlow is not provided, the entire newFlow will be returned.
 *
 * @param newFlow which may contain updated values
 * @param [oldFlow]
 *
 * @returns partial version of newFlow which differs from old
 */
export const getFlowDiff = (
  newFlow: Partial<DraftFlow>,
  oldFlow?: Partial<DraftFlow>,
): Partial<DraftFlow> => {
  if (!oldFlow) {
    return newFlow;
  }

  const allKeys = new Set([
    ...Object.keys(oldFlow),
    ...Object.keys(newFlow),
  ]) as Set<keyof DraftFlow>;

  const changedFields: Partial<DraftFlow> = {};

  allKeys.forEach((key) => {
    if (JSON.stringify(oldFlow[key]) !== JSON.stringify(newFlow[key])) {
      changedFields[key] = newFlow[key] as any;
    }
  });

  return changedFields;
};

/**
 * The list of fields which allow for a partial PATCH flow update.
 * Any fields that fall outside of this list will cause a full PUT.
 */
const PATCH_FLOW_FIELDS: Array<keyof DraftFlow> = ['name', 'description'];

/**
 * Returns whether a flow diff should warrant using a full or partial update
 * as its mechanism for saving.
 *
 * @param diff the object of changes between the old and new version
 *
 * @returns true if full update, false if partial
 */
export const shouldFlowDiffPerformFullUpdate = (
  diff: Partial<DraftFlow>,
): boolean => {
  return Object.keys(diff).some(
    (key) => !PATCH_FLOW_FIELDS.includes(key as keyof DraftFlow),
  );
};

/**
 * Reused options between all API calls.
 */
const SAVE_DRAFT_FLOW_API_OPTIONS = {
  throwErrorAsString: false,
};

export const saveDraftFlow = async (
  draftFlow: DraftFlow,
  initialDraftFlow?: DraftFlow,
): Promise<Flow> => {
  const flow = createFlowFromDraftFlow(draftFlow);
  const apiPayloadFlow = createApiPayloadFlowFromFlow(flow);

  let response;
  if (draftFlow.guid) {
    const flowApiUrl = `${BASE_FLOWS_URL}${draftFlow.guid}/`;

    const flowDiff = getFlowDiff(draftFlow, initialDraftFlow);
    const performFullUpdate = shouldFlowDiffPerformFullUpdate(flowDiff);

    if (performFullUpdate) {
      response = await api.put(
        flowApiUrl,
        apiPayloadFlow,
        SAVE_DRAFT_FLOW_API_OPTIONS,
      );
    } else {
      response = await api.patch(
        flowApiUrl,
        flowDiff,
        SAVE_DRAFT_FLOW_API_OPTIONS,
      );
    }
  } else {
    // Creating a new flow...
    response = await api.post(
      BASE_FLOWS_URL,
      apiPayloadFlow,
      SAVE_DRAFT_FLOW_API_OPTIONS,
    );
  }

  // Merge the provided version and the backend response version, as some
  // fields may be missing, such as `status`.
  // FIXME: [FLOW-571] Remove this merging of objects to clean up the logic.
  const mergedFlow = {
    ...draftFlow,
    ...response,
    actions: hydrateFlowActions(response.actions),
  };

  if (!isFlow(mergedFlow)) {
    throw new Error('Invalid Flow state after save.');
  }

  return mergedFlow;
};

export const cloneFlowViaEndpoint = async (guid: string): Promise<string> => {
  const clonedResponse = await api.post(`${BASE_FLOWS_URL}${guid}/clone/`);
  if (!isClonedFlowResponse(clonedResponse))
    throw new Error('Unexpected Flow response');
  return clonedResponse.guid;
};

export const unscheduleFlow = async (guid: string): Promise<void> => {
  await api.put(`${BASE_FLOWS_URL}cancel/${guid}/`);
};

interface ApiActionType {
  type: string;
  helpText: string;
  category: string;
}

const isApiActionType = (actionType: unknown): actionType is ApiActionType => {
  const actionTypeValue = actionType as ApiActionType;
  if (!actionTypeValue || typeof actionTypeValue !== 'object') return false;
  if (typeof actionTypeValue.type !== 'string') return false;
  if (typeof actionTypeValue.helpText !== 'string') return false;
  if (typeof actionTypeValue.category !== 'string') return false;
  return true;
};

interface ActionTypesResponse {
  actions: ApiActionType[];
}

const isActionTypesResponse = (
  response: unknown,
): response is ActionTypesResponse => {
  const responseValue = response as ActionTypesResponse;

  if (!responseValue || typeof responseValue !== 'object') return false;
  if (!responseValue.actions || !Array.isArray(responseValue.actions))
    return false;
  if (responseValue.actions.some((action) => !isApiActionType(action)))
    return false;

  return true;
};

export const fetchActionTypes = async (): Promise<StaticActionType[]> => {
  const actionTypesResponse: unknown = await api.get(
    `${BASE_FLOW_BUILDER_API_URL}actions/`,
  );

  if (!isActionTypesResponse(actionTypesResponse))
    throw new Error('Unexpected Action Types response');
  return actionTypesResponse.actions.reduce(
    (actionTypes: StaticActionType[], action: ApiActionType) => {
      if (isActionTypesName(action.type))
        return [
          ...actionTypes,
          {
            name: action.type,
            helpText: action.helpText,
            category: action.category,
          },
        ];
      return actionTypes;
    },
    [],
  );
};

export const flowStatusIsEnabled = (status: FlowStatus): boolean =>
  status === FlowStatuses.ENABLED;

export const flowStatusIsActiveOrCompleted = (status: FlowStatus): boolean =>
  status === FlowStatuses.ENABLED || status === FlowStatuses.COMPLETED;

export const flowStatusIsScheduledActiveOrCompleted = (
  status: FlowStatus,
): boolean =>
  status === FlowStatuses.SCHEDULED || flowStatusIsActiveOrCompleted(status);

export const flowStatusIsScheduledOrEnabled = (status: FlowStatus): boolean =>
  status === FlowStatuses.SCHEDULED || status === FlowStatuses.ENABLED;

export const flowStatusIsEndedScheduledActiveOrCompleted = (
  status: FlowStatus,
): boolean =>
  status === FlowStatuses.ENDED ||
  flowStatusIsScheduledActiveOrCompleted(status);

export const flowStatusIsEndedActiveOrCompleted = (
  status: FlowStatus,
): boolean =>
  status === FlowStatuses.ENDED || flowStatusIsActiveOrCompleted(status);

export const flowStatusIsUnapproved = (status: FlowStatus): boolean =>
  status === FlowStatuses.APPROVAL_PENDING ||
  status === FlowStatuses.APPROVAL_DENIED;

/**
 * Returns whether a flow's contents can be edited.
 *
 * NOTE: This function uses a hook, so it needs to be used in a component,
 * without any conditionals wrapping it.
 *
 * @param flow which is being viewed/validated
 * @returns true if editable, false otherwise
 */
export const flowIsEditable = (flow: DraftFlow): boolean => {
  switch (flow.type) {
    case 'AUTOMATION':
      return true;
    case 'CAMPAIGN':
      return !flowStatusIsEndedActiveOrCompleted(flow.status);
    default:
      return false;
  }
};

const DYNAMIC_IMAGE_FLOW_TRIGGER_EVENT_TYPES =
  Object.keys(dynamicImageTagsDict);

/**
 * Returns whether a flow's current state would support dynamic images in
 * the messages it sends out.
 *
 * @param events which are being tested
 * @returns true if it supports dynamic images, false otherwise
 */
export const triggerEventsSupportDynamicImages = (
  events: TriggerEvent[],
): boolean => {
  const trigger = events[0];
  if (!trigger) {
    return false;
  }
  return DYNAMIC_IMAGE_FLOW_TRIGGER_EVENT_TYPES.includes(trigger.eventType);
};

/**
 * Returns array of [placeholder] text instances present in a given string.
 *
 * @param text the string which is being checked for placeholder content
 * @returns array of placeholder text instances, e.g. ["[NAME]", "[DISCOUNT CODE]"]
 */
export const getPlaceholders = (text: string): string[] => {
  const placeholders: string[] = [];

  for (let i = 0; i < text.length; i += 1) {
    // if we get to the opening braces for a merge tag, '{{'
    if (text[i] === '{' && text[i + 1] === '{') {
      // found end of merge tag
      const endIndex = text.indexOf('}}', i);

      if (endIndex === -1) {
        break;
      }

      // advance past this text because we don't want to check merge tags for []'s
      i = endIndex + 1;
    } else if (text[i] === '[') {
      // if we come to an opening bracket
      // found end of placeholder
      const endIndex = text.indexOf(']', i);

      if (endIndex === -1) {
        break;
      }

      placeholders.push(text.substring(i, endIndex + 1));
      // advance past this text because we don't need to check every character
      i = endIndex + 1;
    }
  }

  return placeholders;
};

/**
 * Returns whether any of a flow's send message actions contain a template [placeholder].
 *
 * @param actions which are being validated
 * @returns true if flow contains a placeholder in any of its actions, false otherwise
 */
export const checkSendMessageActionsforPlaceholders = (
  actions: Action[],
): boolean => {
  return actions.some((action) => {
    return (
      isSendMessageAction(action) &&
      getPlaceholders(action.params.content).length
    );
  });
};

/* eslint-disable @typescript-eslint/no-use-before-define */
import { toast } from '@postscript/components';
import { PERMISSIONS } from 'components/account/AccountView/users/constants';
import { userHasPermission } from 'components/account/AccountView/users/helpers';
import { useUsageBilling } from 'components/billing/context/usageBilling';
import { useGetUsageCredit } from 'components/billing/context/useBilling';
import useGetUsageCreditRemaining from 'components/billing/modules/usageCredit/hooks/useGetUsageCreditRemaining';
import { useBanners } from 'components/GlobalBanners/globalBanners';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BANNER_TYPES, logButtonClickEvent } from 'utils/events';
import {
  FREE_TRIAL_BANNER_ID,
  FREE_USAGE_CREDIT_AND_FREE_TRIAL_BANNER_ID,
  FREE_USAGE_CREDIT_BANNER_ID,
  LONG_DATE_FORMAT,
  PAYMENT_METHOD_BANNER_ID,
  PAYMENT_PROVIDERS,
  RAC_FLOW_BANNER_ID,
  RAC_STATUSES,
  SHOP_FAILED_INVOICE_BANNER_ID,
  SHOP_PAST_DUE_BANNER_ID,
} from '../constants';
import { formatDollars, toggleButtonLoadingState } from '../utils';

export default function BillingBanners(): null {
  const [isInFreeTrial, setIsInFreeTrial] = useState(false);
  const [hasFreeUsageCredit, setHasFreeUsageCredit] = useState(false);
  const {
    paymentProvider,
    card,
    usBankAccount,
    recurringApplicationCharge,
    invoicingStatus: { numberOfFailedInvoices, isPastDue },
    freeTrial,
    isInitialDataLoaded,
    showPaymentMethodsModal,
    generateRac,
  } = useUsageBilling();
  const { data: usageCredit } = useGetUsageCredit();
  const estimatedUsageCreditRemaining = useGetUsageCreditRemaining();
  const { addBanner, removeBanner, updateBanner } = useBanners();
  const { push } = useHistory();

  useEffect(() => {
    setHasFreeUsageCredit(
      !!usageCredit &&
        usageCredit.amountTotal - usageCredit.amountUsed > 0 &&
        !usageCredit.isExpired,
    );

    setIsInFreeTrial(!!freeTrial && !freeTrial.isExpired);
  }, [usageCredit, freeTrial]);

  useEffect(() => {
    if (!isInitialDataLoaded || !userHasPermission(PERMISSIONS.billing_read)) {
      return;
    }

    removeAllBillingBanners();
    displayRequiredBanners();
  }, [
    isInitialDataLoaded,
    paymentProvider,
    card,
    usBankAccount,
    recurringApplicationCharge,
    numberOfFailedInvoices,
    hasFreeUsageCredit,
    isInFreeTrial,
  ]);

  useEffect(() => {
    if (!hasFreeUsageCredit) return;
    updateUsageCreditBannerBodyText();
  }, [estimatedUsageCreditRemaining]);

  function removeAllBillingBanners() {
    removeBanner(PAYMENT_METHOD_BANNER_ID);
    removeBanner(RAC_FLOW_BANNER_ID);
    removeBanner(SHOP_PAST_DUE_BANNER_ID);
    removeBanner(SHOP_FAILED_INVOICE_BANNER_ID);
    removeBanner(FREE_TRIAL_BANNER_ID);
    removeBanner(FREE_USAGE_CREDIT_BANNER_ID);
    removeBanner(FREE_USAGE_CREDIT_AND_FREE_TRIAL_BANNER_ID);
  }

  function displayRequiredBanners() {
    if (
      paymentProvider === PAYMENT_PROVIDERS.STRIPE &&
      !card &&
      !usBankAccount
    ) {
      addPaymentMethodBanner();
    }

    if (
      paymentProvider === PAYMENT_PROVIDERS.SHOPIFY &&
      recurringApplicationCharge?.status !== RAC_STATUSES.ACTIVE
    ) {
      addRacApprovalRequiredBanner();
    }

    if (numberOfFailedInvoices > 0) {
      addUnpaidInvoiceBanner();
    }

    if (hasFreeUsageCredit && isInFreeTrial) {
      return addFreeUsageAndFreeTrialBanner();
    }

    if (hasFreeUsageCredit) {
      return addFreeUsageCreditBanner();
    }

    if (isInFreeTrial) {
      return addFreeTrialBanner();
    }
  }

  function addPaymentMethodBanner() {
    const usageCreditPercentUsed =
      ((usageCredit?.amountUsed || 0) / (usageCredit?.amountTotal || 0)) * 100;
    const usageCreditDaysRemaining = moment(usageCredit?.expiresAt).diff(
      moment(),
      'days',
    );
    const freeTrialDaysRemaining = moment(freeTrial?.expiresAt).diff(
      moment(),
      'days',
    );

    // Free Trial Warning Threshold (any of the following):
    // No current free trial
    // 7 or fewer days remaining in free trial
    const hasMetFreeTrialWarningThreshold =
      !isInFreeTrial || freeTrialDaysRemaining <= 7;

    // Free Usage Credit Warning Threshold (any of the following):
    // No unexpired credit remaining
    // 7 or fewer days remaining until expiration
    // 10% or less credit remaining
    const hasMetUsageCreditWarningThreshold =
      !hasFreeUsageCredit ||
      usageCreditPercentUsed >= 90 ||
      usageCreditDaysRemaining <= 7;

    // isWarning makes this a persistent banner with more specific copy
    const isWarning =
      hasMetFreeTrialWarningThreshold && hasMetUsageCreditWarningThreshold;

    let heading = 'Add a payment method';

    if (isWarning && hasFreeUsageCredit) {
      heading = 'Your credit expires soon';
    }

    if (isWarning && isInFreeTrial) {
      heading = 'Your free trial is almost over';
    }

    addBanner({
      id: PAYMENT_METHOD_BANNER_ID,
      eventTrackingOptions: {
        bannerType: BANNER_TYPES.BILLING_PAYMENT_METHOD,
      },
      isDismissable: !isWarning,
      shouldPersistDismiss: !isWarning,
      data: {
        variant: isWarning ? 'warning' : 'guidance',
        heading,
        bodyText:
          'Ensure you can continue sending with no interruptions by adding a card or bank account.',
        primaryAction: {
          text: 'Add Payment Method',
          onClick: (event: React.SyntheticEvent) => {
            logButtonClickEvent(event, {
              banner_type: BANNER_TYPES.BILLING_PAYMENT_METHOD,
            });
            showPaymentMethodsModal();
          },
        },
      },
    });
  }

  function addRacApprovalRequiredBanner() {
    const buttonId = `${RAC_FLOW_BANNER_ID}-button`;
    const buttonText = 'Approve on Shopify';

    addBanner({
      id: RAC_FLOW_BANNER_ID,
      eventTrackingOptions: {
        bannerType: BANNER_TYPES.BILLING_RAC_APPROVAL,
      },
      data: {
        variant: 'warning',
        heading: 'Complete billing setup',
        bodyText: 'Postscript needs your permission to bill you via Shopify.',
        primaryAction: {
          id: buttonId,
          text: buttonText,
          onClick: async (event: React.SyntheticEvent) => {
            logButtonClickEvent(event, {
              banner_type: BANNER_TYPES.BILLING_RAC_APPROVAL,
            });

            const button = document.getElementById(
              buttonId,
            ) as HTMLButtonElement | null;

            try {
              toggleButtonLoadingState({
                button,
                originalText: buttonText,
                isLoading: true,
              });

              const rac = await generateRac();

              if (!rac) {
                toggleButtonLoadingState({
                  button,
                  originalText: buttonText,
                  isLoading: false,
                });

                return toast.error('Please try again.');
              }

              window.location.href = rac.confirmationUrl;
            } catch (error) {
              toggleButtonLoadingState({
                button,
                originalText: buttonText,
                isLoading: false,
              });

              toast.error(error as string);
            }
          },
        },
      },
    });
  }

  function addUnpaidInvoiceBanner() {
    addBanner({
      id: isPastDue ? SHOP_PAST_DUE_BANNER_ID : SHOP_FAILED_INVOICE_BANNER_ID,
      eventTrackingOptions: {
        bannerType: isPastDue
          ? BANNER_TYPES.BILLING_PAST_DUE
          : BANNER_TYPES.BILLING_FAILED_INVOICE,
      },
      data: {
        variant: isPastDue ? 'critical' : 'warning',
        heading: isPastDue
          ? 'Your account is past due'
          : 'You have a past due invoice',
        bodyText:
          'Please pay any overdue invoices to continue sending with Postscript.',
        primaryAction: {
          text: 'View Invoices',
          onClick: () => push('/billing/invoices'),
        },
      },
    });
  }

  function addFreeTrialBanner() {
    if (!freeTrial) return;

    const { expiresAt } = freeTrial;

    const daysRemaining = moment.utc(expiresAt).diff(moment(), 'days');
    const formattedExpirationDate = moment
      .utc(expiresAt)
      .format(LONG_DATE_FORMAT);

    addBanner({
      id: FREE_TRIAL_BANNER_ID,
      eventTrackingOptions: {
        bannerType: BANNER_TYPES.BILLING_FREE_TRIAL,
      },
      isDismissable: true,
      shouldPersistDismiss: true,
      data: {
        variant: 'guidance',
        heading: `${daysRemaining} ${
          daysRemaining > 1 ? 'days' : 'day'
        } left in your trial`,
        bodyText: `Your trial will end on ${formattedExpirationDate}.`,
      },
    });
  }

  async function buildUsageCreditBannerBodyText() {
    if (!usageCredit) return;

    const { amountTotal, expiresAt } = usageCredit;
    const formattedExpirationDate = moment
      .utc(expiresAt)
      .format(LONG_DATE_FORMAT);

    return (
      <>
        <strong>{formatDollars(estimatedUsageCreditRemaining / 100)}</strong>{' '}
        remaining of your {formatDollars(amountTotal / 100, 0, 0)} usage credit
        &mdash; expires on <strong>{formattedExpirationDate}</strong>
      </>
    );
  }

  async function updateUsageCreditBannerBodyText() {
    if (estimatedUsageCreditRemaining <= 0) {
      removeBanner(FREE_USAGE_CREDIT_BANNER_ID);
      removeBanner(FREE_USAGE_CREDIT_AND_FREE_TRIAL_BANNER_ID);

      if (isInFreeTrial) {
        addFreeTrialBanner();
      }

      return;
    }

    const bodyText = await buildUsageCreditBannerBodyText();

    const updateBannerById = (id: string) =>
      updateBanner({
        id,
        data: {
          bodyText,
        },
      });

    updateBannerById(FREE_USAGE_CREDIT_BANNER_ID);
    updateBannerById(FREE_USAGE_CREDIT_AND_FREE_TRIAL_BANNER_ID);
  }

  async function addFreeUsageCreditBanner() {
    if (estimatedUsageCreditRemaining <= 0) return;

    const bodyText = await buildUsageCreditBannerBodyText();

    addBanner({
      id: FREE_USAGE_CREDIT_BANNER_ID,
      eventTrackingOptions: {
        bannerType: BANNER_TYPES.BILLING_USAGE_CREDIT,
      },
      isDismissable: true,
      shouldPersistDismiss: true,
      data: {
        variant: 'guidance',
        heading: 'Messaging credit',
        bodyText,
        primaryAction: {
          text: 'Visit Billing Overview',
          onClick: (event: React.SyntheticEvent) => {
            logButtonClickEvent(event, {
              banner_type: BANNER_TYPES.BILLING_USAGE_CREDIT,
            });
            push('/billing/overview');
          },
        },
      },
    });
  }

  async function addFreeUsageAndFreeTrialBanner() {
    if (!freeTrial) return;

    const { expiresAt: freeTrialExpiresAt } = freeTrial;
    const freeTrialDaysRemaining = moment
      .utc(freeTrialExpiresAt)
      .diff(moment(), 'days');

    if (estimatedUsageCreditRemaining <= 0) {
      return addFreeTrialBanner();
    }

    const bodyText = await buildUsageCreditBannerBodyText();

    addBanner({
      id: FREE_USAGE_CREDIT_AND_FREE_TRIAL_BANNER_ID,
      eventTrackingOptions: {
        bannerType: BANNER_TYPES.BILLING_FREE_TRIAL_AND_USAGE_CREDIT,
      },
      isDismissable: true,
      shouldPersistDismiss: true,
      data: {
        variant: 'guidance',
        heading: `${freeTrialDaysRemaining} ${
          freeTrialDaysRemaining > 1 ? 'days' : 'day'
        } left in your trial`,
        bodyText,
        primaryAction: {
          text: 'Visit Billing Overview',
          onClick: (event: React.SyntheticEvent) => {
            logButtonClickEvent(event, {
              banner_type: BANNER_TYPES.BILLING_FREE_TRIAL_AND_USAGE_CREDIT,
            });
            push('/billing/overview');
          },
        },
      },
    });
  }

  return null;
}
